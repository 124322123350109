export default {
java: `import java.security.PrivateKey;
import java.security.Signature;
import java.util.Base64;

public class Main{
    public static void main(String[] args) throws Exception {

        PrivateKey privateKey = null; //Your private key logic
        
        String message = "Access Token"+ "Request Body";
        
        byte[] data = message.getBytes("UTF8");

        Signature sig = Signature.getInstance("SHA256withRSA");
        sig.initSign(privateKey);
        sig.update(data);
        byte[] signatureBytes = sig.sign();
        Base64.Encoder encoder = Base64.getEncoder();
         
        System.out.println("Signature:" + encoder.encodeToString(signatureBytes));

    }
}`,

csharp : `using System;
using System.Text;
using System.Text.Json;
using System.Text.Json.Serialization;
using Org.BouncyCastle.Crypto;
using Org.BouncyCastle.Security;
public class Program
{
    public static Encoding encoding = Encoding.UTF8;
    public static string SignerSymbol = "SHA256withRSA";

    public static void Main(string[] args)
    {
        String privateKeySt = ""; //your privatekey with begin and end tags

        String privateKeyStr = privateKeySt
          .Replace("-----BEGIN PRIVATE KEY-----", "")
          .Replace("\\r\\n", "") //replaceany line seprators
          .Replace("\\n", "")
          .Replace("\\r", "")
          .Replace("-----END PRIVATE KEY-----", "")
          .Replace(" ", "");


        var body = new Body(); //your requestbody object

        JsonSerializerOptions options = new JsonSerializerOptions();
        options.WriteIndented = true; //Pretty print using indent, white space, new line, etc.
        options.NumberHandling = JsonNumberHandling.AllowNamedFloatingPointLiterals; //Allow NANs
        string requestBody = System.Text.Json.JsonSerializer.Serialize(body, options);
        Console.WriteLine(requestBody);
        string str = "35d34aa1-3a9e-4919-99d5-233cfd44404d" + requestBody;  //token + req body
        Console.WriteLine(str);
        try
        {
            string sign = Sign(str, privateKeyStr);
            Console.WriteLine(sign);
        }
        catch (ArgumentNullException)
        {
            Console.WriteLine("The data was not signed");
        }
    }

    private static AsymmetricKeyParameter CreateKEY(bool isPrivate, string key)
    {
        byte[] keyInfoByte = Convert.FromBase64String(key);

        if (isPrivate)
            return PrivateKeyFactory.CreateKey(keyInfoByte);
        else
            return PublicKeyFactory.CreateKey(keyInfoByte);
    }

    public static string Sign(string content, string privatekey)
    {
        ISigner sig = SignerUtilities.GetSigner(SignerSymbol);

        sig.Init(true, CreateKEY(true, privatekey));

        var bytes = encoding.GetBytes(content);

        sig.BlockUpdate(bytes, 0, bytes.Length);
        byte[] signature = sig.GenerateSignature();


        /* Base 64 encode the sig so its 8-bit clean */
        var signedString = Convert.ToBase64String(signature);

        return signedString;
    }
}`,


sampleUrl : `https://testomnichannellogin.albarakaturk.com.tr/?response_type=code&client_id=api-portal&state=635af1bf85314e0da40312163ab47725&scope=accounts,loans
&redirect_uri=http://mywebsite.com`,

takeAccessToken : `POST /ocf-auth-server/auth/oauth/token HTTP/1.1
Host: apitest.albarakaturk.com.tr
Authorization: Basic cjViNnN3Zm5zbWcybGJ1ODNzNHByamU0bHlvd2k1Mmc6Z0lNUU9zYlN6T1FucDJwJEBvSWNseD1nWlVuaUckSUtlWmdrcS5DcVFFY0BRSVRWSjNlWko0V0dobnhjOThWMg==
Content-Type: application/x-www-form-urlencoded
Content-Length: 89

code=187057638610031645921552017147671482721919777361355527&grant_type=authorization_code&redirect_uri=https%3A%2F%2Fwww.albaraka.com.tr%2F`,

sampleTokenResponse : `{
  "access_token": "a0560aa6-d208-440d-bce0-74933d050eba",
  "token_type": "bearer",
  "refresh_token": "06e25050-08e7-4904-b2c1-43d2e04beaf9",
  "expires_in": 337,
  "scope": "payments accounts investments"
}`,

clientCredentialRequest : `POST /ocf-auth-server/auth/oauth/token HTTP/1.1
Host: apitest.albarakaturk.com.tr
Content-Type: application/x-www-form-urlencoded
Authorization: Basic cjViNnN3Zm5zbWcybGJ1ODNzNHByamU0bHlvd2k1Mmc6Z0lNUU9zYlN6T1FucDJwJEBvSWNseD1nWlVuaUckSUtlWmdrcS5DcVFFY0BRSVRWSjNlWko0V0dobnhjOThWMg==
Content-Length: 42

grant_type=client_credentials&scope=public`,

clientCredentialResponse : `{
  "access_token": "c28a9393-24ed-4bbe-b89c-c9f232e620c9",
  "token_type": "bearer",
  "expires_in": 356,
  "scope": "public"
}`,

corporateTokenRequest : `POST /ocf-auth-server/auth/oauth/token HTTP/1.1
Host: apitest.albarakaturk.com.tr
Authorization: Basic cjViNnN3Zm5zbWcybGJ1ODNzNHByamU0bHlvd2k1Mmc6Z0lNUU9zYlN6T1FucDJwJEBvSWNseD1nWlVuaUckSUtlWmdrcS5DcVFFY0BRSVRWSjNlWko0V0dobnhjOThWMg==
Content-Type: application/x-www-form-urlencoded
Content-Length: 115

grant_type=password&username=12&password=123456&corporateUsername=testUser1&scope=payments%20accounts%20investments`,

refreshTokenRequest : `POST /ocf-auth-server/auth/oauth/token HTTP/1.1
Host: apitest.albarakaturk.com.tr
Authorization: Basic cjViNnN3Zm5zbWcybGJ1ODNzNHByamU0bHlvd2k1Mmc6Z0lNUU9zYlN6T1FucDJwJEBvSWNseD1nWlVuaUckSUtlWmdrcS5DcVFFY0BRSVRWSjNlWko0V0dobnhjOThWMg==
Content-Type: application/x-www-form-urlencoded
Content-Length: 75

grant_type=refresh_token&refresh_token=b5b27ca2-7d5c-4595-9545-f532288d131c`,

refreshTokenResponse : `{
    "access_token": "9be14c58-aaeb-4ece-98bd-2ac056bd6608",
    "token_type": "bearer",
    "refresh_token": "ae2cee42-be31-4b57-ac53-7e6eb0b5b6cb",
    "expires_in": 599,
    "scope": "accounts investments moneytransfers"
}`,

transactionIdResp : `{
  "data": {
      "transactionId": "39F77307-D1B5-4FCE-B390-10ACEB7E45F6",
      "authenticationType": 1
  },
  "header": {
      "status": "SUCCESS",
      "statusCode": 200,
      "warnings": []
  }
}`,

mobileConfirmationResp : `{
  "data": {
      "transactionId": "DED1F23A-A39C-4B71-BEC2-AEC6F50A9FF7",
      "authenticationType": 5,
      "activationId": "116210",
      "requestId": 55863
  },
  "header": {
      "status": "SUCCESS",
      "statusCode": 200,
      "warnings": []
  }
}`,


transactionIdReq: `{
  "transaction_id": "DF9A771F-C5D7-4A04-87BB-6A8C29E2A488",
  "authenticationType": 1,
  "otp_info": "123456"
}`,

mobileConfirmationReq: `{
  "transaction_id": "615FD199-A521-4842-A6BA-CC06A852AA8F",
  "authenticationType": 5,
  "activationId": "116210",
  "requestId": 55859,
}`,

signResponsePublicKey : `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzvFbX8deYL1SDnZu4lrk
rhgAC6A8G0t8+bMpiDbsDG65nb+rH561ZD0wm1yLxZRo3GZn5APfnhuuVEQlPW4c
ditJf54S9dJLiIGr86tFXg06E3EgVpqTTBZYsFFzoODRs4bYJm6k8IzPBQmFtbnm
VuTqVCwufCRTYrDU1sqa4p78UjbvkeZbFiA7fZ6RdUDbIiUYC4lhcYmSKsDUqDna
Ng0MU4JgVigypOUUXc9BFEa0Cj2s1yCHi6EYKp8Xvwm7c1FrhXBqj1g3sJEPkS2a
5xukmU76hSfcwxkFvEOyrbvN/DL/FsbmX4/CknUoGnretqEYR8R4agYtITs9SVO7
OQIDAQAB
-----END PUBLIC KEY-----`,

authorizationIdRequest : `POST /ocf-auth-server/auth/oauth/token HTTP/1.1
Host: apitest.albarakaturk.com.tr
Content-Type: application/x-www-form-urlencoded
Authorization: Basic aWQxcHZhbGpzM210cXpkY3k3d3F4N2czMTUyN3VpNjY6QzdociRqRmlya1dTQGtFUVpqZ2pLQyRBc0hDT0x2VmppdzNPdDZWVVg3czRyWj1NNHU3TXlmV1RwemZoRCFBUQ==
sign: E5nslcEih8TVvwokngmP5NE0TnGskjteGIYunO3iw5GDYJcEoZIcFru7aytRrieC2M31NgWZ/dNdzwwB1OY3+ZQ+X6cExElyUzoRAULfW386KBQXEoJr93SIuwZEWZPTgI2wJG46smtmZFiNUi6A3KCr/9VAEC1T57lcAEfT8iU=
Content-Length: 42

authorization_id=727a2b5a-aa07-4f01-8e13-9ce7c91b62d2&grant_type=authorization_id&scope=baas_accounts baas_moneytransfers baas_investments baas_cancels`,

authorizationIdResponse : `{
  "access_token": "c28a9393-24ed-4bbe-b89c-c9f232e620c9",
  "token_type": "bearer",
  "expires_in": 356,
  "scope": "baas_accounts baas_moneytransfers baas_investments baas_cancels "
}`

}


