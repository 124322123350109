const styles = theme => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#F0F2F1',
    webkitBackgroundSize: 'cover',
    mozBackgroundSize: 'cover',
    oBackgroundSize: 'cover',
    backgroundSize: 'cover'
  },
  typography: {
    width: '25%',
    paddingBottom: '4%'
  },
  title: {
    marginTop: '6%',
    width: '100%',
    height: '44px',
    fontFamily: 'Gilroy',
    fontSize: '44px',
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.2px',
    textAlign: 'center',
    color: '#0f1120'
  },
  description: {
    marginTop: '3%',
    width: '100%',
    height: '57.6px',
    alignSelf: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: '16.2px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.78',
    letterSpacing: '-0.5px',
    textAlign: 'center',
    color: '#4c5068'
  },
  intro: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    paddingBottom: '10%'
  }
});

export default styles;

