/* eslint-disable no-use-before-define */
import {injectRequiredHeaders, fetchWithAccessToken } from './oauth2';
import * as errorUtils from './errorUtils';

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function request(url, options, ignoreError = false) {
  return makeRequest(url, options, ignoreError);
}

export function rawrequest(url, options, ignoreError = false) {
  return makeRequest(url, options, ignoreError, true);
}

/**
 * POST to a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function post(url, body, ignoreError = true) {
  body = body || {};
  body.context = { 'channel': 'APIPORTAL', 'language': 'TR' }; // TODO get language info from device

  const options = {
    method: 'POST',
    body
  };

  return makeRequest(url, options, ignoreError);
}

export function postPreLogin(url, body, ignoreError = true, timeout) {
  body = body || {};
  body.context = { 'channel': 'APIPORTAL', 'language': 'TR' }; // TODO get language info from device
  const options = {
    method: 'POST',
    body
  };

  return makeRequest(url, options, ignoreError, false, true, timeout);
}

/**
 * GET from a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function get(url, ignoreError = false, isRaw = false) {
  const options = {
    method: 'GET'
  };

  return makeRequest(url, options, ignoreError, isRaw);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
function makeRequest(url, options, ignoreError = false, isRaw = false, beforeLogin = false, timeout) {
  return fetchWithAccessToken(() => {
    const optionsWithToken = injectRequiredHeaders(options, isRaw, beforeLogin);

    return wrapper(fetch(url, optionsWithToken), timeout);
    // return fetch(url, optionsWithToken);
  }, isRaw, false, false, beforeLogin)
    .catch((error) => {
      console.log(error);
      if (ignoreError) {
        if (JSON.parse(options.body).showError) {
          const responseError = {};

          Object.assign(
            responseError,
            { isFailure: true, errorMessage: JSON.parse(error.response.resp.data).header.message }
          );
          return responseError;
        }
        // TODO: blok yeniden ele alinip bu method tamamiyla silinebilir
        errorUtils.showSystemError(error);
        // throw error;
      }

      throw error;
    });

  function wrapper(promise, ms = 10000) {
    let timerPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Request Timeout'));
      }, ms);
    });

    return Promise.race([timerPromise, promise]);
  }
}
