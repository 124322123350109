const styles = theme => ({
  footer: {
    display: 'flex',
    flexFlow: 'column wrap',
    backgroundColor: '#FFFFFF !important'
  },
  customWidget: {
    marginLeft: '-20%'
  },

  menu: {
    fontFamily: 'Gilroy-Light',
    fontWeight: '400',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: '#4C5068',
    '& h4': {
      fontFamily:'Gilroy',
      fontSize: '16px',
      color: '#4C5068 !important'
    },
    '& ul li a': {
      fontSize: '16px',
      color: '#778493 !important'
    }
  },
  link: {
    display: 'flex',
    flexFlow: 'column wrap',
    width: '50%'
  }
});

export default styles;

