const styles = theme => ({
  form: {
    paddingBottom: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px'

  },
  btnSubmit: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2%'
  },
  registerButton: {
    marginTop: '15px',
    display: 'flex'
  }
});

export default styles;
