import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.css';
import Button from '../ui/Button';
import { Link } from 'react-router-dom';
import MUTypography from '../ui/MUTypography';
import BasicPageComponent from '../BasicPageComponent';
import FeaturedAPIs from '../FeaturedAPIs';
import HowItWorks from '../HowItWorks';
import Header from '../Header';
import ScreenContainer from '../ui/ScreenContainer';
import { motion } from 'framer-motion';
import ProductCard from '../ProductCard';
import GettingStarted from '../GettingStarted';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreProduct: false,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div >

        <section id="slider" className="slider-element force-full-screen full-screen">
          <div className="force-full-screen full-screen dark bg">

            <div>
              <Header srcImage={require('../../images/albaraka_api_white.png')} homepage={true} />
            </div>

            <div className={classes.intro}>
              <MUTypography paragraph={true} className={classes.typography}>
                Let's build together<br>
                </br>
                the digital products!
              </MUTypography>
            </div>

            <div>
              <Button
                variant='text'
                classes={{ root: classes.btnRegister, label: classes.lblRegister }}
                text="REGISTER"
                component={Link}
                to="/register"
              />
            </div>

          </div>

          <div style={{
            zIndex: '4',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F4F5F6',
            maxHeight: 930,
            marginBottom: '-10%'
          }}>
            <a className={classes.section4} href='#feature' style={{ paddingBottom: 30 }}>
              <img src={require('../../images/scrollButton.png')} />
            </a>
          </div>

     
          <ProductCard showHeader={false}/>
          <GettingStarted></GettingStarted>
          <div id="whyYouShould" style={{
            textAlign: 'center',
            margin: 'auto',
            paddingTop: 72,
            paddingBottom: 72
          }}>
            <div id="whyYouShould" style={{
              textAlign: 'center',
              margin: 'auto',
              paddingTop: 72,
              paddingBottom: 72
            }}>
              <MUTypography className={classes.gettingStartedTypography}>Why You Should Partner With Us</MUTypography>
            </div>
            <div style={{ display: 'flex', maxWidth: '75%', paddingBottom: 56, margin: 'auto', justifyContent: 'center' }}>
              <div style={{ width: '50%' }}>
                <img src={require('../../images/developers.png')} />
                <MUTypography className={classes.developersTypography}>Developers</MUTypography>
                <MUTypography className={classes.developersTypographySub}>Provide valuable offerings through Albaraka’s high quality APIs.</MUTypography>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/innovators.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Innovators in functionality of APIs</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>Access our innovative and extensive APIs</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/ability.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Ability to develop free and fast applications</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>You can quickly develop your applications using our API for free.</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/simple.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Simple and effective sandbox environment</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>Explore our secure sandbox environment, receive error notifications, access analytics and enjoy numerous additional features.</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/documentation.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Documentation suitable for dynamics</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>We provide comprehensive and up-to-date API documentation, easy-to-follow guidelines for registering test applications, sample code and a wealth of additional resources.</MUTypography>
                  </div>
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <img src={require('../../images/business.png')} style={{ height: 64 }} />
                <MUTypography className={classes.developersTypography}>Business</MUTypography>
                <MUTypography className={classes.developersTypographySub}> Create new business opportunities.</MUTypography>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/user.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>User friendly experience</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>Our user-friendly approach is centered around you. We are here to listen, adapt and provide solutions that align with your needs.</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/prime.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Prime security standards</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>We prioritize security and compliance, ensuring that our API products are exceptionally reliable and trustworthy.</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/partnership.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Partnership opportunities</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>Today, Albaraka plays a dynamic role in shaping the API economy by actively participating in collaborative partnerships to develop financial solutions.</MUTypography>
                  </div>
                </div>
                <div style={{ padding: '32px 32px', display: 'flex' }}>
                  <img src={require('../../images/instant.png')} style={{ height: 64 }} />
                  <div style={{ display: 'block', paddingLeft: 16 }}>
                    <MUTypography className={classes.developersTypographysContext}>Instant and continuous support</MUTypography>
                    <MUTypography className={classes.developersTypographySubContext}>You can contact us through our communication channels 24/7.</MUTypography>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="footerImage" id="footer">
            <div style={{ paddingTop: '250px' }}>
              <div>
                <MUTypography className={classes.footerImageText}>
                  ALBARAKA BANKING GROUP
                </MUTypography>
                <MUTypography className={classes.footerImageText}>
                  GLOBAL NETWORK
                </MUTypography>
              </div>
              <div style={{ paddingTop: '40px' }}>
                <MUTypography className={classes.countryList}>
                  JORDAN   INDONESIA   PAKISTAN   BAHRAIN   TURKEY   TUNUSIA   LIBYA   SAUDI ARABIA
                </MUTypography>
                <MUTypography className={classes.countryList}>
                  ALGERIA   SOUTH AFRICA   SUDAN   SYRIA   LEBANON   EGYPT   IRAQ
                </MUTypography>
              </div>
            </div>
          </div>
        </section >
      </div >
    );
  }
}

Home.propTypes = {
  login: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
