import React from 'react';
import PropTypes from 'prop-types';
import {ListItemText} from '@material-ui/core';

class MUListItemText extends React.Component {

  render() {
    const { props } = this;

    return (
      <ListItemText
        className = {props.className}
        inset = {props.inset}
        primary = {props.primary}
        secondary = {props.secondary}
        classes={props.classes}
      >
        {props.children}
      </ListItemText>
    );
  }
}

MUListItemText.propTypes = {
  className: PropTypes.string,
  inset: PropTypes.bool,
  primary: PropTypes.node,
  secondary: PropTypes.node
};

MUListItemText.defaultProps = {
  inset: false
};

export default MUListItemText;
