import React from 'react';
// import {MUTypography} from 'basic-components';
import MUTypography from '../../../../../ui/MUTypography';
import CodeBlock from '@tenon-io/tenon-codeblock';
import sample  from './Texts.js';
import {ScopeTable, AuthCodeReqTable, TakeAccessTokenSuccessResponseTable, 
  TakeAccessTokenFailResponseTable, GetAuthCodeReqTable, ClientCredentialsFlowRequestTable, 
  PasswordFlowReqTable, RefreshTokenReqTable, TakeAccessTokenTable, ClientCredentialsTokenResponseTable,
  AuthorizationIdFlowRequestTable, AuthorizationIdTokenResponseTable} from './index';
   
const Info2 = (props) =>
  <div>
    <MUTypography variant='h4' className={props.classes.h4}>
      Authorization
    </MUTypography>
    <br/>
    <MUTypography className={props.classes.paragraph} variant='display1'paragraph={true}>
  In order to authorize Albaraka APIs, we use OAuth2 protocol. APIs are handled as Public and Private depending on authorization types.
  Public apis that doesn't contain customer data are used with Client Credentials Flow".
  In private APIs which require customer login, Authorization Code Flow or Password Flow is used for access.
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
      Test Customers
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      You can use test customers shown below while logging in to Albaraka Türk Online Banking and making requests.  <br />
      Test Individual Customer Numbers: 1, 2, 3, 4, 5, 6, 7 <br />
      Test Corporate Customer Number: 12 <br />
      Corporate Username for Corporate Customer Number: testUser1<br />
      Password: 123456 ( same for all test customers )
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
      Scopes
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      When you create an application, you can specify which services you want to grant access to.
      <p>Scope information is listed below:</p>
      <ScopeTable/>
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
      Authorization Code Flow
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      In order to use private APIs you can get an Access Token by Authorization Code Flow.<br></br>
      This type of flow require a customer login. (also see RFC-6749(https://tools.ietf.org/html/rfc6749))
    </MUTypography>
    <MUTypography variant='h6' className={props.classes.h6}>
    1. Request for Authorization
    </MUTypography>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      Make a Get request to Albaraka Auth Server for authorization. The request redirects customer to login page.<br></br>
      After customer review scope informations its going to let to use, enters login credentials or deny login process.
      </MUTypography>
      <MUTypography variant='display1' className={props.classes.paragraph}>
      <img src= {require('./getIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://testomnichannellogin.albarakaturk.com.tr/</b>
      </MUTypography>
      <br/>
      <GetAuthCodeReqTable/>
      <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request :
      </MUTypography>
      <br/>
      <div style={{ width: 710 }}>
        <CodeBlock
        language="html"
        codeString={sample['sampleUrl']} />
      </div>
      
    </div>
    <MUTypography variant='h6' className={props.classes.h6}>
    2. Authorization Code Redirect
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    After user grants(or denies) access, Albaraka Auth Server redirects user to "redirect_uri" that provided in first request.<br></br>
    If the user has granted access, an authorization code will be provided. This code will be used to take an access token in next step.
    </MUTypography>

    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Successfull redirecting contains following data:
      <AuthCodeReqTable/>
    </MUTypography>

    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample redirected uri :
    </MUTypography>
    <div style={{ width: 710 }}>
    <CodeBlock
					language="html"
          codeString={"http://mywebsite.com?code=12345&state=635af1bf85314e0da40312163ab47725"} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Or
    </MUTypography>
    <div style={{ width: 710 }}>
    <CodeBlock
					language="html"
          codeString={"http://mywebsite.com?error=access_denied&state=635af1bf85314e0da40312163ab47725"} />
    </div>
    <MUTypography variant='display1' className={props.classes.paragraph}></MUTypography>
    <br/>

    <MUTypography variant='h6' className={props.classes.h6}>
    3. Take Access Token
    </MUTypography>
    <br/>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    This is the last step of authorization process.<br></br>
    If you succeeded in two previous steps you can take Access Token with "authorization code".<br></br>
    Make a POST request to Albaraka Auth Server.
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/ocf-auth-server/auth/oauth/token</b>
    </MUTypography>
    <MUTypography variant='h6' className={props.classes.h6}>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    While using auth server token endpoint “Basic Auth” is required. <br></br>
    Base64 encoded "client_id:client_secret" credentials must be placed in header.
    </MUTypography>
    Body & header parameters:
    </MUTypography>
    <TakeAccessTokenTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
    <CodeBlock
					language="html"
          codeString={sample['takeAccessToken']} />
    </div>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    After succeeded, you'll be provided an Access Token in order to use Albaraka APIs.<br/>
    </MUTypography>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Response:
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    <div style={{ width: 710 }}>
    <CodeBlock
      className="codeBlock"
      language="html"
      codeString={sample['sampleTokenResponse']} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Response parameters:
    </MUTypography>
    <TakeAccessTokenSuccessResponseTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      On error case:
    </MUTypography>
    <TakeAccessTokenFailResponseTable/>
    </MUTypography>

    <MUTypography variant='h5' className={props.classes.h5}>
    Client Credentials Flow
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Just take a token with client_id and client_secret credentials, then call Albaraka Public APIs and analytic APIS.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/ocf-auth-server/auth/oauth/token</b>
    </MUTypography>
    <br/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Body & header parameters:
    </MUTypography>
    <br/>
    <ClientCredentialsFlowRequestTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
    <CodeBlock
			language="html"
      codeString={sample['clientCredentialRequest']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response:
    </MUTypography>
    <br></br>
    <div style={{ width: 710 }}>
    <CodeBlock
      language="html"
      codeString={sample['clientCredentialResponse']} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response parameters:
    </MUTypography>
    <ClientCredentialsTokenResponseTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    On error case:
    </MUTypography>
      <br></br>
      <TakeAccessTokenFailResponseTable/>
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      On a successful response from Authorization Server, you'll be provided an Access Token.
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
    Password Flow
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    If you do not want to use authorization code flow, you can directly make a request to the Albaraka Auth Server.<br></br>
    You must pass your client information in the header again.<br></br>
    You just have to additionally pass in the body the customer information you want to generate the token.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/ocf-auth-server/auth/oauth/token</b>
    </MUTypography>
    <br/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Body & header parameters:
    </MUTypography>
    <br/>
    <PasswordFlowReqTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
    <CodeBlock
      style={{ width: 100 }}
			language="html"
      codeString={sample['corporateTokenRequest']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
    <CodeBlock
      language="html"
      codeString={sample['sampleTokenResponse']} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response parameters:
    </MUTypography>
    <TakeAccessTokenSuccessResponseTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    On error case:
    </MUTypography>
      <br/>
      <TakeAccessTokenFailResponseTable/>
    </MUTypography>

    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      On a successful response from Authorization Server, you'll be provided an Access Token.
    </MUTypography>

    <MUTypography variant='h5' paragraph={true} className={props.classes.h5}>
    Refreshing Access Token
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Refresh Token has bigger lifetime than Access Token, so it's used to refresh access token.<br></br>
    The tokens provided via Authorization Code Flow are refreshed as in the Client Credentials Flow without user login.
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    On a successful response from Authorization Server, you'll be provided a new Access Token.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/ocf-auth-server/auth/oauth/token</b>
    </MUTypography>
    <br/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Body & header parameters:
    </MUTypography>
    <br/>
    <RefreshTokenReqTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request:
    </MUTypography>
    <div style={{ width: 710 }}>
    <CodeBlock
				language="html"
        codeString={sample['refreshTokenRequest']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    <br/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
      <CodeBlock
				language="html"
        codeString={sample['refreshTokenResponse']} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response parameters:
    </MUTypography>
    <TakeAccessTokenSuccessResponseTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    On error case:
    </MUTypography>
    <br/>
      <TakeAccessTokenFailResponseTable/>
    </MUTypography>

    <MUTypography variant='h5' className={props.classes.h5}>
    Authorization Id Flow
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Just take a token with client_id, client_secret credentials and customerId, then call Baas APIS.This flow is only used for Baas services.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/ocf-auth-server/auth/oauth/token</b>
    </MUTypography>
    <br/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Body & header parameters:
    </MUTypography>
    <br/>
    <AuthorizationIdFlowRequestTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
      Sample request:
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
    <CodeBlock
			language="html"
      codeString={sample['authorizationIdRequest']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response:
    </MUTypography>
    <br></br>
    <div style={{ width: 710 }}>
    <CodeBlock
      language="html"
      codeString={sample['authorizationIdResponse']} />
    </div>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Response parameters:
    </MUTypography>
    <AuthorizationIdTokenResponseTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    On error case:
    </MUTypography>
      <br></br>
      <TakeAccessTokenFailResponseTable/>
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      On a successful response from Authorization Server, you'll be provided an Access Token.
    </MUTypography>
    
  </div>;

export default Info2;

