import React, { Component } from 'react';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import * as apiUserService from '../../services/confirm';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import Button from '../ui/Button';
import classNames from 'classnames';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onConfirmProgress: true,
      error: false,
      errorMessage: ''
    };
  }

  componentWillMount() {
    this.sendConfirm();
  }
  sendConfirm = () => {
    apiUserService.doConfirm(this.props.match.params.key)
      .then((responseJson) => {
        this.setState({ onConfirmProgress: false });
        console.log('Service Response: ' + responseJson);
      })
      .catch((exception) => {
        this.setState({ onConfirmProgress: false });
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            this.setState({
              error: true,
              errorMessage: exception.message
            });
          } else {
            this.setState({
              error: true,
              errorMessage: 'System error occured'
            });
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            this.setState({
              error: true,
              errorMessage: r.header.message
            });
          });
      });
  }

  render() {
    const { onConfirmProgress, error, errorMessage } = this.state;
    const { classes } = this.props;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title=''
          description=''
          children= {
            <form id="login-form" name="login-form" className="nobottommargin" action="#" method="post">
              <div className="col_full" align="center"
                style = {{
                  marginTop: '-15%',
                  paddingBottom: '10%'}}>
                {
                  onConfirmProgress ?
                    <h3 className = {classes.message}>Activation is completing...</h3> :
                    (error ?
                      <div>
                        <img src={require('../../images/fail.png')} />
                        <h3 className = {classes.message}>Activation failed. Reason : {errorMessage}</h3>
                      </div> :
                      <div>
                        <img src={require('../../images/success.png')} />
                        <h3 className = {classes.message}>Your activation completed.</h3>
                      </div>
                    )
                }
              </div>
              { !onConfirmProgress && !error ?
                <div className="col_full nobottommargin" align="center">
                  {error ? null :
                    <Button
                      variant = 'outlined'
                      color = 'primary'
                      component={Link}
                      to="/login"
                      text="LOG IN"
                      className= {classNames('outlinedPrimary', classes.btnChange)}></Button>
                  }
                </div> : null }
            </form>
          }
        />

      </div>
    );
  }
}

export default withStyles(styles)(Confirm);
