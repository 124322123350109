import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import {Button as MaterialButton} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    }
  },
  overrides: {
    MuiButton: {
      outlinedPrimary: {
        width: '216px',
        marginLeft: '0.5%',
        height: '50px',
        borderRadius: '32px',
        backgroundColor: '#0f1120',
        '&:hover': {
          borderColor: 'black',
          color: 'black'
        }
      },
      raisedPrimary: {
        color: 'white'
      }
    }
  }
});

const Button = (props) => {

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialButton variant={props.variant}
        color={props.color}
        className={props.className}
        classes={props.classes}
        onClick={props.onClick}
        type={props.type}
        component={props.component}
        to={props.to}
        disabled={props.disabled}
        style={props.style}
      >
        {props.icon}
        {props.text}
      </MaterialButton>
    </MuiThemeProvider>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.element,
  component: PropTypes.func,
  to: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  text: 'buttontext'
};

export default withStyles(styles)(Button);
