import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import styles from './style';
import './style.css';
import * as Actions from '../../redux/actions';
import { getProducts } from '../../redux/selectors';
import * as apiDashBoardService from '../../services/apidashboardservice';
import MUCard from '../ui/MUCard';
import MUCircularProgress from '../ui/MUCircularProgress';
import { history } from '../../App';
import { docsInfoDynamicContentScreen } from '../../redux/constants/docsInfoContentTypes';
import Button from '../ui/Button';
import BasicPageComponent from '../BasicPageComponent';
import Header from '../Header';
import MUTypography from '../ui/MUTypography';
import { motion } from 'framer-motion';
import Discover from './discover';
import Register from './register';
import CreateAPI from './createAPI';
import GoLive from './goLive';

class GettingStartedSub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: props.productsFromStore,
            onServiceCall: false,
            showMoreProduct: false,
            isHovered: false,
            isHoveredRegister: false,
            isHoveredCreateApp: false,
            isHoveredDevelopApp: false,
            isHoveredGoLive: false
        };

    }
    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };
    handleRegisterMouseEnter = () => {
        this.setState({ isHoveredRegister: true });
    };

    handleRegisterMouseLeave = () => {
        this.setState({ isHoveredRegister: false });
    };
    handleCreateAppMouseEnter = () => {
        this.setState({ isHoveredCreateApp: true });
    };

    handleCreateAppMouseLeave = () => {
        this.setState({ isHoveredCreateApp: false });
    };
    handleDevelopAppMouseEnter = () => {
        this.setState({ isHoveredDevelopApp: true });
    };

    handleDevelopAppMouseLeave = () => {
        this.setState({ isHoveredDevelopApp: false });
    };
    handleGoLiveMouseEnter = () => {
        this.setState({ isHoveredGoLive: true });
    };

    handleGoLiveMouseLeave = () => {
        this.setState({ isHoveredGoLive: false });
    };
    handleButtonMediumClick = () => {
        const url = 'https://albarakagaraj.medium.com/ayl%C4%B1k-garajlog-b%C3%BClteni-3119f28607ad';
        window.open(url, '_blank');
    };
    handleButtonClick = () => {
        this.props.history.push('/gettingStarted');
    };

    render() {
        const { classes } = this.props;
        const { isHovered, isHoveredRegister, isHoveredCreateApp, isHoveredDevelopApp, isHoveredGoLive } = this.state;

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Header />
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        padding: '56px 508px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        backgroundColor: '#0076FF'
                    }}>
                    <MUTypography
                        className={classes.gettingStartedTypography}>
                        Getting Started
                    </MUTypography>
                </div>
                <div
                    style={{
                        display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start',
                        width: '100%',
                        padding: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                            padding: '80px 96px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: isHovered ? '#F4F5F6' : 'white'
                        }}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}>
                        <Discover></Discover>
                        <div style={{ backgroundColor: isHovered ? '#F4F5F6' : 'white', paddingLeft: 100 }}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}>

                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            backgroundColor: isHovered ? '#0076FF' : '#8B96A4',
                                            display: 'flex',
                                            width: '56px',
                                            height: '56px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#8B96A4',
                                        }}
                                        onMouseEnter={this.handleMouseEnter}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <span style={{
                                            backgroundColor: isHovered ? '#0076FF' : '#8B96A4',
                                            color: 'white',
                                            textAlign: 'center',
                                            leadingTrim: 'both',
                                            textEdge: 'cap',
                                            fontFamily: 'Gilroy',
                                            fontSize: 24,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}>1</span>
                                    </div>
                                    <span style={{
                                        color: '#4C5068',
                                        fontFamily: 'Gilroy',
                                        fontSize: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.608px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginLeft: '16px'
                                    }} onMouseEnter={this.handleMouseEnter}
                                        onMouseLeave={this.handleMouseLeave}>
                                        DISCOVER OUR APIs
                                    </span>
                                </div>


                                <div
                                    style={{
                                        paddingTop: 10,
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                    }}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                >
                                    <MUTypography className={classes.typography}>Visit our <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/productCard'}>Discover APIs</span> page to find the APIs that are suitable for you.</MUTypography>

                                </div>
                            </div>

                        </div>

                    </div>
                </div >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        padding: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                            padding: '78px 60px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: isHoveredRegister ? '#F4F5F6' : 'white'
                        }}
                        onMouseEnter={this.handleRegisterMouseEnter}
                        onMouseLeave={this.handleRegisterMouseLeave}>
                        <div style={{ backgroundColor: isHoveredRegister ? '#F4F5F6' : 'white', paddingRight: 100 }} onMouseEnter={this.handleRegisterMouseEnter}
                            onMouseLeave={this.handleRegisterMouseLeave}>

                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            backgroundColor: isHoveredRegister ? '#0076FF' : '#8B96A4',
                                            display: 'flex',
                                            width: '56px',
                                            height: '56px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#8B96A4',
                                        }}
                                        onMouseEnter={this.handleRegisterMouseEnter}
                                        onMouseLeave={this.handleRegisterMouseLeave}
                                    >
                                        <span style={{
                                            backgroundColor: isHoveredRegister ? '#0076FF' : '#8B96A4',
                                            color: 'white',
                                            textAlign: 'center',
                                            leadingTrim: 'both',
                                            textEdge: 'cap',
                                            fontFamily: 'Gilroy',
                                            fontSize: 24,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}>2</span>
                                    </div>
                                    <span style={{
                                        color: '#4C5068',
                                        fontFamily: 'Gilroy',
                                        fontSize: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.608px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginLeft: '16px'
                                    }} onMouseEnter={this.handleRegisterMouseEnter}
                                        onMouseLeave={this.handleRegisterMouseLeave}>
                                        REGISTER ON SITE
                                    </span>
                                </div>


                                <div
                                    style={{
                                        paddingTop: 10,
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                    }}
                                    onMouseEnter={this.handleRegisterMouseEnter}
                                    onMouseLeave={this.handleRegisterMouseLeave}
                                >
                                    <MUTypography className={classes.typography}>Click <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/register'}>Register </span> from the home page.
                                        Enter your name, your lastname, your email, your password and choose your country.
                                        Click the activation link sent to your email to activate your account.
                                        You’ll be ready for developing after click activation link.</MUTypography>

                                </div>
                            </div>

                        </div>
                        <Register></Register>
                    </div>
                </div >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        padding: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                            padding: '80px 96px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: isHoveredCreateApp ? '#F4F5F6' : 'white'
                        }}
                        onMouseEnter={this.handleCreateAppMouseEnter}
                        onMouseLeave={this.handleCreateAppMouseLeave}>

                        <CreateAPI> </CreateAPI>
                        <div style={{ backgroundColor: isHoveredCreateApp ? '#F4F5F6' : 'white', paddingLeft: 100 }}
                            onMouseEnter={this.handleCreateAppMouseEnter}
                            onMouseLeave={this.handleCreateAppMouseLeave}>

                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            backgroundColor: isHoveredCreateApp ? '#0076FF' : '#8B96A4',
                                            display: 'flex',
                                            width: '56px',
                                            height: '56px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#8B96A4',
                                        }}
                                        onMouseEnter={this.handleCreateAppMouseEnter}
                                        onMouseLeave={this.handleCreateAppMouseLeave}
                                    >
                                        <span style={{
                                            backgroundColor: isHoveredCreateApp ? '#0076FF' : '#8B96A4',
                                            color: 'white',
                                            textAlign: 'center',
                                            leadingTrim: 'both',
                                            textEdge: 'cap',
                                            fontFamily: 'Gilroy',
                                            fontSize: 24,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}>3</span>
                                    </div>
                                    <span style={{
                                        color: '#4C5068',
                                        fontFamily: 'Gilroy',
                                        fontSize: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.608px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginLeft: '16px'
                                    }} onMouseEnter={this.handleCreateAppMouseEnter}
                                        onMouseLeave={this.handleCreateAppMouseLeave}>
                                        CREATE APP AND USE APIs
                                    </span>
                                </div>
                                <div
                                    style={{
                                        paddingTop: 10,
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                    }}
                                    onMouseEnter={this.handleCreateAppMouseEnter}
                                    onMouseLeave={this.handleCreateAppMouseLeave}
                                >
                                    <MUTypography className={classes.typography}>Click on the New Application button on the Dashboard page.
                                        Fill out the Name, Description, URI and Public Key fields that are required.
                                        IP field, let us to prevent request made from other than your servers, is optional in developer environment but required in live environment.
                                        Activate apis required for your application. Its available to switch apis in time.
                                        Click to Add button</MUTypography>

                                </div>
                            </div>

                        </div>

                    </div>
                </div >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        padding: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                            padding: '80px 96px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: isHoveredDevelopApp ? '#F4F5F6' : 'white'

                        }}
                        onMouseEnter={this.handleDevelopAppMouseEnter}
                        onMouseLeave={this.handleDevelopAppMouseLeave}>
                        <div style={{ backgroundColor: isHoveredDevelopApp ? '#F4F5F6' : 'white', paddingRight: 100 }}
                            onMouseEnter={this.handleDevelopAppMouseEnter}
                            onMouseLeave={this.handleDevelopAppMouseLeave}>

                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            backgroundColor: isHoveredDevelopApp ? '#0076FF' : '#8B96A4',
                                            display: 'flex',
                                            width: '56px',
                                            height: '56px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#8B96A4',
                                        }}
                                        onMouseEnter={this.handleDevelopAppMouseEnter}
                                        onMouseLeave={this.handleDevelopAppMouseLeave}
                                    >
                                        <span style={{
                                            backgroundColor: isHoveredDevelopApp ? '#0076FF' : '#8B96A4',
                                            color: 'white',
                                            textAlign: 'center',
                                            leadingTrim: 'both',
                                            textEdge: 'cap',
                                            fontFamily: 'Gilroy',
                                            fontSize: 24,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}>4</span>
                                    </div>
                                    <span style={{
                                        color: '#4C5068',
                                        fontFamily: 'Gilroy',
                                        fontSize: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.608px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginLeft: '16px'
                                    }} onMouseEnter={this.handleDevelopAppMouseEnter}
                                        onMouseLeave={this.handleDevelopAppMouseLeave}>
                                        DEVELOP YOUR APP
                                    </span>
                                </div>


                                <div
                                    style={{
                                        paddingTop: 10,
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                    }}
                                    onMouseEnter={this.handleDevelopAppMouseEnter}
                                    onMouseLeave={this.handleDevelopAppMouseLeave}
                                >
                                    <MUTypography className={classes.typography}>Begin the development process by utilizing the sandbox environment. This platform provides access to simulated data, allowing you to safely experiment with all API features.
                                        For detailed instructions on using a specific API within the sandbox environment, refer to the relevant API  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/productCard'}>product documentation</span>. To explore the complete list of available APIs, visit the <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/productCard'}>Discover APIs</span> page.</MUTypography>

                                </div>
                            </div>

                        </div>
                        <img src={require('../../images/developApi.png')} />

                    </div>
                </div >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        padding: '56px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '70%',
                            padding: '80px 96px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: isHoveredGoLive ? '#F4F5F6' : 'white'
                        }}
                        onMouseEnter={this.handleGoLiveMouseEnter}
                        onMouseLeave={this.handleGoLiveMouseLeave}>
                        <GoLive></GoLive>
                        <div style={{ backgroundColor: isHoveredGoLive ? '#F4F5F6' : 'white', paddingLeft: 100 }}
                            onMouseEnter={this.handleGoLiveMouseEnter}
                            onMouseLeave={this.handleGoLiveMouseLeave}>

                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            backgroundColor: isHoveredGoLive ? '#0076FF' : '#8B96A4',
                                            display: 'flex',
                                            width: '56px',
                                            height: '56px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            background: '#8B96A4',
                                        }}
                                        onMouseEnter={this.handleGoLiveMouseEnter}
                                        onMouseLeave={this.handleGoLiveMouseLeave}
                                    >
                                        <span style={{
                                            backgroundColor: isHoveredGoLive ? '#0076FF' : '#8B96A4',
                                            color: 'white',
                                            textAlign: 'center',
                                            leadingTrim: 'both',
                                            textEdge: 'cap',
                                            fontFamily: 'Gilroy',
                                            fontSize: 24,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: 'normal',
                                        }}>5</span>
                                    </div>
                                    <span style={{
                                        color: '#4C5068',
                                        fontFamily: 'Gilroy',
                                        fontSize: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.608px',
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        marginLeft: '16px'
                                    }} onMouseEnter={this.handleGoLiveMouseEnter}
                                        onMouseLeave={this.handleGoLiveMouseLeave}>
                                        THAN GO LIVE
                                    </span>
                                </div>
                                <div
                                    style={{
                                        paddingTop: 10,
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        textAlign: 'left',
                                    }}
                                    onMouseEnter={this.handleGoLiveMouseEnter}
                                    onMouseLeave={this.handleGoLiveMouseLeave}
                                >
                                    <MUTypography className={classes.typography}>When you are confident that your application is working as desired, you can request access by contacting us through the <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/contact'}>Contact Us</span> section.</MUTypography>

                                </div>
                            </div>

                        </div>

                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
    productsFromStore: getProducts(state)
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(GettingStartedSub);
