const styles = theme => ({
  list: {
    width: '100%'
  },
  listItem: {
    width: '100%',
    minWidth: '240px',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    /* align-items: center; */
    paddingTop: '11px',
    paddingBottom: '11px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    '&$selected, &$selected:hover, &$selected:focus': {
      backgroundColor: '#50e3c2'
    }
  },
  listItemText: {
    fontSize: '14px',
    fontFamily: 'Gilroy-Light',
    fontWeight: 'bolder'
  },
  collapse: {
    backgroundColor: '#50e3c2'
  },
  collapseItemText: {
    fontSize: '12px',
    fontFamily: 'Gilroy-Light'

  },
  selected: {}
});

export default styles;

