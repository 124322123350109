import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.css';
import MUTypography from '../ui/MUTypography';
import { motion } from 'framer-motion';
import Button from '../ui/Button';
import Header from '../Header';

class Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreProduct: false,
            isHovered: false,
            isHoveredRegister: false,
            isHoveredCreateApp: false,
            isHoveredDevelopApp: false,
            isHoveredGoLive: false,
            windowWidth: window.innerWidth
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };
    handleButtonMediumClick = () => {
        const url = 'https://medium.com/albaraka-open-banking';
        window.open(url, '_blank');
    };
    handleButtonSlackClick = () => {
        const url = 'https://join.slack.com/t/albarakaapi/shared_invite/zt-24pex8dlv-htHYqVb4SdZkM9~DI1fPWQ';
        window.open(url, '_blank');
    };
    render() {
        const { classes } = this.props;
        const widthSize = this.state.windowWidth < 1650;

        return (
            <div>
                <Header></Header>

                <div style={{ position: 'relative' }}>
                    <img style={{ width: '70%', marginLeft: '15%' }} src={require('../../images/community.png')} alt="community" />
                    <div style={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFF',
                        textAlign: 'center',
                        zIndex: 1, // Yukarıda gösterilecek diğer içeriklerin önüne geçmesini sağlar
                    }}>
                        <MUTypography className={classes.developerBlogTitle}>
                            Developer Blogs
                        </MUTypography>
                        <MUTypography className={classes.developerBlogText}>
                            Our developer blogs are inclusive, serving both business and IT developers. We offer insights into innovation and technology, provide API updates and share inspiring stories to honor your accomplishment and contributions.
                        </MUTypography>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}>
                            <Button
                                variant='text'
                                classes={{ root: widthSize?classes.showMoreButton2:classes.showMoreButton, label: classes.showMoreBtnTxt }}
                                text="READ OUR BLOGS"
                                onClick={this.handleButtonMediumClick}
                            
                            />
                        </div>
                    </div>
                </div>

                <div style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2, // Birinci görselin üzerine gelecek, ama altta kalacak
                }}>
                    <img style={{ width: '100%', height: '100%', margin: 'auto' }} src={require('../../images/become.png')} alt="become" />
                </div>

                <div style={{ paddingTop: 100, position: 'relative' }}>
                    <img style={{ width: '70%', marginLeft: '15%' }} src={require('../../images/slack.png')} alt="slack" />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFF',
                        textAlign: 'center',
                    }}>
                        <MUTypography className={classes.developerBlogTitle}>
                            Slack Channel
                        </MUTypography>
                        <MUTypography className={classes.developerBlogText}>
                            Connect with developers and the IT team in real-time using our Slack channels. Exchange ideas on the same topics.
                        </MUTypography>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}>
                            <Button
                                variant='text'
                                classes={{ root: classes.showMoreButton, label: classes.showMoreBtnTxt }}
                                text="JOIN OUR CHANNELS"
                                onClick={this.handleButtonSlackClick}

                            />
                        </div>
                    </div>
                </div>
                <MUTypography className={classes.footerTypography}>
                    Let’s collaborate and drive innovation together
                </MUTypography>
                <MUTypography className={classes.footerTypographyText}>
                    We provide valuable insights into the World of innovation and technology
                    <br>
                    </br>and showcase inspiring stories.
                </MUTypography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingBottom:163
                }}>
                    <Button
                        variant='text'
                        classes={{ root: classes.footerButton, label: classes.footerBtnTxt }}
                        text="BECOME A PARTNER"
                        onClick={() => window.location.href = '/register'}
                    />
                </div>
                
            </div>
        );
    }
}

Community.defaultProps = {
    showHeader: true,
};
Community.propTypes = {
    login: PropTypes.object.isRequired,
    showHeader: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(Community);
