import React from 'react';

class WrappedOperationTag extends React.Component {
  render() {
    const {
      children,
      getComponent
    } = this.props;

    const Collapse = getComponent('Collapse');

    return (
      <div className={'opblock-tag-section is-open'} >
        <Collapse isOpened={true}>
          {children}
        </Collapse>
      </div>
    );
  }
}

const MyWrapComponentPlugin = function (system) {
  return {
    wrapComponents: {
      OperationTag: (Original, system) => (props) => { return <WrappedOperationTag {...props} />; }
    }
  };
};

export default MyWrapComponentPlugin;
