import * as actionTypes from '../constants/actionTypes';

const initialState = {
  message: '',
  variant: 'error',
  status: false
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return {
        message: action.message || '',
        variant: action.variant || 'error', // success, error, info, warning
        status: action.status || true
      };
    case actionTypes.CLEAR_NOTIFICATION:
      return {
        message: '',
        variant: action.variant,
        status: false
      };
    default:
      return state;
  }

}
