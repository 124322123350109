import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Apps, AddBox, ShowChart} from '@material-ui/icons';
import View from '../View';
import Applications from './Applications';
import NewApplication from '../NewApplication';
import MUGrid from '../ui/MUGrid';
import Analytics from '../Analytics';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
class MyApplications extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      applications: false,
      analytics: false,
      newapplication: false,
      appId: '',
      readOnly: false,
      editapplication: false
    };

    this.state = this.initialState;
  }

  componentWillMount() {

    this.setState({applications: true});
  }

  onCardClick = (id, applications) => {
    console.log('card clicked in my apps');

    this.setState({
      applications: false,
      newapplication: false,
      editapplication: true
    });
  }

  resetState = () => {
    this.setState(this.initialState);
  }

  handleClick = (e, component) => {

    this.resetState();
    this.setState(
      {
        [component]: true,
        editapplication: false
      });
  }
  render() {
    const { classes } = this.props;
    const { editapplication, newapplication} = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title='My Applications'
          description='Via My Applications page, you can create new applications or you can edit existing applications.'
          children = {
            <View className='container'>
              <MUGrid container spacing={24}>

                <MUGrid item xs={3}>
                  <MenuList className={classes.menuList}>

                    <MenuItem classes = {{selected: classes.selected}} selected ={newapplication}
                      onClick={(e) => this.handleClick(e, 'newapplication')}>
                      <ListItemIcon className={classes.icon}>
                        <AddBox />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.primary }} inset primary={'New Application'} />
                    </MenuItem>

                    <MenuItem classes = {{selected: classes.selected}} onClick={(e) => this.handleClick(e, 'applications')} selected ={this.state.applications}>
                      <ListItemIcon className={classes.icon}>
                        <Apps />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.primary }} inset primary="My Applications" />
                    </MenuItem>

                    {/* <MenuItem classes = {{selected: classes.selected}} selected ={this.state.analytics} onClick={(e) => this.handleClick(e, 'analytics')}>
                    <ListItemIcon className={classes.icon}>
                      <ShowChart />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary="Analytics" />
                  </MenuItem> */}

                  </MenuList>
                </MUGrid>
                <MUGrid item xs={9}>
                  <Applications open ={this.state.applications} {...this.props} onCardClick = {this.onCardClick} onAddClick = {(e) => this.handleClick(e, 'newapplication')}/>
                  {editapplication ? <NewApplication open = {editapplication} editapplication={editapplication} newapplication={newapplication} {...this.props} {...this.state} /> :
                    null}
                  {newapplication ? <NewApplication open = {newapplication} editapplication={newapplication} newapplication={newapplication} {...this.props} {...this.state} /> :
                    null}
                  {this.state.analytics ? <Analytics /> : null}
                </MUGrid>
              </MUGrid>

            </View>
          } />

      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MyApplications);

