const styles = theme => ({
  intro: {
    paddingTop: '15%',
    paddingLeft: '15vw',
    marginBottom: '3vh'
  },
  typography: {
    width: '33vw',
    color: '#ffffff',
    fontSize: '72px',
    fontStyle: 'normal',
    width: '100%',
    height: 'auto',
    maxHeight: '-webkit-fill-available',
    fontFamily: 'Gilroy',
    fontWeight: 900,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-2.028px'
  },
  txtRegister: {
    width: '149px',
    height: '14px',
    fontSize: '14px !important',
    fontFamily: 'Gilroy-Bold',
    fontWeight: 'bolder',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#4a90e2'
  },
  lblRegister: {
    fontSize: '14px',
    fontFamily: 'bolder',
    lineHeight: 'normal',
    color: '#0076FF',
    width: 149,
    paddingBottom: 18,
    paddingTop: 21,
    paddingLeft: 46,
    paddingRight: 45,
    fontFamily: 'Gilroy'
  },
  btnRegister: {
    width: '240px',
    height: '56px',
    borderRadius: '32px',
    backgroundColor: '#ffffff',
    fontSize: '14px !important',
    color: '#0076FF',
    marginLeft: '15vw',
    zIndex: 9999,
    marginTop: 70

  },
  section4: {
    webkitTransform: 'translate(0, -50%)',
    transform: 'translate(0, -50%)',
    color: '#fff',

  },
  span: {
    position: 'absolute',
    top: '0',
    left: '50%',
    width: '46px',
    height: '46px',
    marginLeft: '-23px',
    borderRadius: '100%',
    boxSizing: 'border-box',
    display: 'inline-block'
  },
  scroll: {
    alignSelf: 'center',
    paddingTop: '5%'
  },
  scrollIcon: {
    fontSize: '100px'
  },
  footerImageText: {
    backgroundImage: 'linear-gradient(to right, #3023ae 30%, #53a0fd 44%, #b4ec51 75%)',
    fontFamily: 'Gilroy',
    fontSize: '66px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.91',
    textAlign: 'center',
    '-webkitBackgroundClip': 'text',
    '-webkitTextFillColor': 'transparent'
  },
  countryList: {
    fontFamily: 'Gilroy',
    fontSize: '27px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    wordSpacing: '20px',
    textAlign: 'center',
    color: '#332ab3'
  },
  productCard: {
    padding: 15, // 48 piksel padding
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '1px solid #ddd', // Çizgileri belirgin hale getirme
  },
  cardContainer: {
    border: '2px solid red',
    textAlign: 'center',
    width: '100%',
    margin: 'auto'
  },
  screenContainer: { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', padding: '30px 0', paddingBottom: 0, fontFamily: 'Gilroy' },
  screenSubContainer: { padding: 10, width: '33%', minWidth: 300, backgroundColor: '#F4F5F6' },
  screenMoreContainer: { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', fontFamily: 'Gilroy' },
  screenContainerContext: { padding: 48, backgroundColor: 'white' },
  screenImage: { paddingBottom: 48 },
  containerTypography: {
    display: 'flex',
    width: '277px',
    height: '120px',
    flexDirection: 'column',
    alignItems: 'flex - start',
    gap: '4px',
    color: '#4C5068',
    fontFamily: 'Gilroy',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.608px'
  },
  showMoreButton: {
    width: '216px',
    height: '50px',
    flexShrink: '0',
    borderRadius: '32px',
    color: 'white',
    backgroundColor: 'black',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    lineHeight: 'normal',
  },
  showMoreBtnTxt: {
    color: 'white',
  },
  findoutTypography: {
    color: '#3951BD',
    padding: 15,
    fontFamily: 'Gilroy-Light',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  gettingStartedTypography: {
    color: '#0F1120',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: 44,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-2.239px'
  },
  developersTypography: {
    color: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.608px'
  },
  developersTypographySub:{
    color: '#4C5068',
    textAlign: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: 16.2,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.456px'
  },

  developersTypographysContext:{
    color: '#4C5068',
    textAlign: 'left',
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.608px'
  },
  developersTypographySubContext:{
    color: '#4C5068',
    textAlign: 'left',
    fontFamily: 'Gilroy-Light',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.456px',
    marginTop:10
  },
});

export default styles;
