const styles = theme => ({
  gettingStartedTitle: {
    color: '#0F1120',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: 44,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-2.239px'
  },
  screenContainer: {
    margin: 'auto',
    display: 'flex',
    padding: ' 30px 0',
    fontFamily: 'Gilroy',
    alignContent: 'space-around',
    paddingBottom: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start'
  },
  getStartBtn: {
    width: '182px',
    height: '44px',
    flexShrink: '0',
    borderRadius: '24px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    lineHeight: 'normal',
  },
  letsStartBtn: {
    width: '225px',
    height: '30px',
    borderRadius: '32px',
    border: '1px solid white',
    color: 'white',
    backgroundColor: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '22px !important',
    lineHeight: '28.8px',
    padding: '12px 32px',
  },
  getStartBtnTxt: {
    color: 'white',
  },
  letsStartTxt: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: '16.2px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28.8px',
    letterSpacing: '-0.456px'
  },
  gettingStartedTypography: {
    color: '#8B96A4',
    fontFamily: 'Gilroy-Light',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.456px',
    textAlign:'left'
  }
});

export default styles;
