import React from 'react';
import PropTypes from 'prop-types';
import {List} from '@material-ui/core';

class MUList extends React.Component {

  render() {
    const { props } = this;

    return (
      <List
        component={props.component}
        disablePadding = {props.disablePadding}
      >
        {props.children}
      </List>
    );
  }
}

MUList.propTypes = {
  component: PropTypes.string,
  disablePadding: PropTypes.bool
};

MUList.defaultProps = {
  disablePadding: false
};

export default MUList;
