import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';

import MUTypography from '../ui/MUTypography';

class BasicPageComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.intro} >
          <MUTypography paragraph={true} className={classes.title}>
            {this.props.title}
          </MUTypography>
          <div style={{margin:'auto'}}>
            <MUTypography
              paragraph={true}
              className={classes.description}
              style={{ color:'#4C5068',fontSize: 16.2, fontFamily: 'Gilroy-Medium' }}
            >
              {this.props.description}
            </MUTypography>
          </div>
        </div>
        <div className={classes.body}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BasicPageComponent);

