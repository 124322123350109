export const SERVER_BASE_URL = 'https://developer.albarakaturk.com.tr';
export const DOCS_BASE_URL = SERVER_BASE_URL + '/api-docs-service';

export const APIUSERSERVICE_CONFIRM = '/api-user-service/user/activate';
export const APIUSERSERVICE_CHANGE_PASSWORD_BY_KEY = '/api-user-service/user/changePasswordByKey';
export const APIUSERSERVICE_REGISTER = '/api-user-service/user/register';
export const APIUSERSERVICE_RESET_PASSWORD = '/api-user-service/user/resetpassword';
export const APIUSERSERVICE_SAVE_CONTACT = '/api-user-service/user/savecontact';
export const APIUSERSERVICE_GET_USER = '/api-user-service/user/getuser';
export const APIUSERSERVICE_UPDATE_USER = '/api-user-service/user/updateuser';
export const APIUSERSERVICE_CHANGE_PASSWORD = '/api-user-service/user/changepassword';
export const APIUSERSERVICE_REACTIVATION = '/api-user-service/user/reactivate';

export const AUTHSERVER_TOKEN = SERVER_BASE_URL + '/auth-server/auth/oauth/token';
export const AUTHSERVER_TOKEN_REVOKE = SERVER_BASE_URL + '/auth-server/auth/user/token/revoke';

export const APIDASHBOARDSERVICE_PRODUCTS = '/api-dashboard-service/dashboard/products';
export const APIDASHBOARDSERVICE_APPLICATIONS = '/api-dashboard-service/dashboard/applications';
export const APIDASHBOARDSERVICE_PRODUCT_SERVICES = '/api-dashboard-service/dashboard/services';
export const APIDASHBOARDSERVICE_PRODUCT_SERVICES_BY_ID = '/api-dashboard-service/dashboard/product/services';
export const APIDASHBOARDSERVICE_SAVE_APPLICATION = '/api-dashboard-service/dashboard/saveapplication';
export const APIDASHBOARDSERVICE_RENEW_TOKENS = '/api-dashboard-service/dashboard/renewtokens';
export const APIDASHBOARDSERVICE_DELETE_APPLICATION = '/api-dashboard-service/dashboard/deleteapplication';
export const APIDASHBOARDSERVICE_GENERATE_REPORTS = '/api-dashboard-service/dashboard/generatereports';

export const APIDASHBOARDSERVICE_ANNOUNCEMENTS = '/api-dashboard-service/announcement/announcements';
