const styles = theme => ({
  textField: {
    width: '100%',
    transform: 'none !important',
    marginBottom: '20px'
  },
  menu: {
    width: 200
  },
  registerButton: {
    marginLeft: theme.spacing.unit,
    marginTop: '20px',
    display: 'flex'
  },
  root: {
    flexGrow: 1
  },
  colorPrimaryFirst: {
    backgroundColor: '#ED502E66'
  },
  barColorPrimaryFirst: {
    backgroundColor: '#ED502E'
  },
  colorPrimarySecond: {
    backgroundColor: '#D0142C66'
  },
  barColorPrimarySecond: {
    backgroundColor: '#D0142C'
  },
  paper: {
    height: 140,
    width: 100
  },
  title: {
    fontSize: '24px'
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  formControl: {
    width: '33.95%',
    marginLeft: '2.5%'
  },
  select: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    padding: '4%',
    backgroundColor: 'white !important',
    borderRadius: '28px !important',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    width: '100%',
    height: '57px'
  }
});

export default styles;
