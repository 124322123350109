import React from 'react';
import PropTypes from 'prop-types';
import {ListItem} from '@material-ui/core';

class MUListItem extends React.Component {

  render() {
    const { props } = this;

    return (
      <ListItem
        button={props.button}
        className = {props.className}
        dense = {props.dense}
        key = {props.key}
        onClick = {props.onClick}
        classes={props.classes}
        selected ={props.selected}
      >
        {props.children}
      </ListItem>
    );
  }
}

MUListItem.propTypes = {
  button: PropTypes.bool,
  className: PropTypes.string,
  dense: PropTypes.bool,
  key: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

MUListItem.defaultProps = {
  button: false,
  dense: false
};

export default MUListItem;
