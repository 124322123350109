import * as actionTypes from '../../constants/actionTypes';

export default function productServices(state = '', action) {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_SERVICES:
      return action.services;
    default:
      return state;
  }

}
