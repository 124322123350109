
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, CardHeader, Avatar, CardActions } from '@material-ui/core';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as Image from '../../../images';
import CardActionArea from '@material-ui/core/CardActionArea';

class MUCard extends React.Component {

  onTitleClick = (e, id) => {
    e.preventDefault();
    this.props.handleClick(e, id);
  };

  render() {
    const { classes, style, className } = this.props;

    return (
      <div style={style} className={className}>
        {this.props.basicComponent ?
          <Card className = {classes.card}>
            <CardContent className = {classes.card}>
              {this.props.basicComponentContent}
            </CardContent>
          </Card> :

          <Card key={this.props.key} className = {classes.card}>

            {this.props.header ?
              <CardHeader
                classes = {{content: classes.content,
                  action: classes.action }}
                avatar={ this.props.avatar ?
                  <Avatar src={`data:image/png;base64,${this.props.icon}`} className={classes.avatar} /> :
                  null
                }
                action={this.props.headerActionImage}
              /> : null }
            <CardActionArea className ={classes.cardActionArea} classes = {this.props.classes} onClick={this.props.onCardClick}>
              {this.props.media ?
                <CardMedia
                  className = {classes.media}
                  image={this.props.imageSource ? Image[this.props.imageUrl] : this.props.image}
                /> : null }

              <CardContent classes = {{root: classes.cardContent}}>
                {this.props.title ?
                  <Typography className={classes.h2} component="h2">
                    <Link className = {classes.link} to="#" onClick = {(e) => this.onTitleClick(e, this.props.id)}>{this.props.name}</Link>
                  </Typography> :
                  null}
                <Typography component="p" className={classes.text}>
                  {this.props.content}
                </Typography>
              </CardContent>

              <CardActions classes = {{root: classes.cardActions}}>
                {this.props.children}
              </CardActions>
            </CardActionArea>
          </Card>
        }
      </div>
    );
  }
}

MUCard.propTypes = {
  handleClick: PropTypes.func.isRequired,
  basicComponent: PropTypes.bool,
  basicComponentContent: PropTypes.element,
  media: PropTypes.bool,
  header: PropTypes.bool,
  avatar: PropTypes.bool,
  headerActionImage: PropTypes.element,
  title: PropTypes.bool,
  content: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  key: PropTypes.number,
  imageUrl: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

export default withStyles(styles)(MUCard);
