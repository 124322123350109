/* eslint no-use-before-define: 0 */

import {
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import React from 'react';
import { AUTHSERVER_TOKEN, AUTHSERVER_TOKEN_REVOKE } from '../../config/paths';
import { toFormBody } from '../../utils/fetchUtils';
import { setCookie, removeCookie, hasCookie } from '../../utils/cookieUtil';
import { LOGIN_USER, LOGOUT, TOKEN_REFRESH, TOKEN_REFRESH_SUCCESS, INITIALIZE } from '../../redux/constants';
import { loginUserSuccess, loginUserError, timeoutCreate, logout, logoutUser,
  setNotification, clearLogin, tokenRefresh } from '../../redux/actions';
import selectLogin from './selectors';
import { rawrequest } from '../../utils/request';
import { getOAuth2Token, refreshToken, isOAuth2TokenExpired } from '../../utils/oauth2';
import moment from 'moment';
import { history } from '../../App';
import { store } from '../../../src';
import {Link} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import _ from 'lodash';

/**
 * loginUserHandler  login submit handler function
 */
export function * loginUserHandler() {
  try {
    const {
      username,
      password,
      recaptchaResponse
    } = yield select(selectLogin());

    const options = {
      method: 'POST',
      body: toFormBody({
        scope: 'portal',
        grant_type: 'password', // eslint-disable-line camelcase
        username: username,
        password: password,
        recaptchaResponse: recaptchaResponse

      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic YXBpLXBvcnRhbDp0aGlzaXNzZWNyZXQ='
      }
    };
    let response = yield call(rawrequest, AUTHSERVER_TOKEN, options);

    const token = yield call((r) => { return r.json(); }, response);
    const expireDate = moment().add(token.expires_in, 'seconds');

    startTokenTimer();

    token.expireDate = expireDate;
    setCookie('PRT-XAUTH-ACTKN', token.access_token, { expires: token.expireDate.toDate() });
    yield put(loginUserSuccess(token));
    history.push('/myapplications');
  } catch (error) {
    if (_.isNil(error.response)) {
      console.log('ERROR_responsenotfound: ' + error.message);
      yield put(setNotification(error.message, 'error'));
      yield put(loginUserError());
      return;
    }

    const errorResponse = yield call((r) => { return r.json(); }, error.response);

    console.log(`
    ERROR: ${JSON.stringify(errorResponse)}
    status: ${error.response.status}
    `);

    switch (error.response.status) {
      case 401:
        if (errorResponse.error_description === 'USER DISABLED. PASSWORD RENEWAL IS REQUIRED') {
          yield put(setNotification(<Typography component="p">
          USER DISABLED. PLEASE RENEW PASSWORD BY CLICK  :
            <Link to="/reminder"> FORGOT PASSWORD</Link>
          </Typography>, 'error'));
        } else {
          yield put(setNotification(errorResponse.error_description, 'error'));
        }
        break;
      default:
        yield put(setNotification('System error occured, please try again later', 'error'));
        break;
    }

    // TODO: action will be triggered with error parameter
    yield put(loginUserError());
  }
}

export function * logoutHandler() {
  const { timeoutId, token } = yield select(selectLogin());

  console.log('token: ' + token);
  yield put(logoutUser());
  try {
    removeCookie();
    clearTimeout(timeoutId);

    if (!_.isNil(token.access_token)) {
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token.access_token
        },
        body: {}
      };

      yield call(rawrequest, AUTHSERVER_TOKEN_REVOKE, options);
    }

  } catch (error) {
    // keep calm end carry on
    console.log('ERROR: ' + error);
  }

  store.dispatch(setNotification('Logout successfully', 'success'));
  history.push('/');
}

export function tokenRefreshHandler() {
  const token = getOAuth2Token();

  // 3 mins pre expire gap
  if (token && isOAuth2TokenExpired(token, 180)) {
    try {
      refreshToken(token);
    } catch (error) {
      let responseError = {};

      if (_.isNil(error.response)) {
        // timeout-offline cases, do nothing
        responseError.errorDescription = !_.isNil(error.message) ? error.message : 'unknown exception';
      } else {
        const resp = error.response.resp.data && JSON.parse(error.response.resp.data);

        console.log('resp: ' + resp);
        const message = (resp && resp.header && resp.header.message);

        responseError.errorDescription = message || responseError.errorDescription;
      }

      console.log(`
      ERROR: ${JSON.stringify(responseError)}
      `);
    }
  }
}

export function * tokenRefreshSuccessHandler() {
  const token = getOAuth2Token();
  let { timeoutId } = yield select(selectLogin());

  startTokenTimer(timeoutId, token.expires_in);

  if (!token) return;
  const expireDate = moment().add(token.expires_in, 'seconds');

  token.expireDate = expireDate;
  setCookie('PRT-XAUTH-ACTKN', token.access_token, { expires: token.expireDate.toDate()});
}

export function siteInitialization() {
  history.listen((location, action) => {
    store.dispatch(tokenRefresh());
  });

  if (!hasCookie()) {
    store.dispatch(clearLogin());
  }

  const token = getOAuth2Token();

  if (token && token.expires_in) {
    startTokenTimer(null, token.expires_in - 10);
  }
}

function startTokenTimer(timeoutId, tokenLifetime) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  const newTimeoutId = setTimeout(() => {
    store.dispatch(logout());
  }, (tokenLifetime || (8 * 60)) * 1000);

  store.dispatch(timeoutCreate(newTimeoutId));
}

export default function * defaultSaga() {
  yield takeLatest(LOGIN_USER, loginUserHandler);
  yield takeLatest(LOGOUT, logoutHandler);
  yield takeLatest(TOKEN_REFRESH, tokenRefreshHandler);
  yield takeLatest(TOKEN_REFRESH_SUCCESS, tokenRefreshSuccessHandler);
  yield takeLatest(INITIALIZE, siteInitialization);
}
