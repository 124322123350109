const styles = theme => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#F4F5F6',
    webkitBackgroundSize: 'cover',
    mozBackgroundSize: 'cover',
    oBackgroundSize: 'cover',
    backgroundSize: 'cover'
  },
  typography: {
    height: '175px',
    width: '35%',
    fontFamily: 'Gilroy',
    fontSize: '72px',
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-2px',
    color: '#ffffff',
    paddingTop: '20%',
    marginLeft: '8%'
  },
  title: {
    marginTop: '90px',
    fontSize: '44px',
    fontWeight: 400,
    fontFamily: 'Gilroy',
    // width:300,
    height:100,
    margin:'auto',
    letterSpacing:'-2.239px',

  },
  description: {
    marginTop: '-2%',
    width: '100%',
    height: 'auto',
    alignSelf: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: '16.2px',
    fontWeight: '400',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.8',
    letterSpacing: '-0.456px',
    textAlign: 'center',
    color: '#4c5068'
  },
  intro: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    backgroundColor:'#F4F5F6'
  },
  body: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0% 10%',
    backgroundColor:'F4F5F6'
  }

});

export default styles;

