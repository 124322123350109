import React from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { hasCookie } from '../../utils/cookieUtil';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const auth = () => {
    // has already logged in
    if (hasCookie()) {
      return true;
    }

    return null;
  };

  return (
    <Route {...rest} render={(props) => (
      auth() === true ?
        <Component {...props} /> :
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )} />
  );
};

export default AuthenticatedRoute;
