import React from 'react';

class Copyrights extends React.Component {
  render() {
    return (

      <div id="copyrights" style={{backgroundColor: '#F4F5F6'}}>

        <div className="container clearfix">

          <div className="row">

            <div className="col-lg-3 col-6 bottommargin-sm widget_links">
              <a href="https://www.albaraka.com.tr/tr" target="_blank"><img src= {require('../../images/albaraka_logo.png')} ></img></a>
            </div>
            <div className="col-lg-3 col-6 bottommargin-sm widget_links">
              <a href="/home" ><img src= {require('../../images/albarakaApi.png')}></img></a>
            </div>
            <div className="col-lg-3 col-6 bottommargin-sm widget_links">
              <a href="https://www.albarakagaraj.com/" target="_blank"><img src= {require('../../images/albarakagaraj_logo.png')}></img></a>
            </div>

            <div className="col-lg-3 col-6 bottommargin-sm widget_links">
              <a href="https://albarakatech.com/tr.html" target="_blank"><img src= {require('../../images/atg.png')}></img></a>
            </div>

          </div>

        </div>

      </div>
    );
  }
}

export default Copyrights;
