import React from 'react';
import _ from 'lodash';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MUValidatorForm } from 'basic-components';
import Button from '../ui/Button';
import MUTypography from '../ui/MUTypography';
import { bindActionCreators } from 'redux';
import {getProducts as getProductsSelect} from '../../redux/selectors';
import * as apiDashboardService from '../../services/apidashboardservice';
import * as Actions from '../../redux/actions';
import MUNativeSelect from '../ui/MUNativeSelect';
import MUChart from '../ui/Chart';
import classNames from 'classnames';
import * as d3 from 'd3';

const dateRanges = [
  { id: 0, value: 'Last 24 Hours' },
  { id: 1, value: 'Last 7 Days' },
  { id: 2, value: 'Last 1 Month' },
  { id: 3, value: 'Last 3 Months' },
  { id: 4, value: 'Last 1 Year' }
];

const now = new Date();

class Analytics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      application: '',
      applications: [],
      product: '',
      products: [],
      productService: '',
      productServices: [],
      chartData: [],
      ticks: [],
      tickCount: '',
      interval: '',
      angle: undefined,
      textAnchor: undefined
    };
  }

  componentWillMount() {
    this.getApplications();
  }

  getApplications = () => {
    apiDashboardService.getApplications()
      .then((responseJson) => {
        console.log('applications => ' + JSON.stringify(responseJson.data || null));

        this.setState({
          applications: responseJson.data || []
        });
      })
      .catch((error) => {
        console.log('ERROR: ' + error);
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            this.props.actions.setNotification(error.message, 'error');
          } else {
            this.props.actions.setNotification('Applications couldnt get, please try again later', 'error');
          }
          // console.log('ERROR: ' + error);
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_applications: ' + JSON.stringify(r));
            this.props.actions.setNotification(r.error_description, 'error');
          });
      });
  }

  handleChange = (e, name) => {
    let value = e.target.value;

    console.log('State ' + name + ' : ' + value);
    this.setProducts(value);
    this.setState({ [name]: value });

  };

  setProducts = (applicationId) => {
    var array = [];

    this.state.applications.forEach(value => {
      if (value.id == applicationId) {
        console.log('produclist : ' + value.productList);
        array = Array.from(value.productList);
        this.setState({products: array});
        this.setProductServices(array);
      } else {
        console.log('no match');
      }
    });
  }

  setProductServices = (products) => {

    var array = [];

    this.state.products.forEach(value => {
      apiDashboardService.getProductServicesById(value)
        .then((responseJson) => {
          console.log('productservices => ' + JSON.stringify(responseJson.data || null));
          array = array.concat(responseJson.data);
          this.setState({
            productServices: array
          });
        })
        .catch((error) => {
          console.log('ERROR: ' + error);
          if (_.isNil(error.response)) {
            if (!_.isNil(error.message)) {
              this.props.actions.setNotification(error.message, 'error');
            } else {
              this.props.actions.setNotification('ProductServices couldnt get, please try again later', 'error');
            }
            // console.log('ERROR: ' + error);
            return;
          }

          error.response.json()
            .then(r => {
              console.log('ERROR_applications: ' + JSON.stringify(r));
              this.props.actions.setNotification(r.error_description, 'error');
            });
        });
    });
  }

  handleSubmit = () => {
    console.log('reports generated');
    let { productService, dateRange, application } = this.state;

    // applicationId,endpoint,requestDate,dateRange
    this.generateReports(application, productService, dateRange, new Date().getTime());
  }

  generateReports = (applicationId, serviceEndpoint, dateRangeId, requestDate) => {
    apiDashboardService.generateReports(applicationId, serviceEndpoint, dateRangeId, requestDate)
      .then((responseJson) => {
        console.log('productservices => ' + JSON.stringify(responseJson.data || null));
        console.log(responseJson.data);
        this.setState({ chartData: responseJson.data}) ;
      })
      .catch((error) => {
        console.log('ERROR: ' + error);
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            this.props.actions.setNotification(error.message, 'error');
          } else {
            this.props.actions.setNotification('ProductServices couldnt get, please try again later', 'error');
          }
          // console.log('ERROR: ' + error);
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_applications: ' + JSON.stringify(r));
            this.props.actions.setNotification(r.error_description, 'error');
          });
      });
  }

  setTickFormatter = (e, name) => {
    let value = e.target.value;
    let domainToday = undefined ,timeFormatter = undefined,
    ticks = undefined, interval = '', tickCount = undefined, angle= undefined,
    textAnchor= undefined;

    // Last 24 Hours
    if (_.isEqual(value, '0')) {

     domainToday = d3.scaleTime().domain([new Date(now - 86400000), now]); // last 24 hours
     ticks = domainToday.ticks(d3.timeHour.every(1)); // group by hours

    timeFormatter = (tick) => { return d3.timeFormat('%H:%M')(new Date(tick)); };
   
      interval = 'preserveStartEnd';
      tickCount = 24;
      console.log("ticks : "+ticks);
    } 
    // Last 7 Days
    else if (_.isEqual(value, '1')) {

      domainToday = d3.scaleTime().domain([new Date(now - 604800000), now]); // last 7 days
      ticks = domainToday.ticks(d3.timeDay.every(1)); // group by days

      timeFormatter = (tick) => {return d3.timeFormat('%a %d')(new Date(tick));};
  
      interval = 0;
      tickCount = 24;
      console.log("ticks : "+ticks);
    } 
    // Last Month
    else if (_.isEqual(value, '2')) {

      domainToday = d3.scaleTime().domain([now.setMonth(now.getMonth()-1), now]); // last month
      ticks = domainToday.ticks(d3.timeDay.every(1)); // group by days

      timeFormatter = (tick) => {return d3.timeFormat('%a %d %b')(new Date(tick));};

      interval = 0;
      tickCount = 30;
      angle = -45;
      textAnchor = 'end';
      console.log("ticks : "+ticks);
    } 
    // Last 3 Months
    else if (_.isEqual(value, '3')) {

      domainToday = d3.scaleTime().domain([now.setMonth(now.getMonth()-3), now]); // last 3 months
      ticks = domainToday.ticks(d3.timeWeek.every(1)); // group by weeks

      timeFormatter = (tick) => {return d3.timeFormat('%a %d %b %y')(new Date(tick));};

      interval = 0;
      tickCount = 20;
      angle = 0;
      textAnchor = 'middle';
      console.log("ticks : "+ticks);
    }
    // Last Year
    else if (_.isEqual(value, '4')) {

      domainToday = d3.scaleTime().domain([now.setFullYear(now.getFullYear()-1), now]); // last year
      ticks = domainToday.ticks(d3.timeMonth.every(1)); // group by months

      timeFormatter = (tick) => {return d3.timeFormat('%b %y')(new Date(tick));};

      interval = 0;
      tickCount = 20;
      angle = -45;
      textAnchor = 'end';
      console.log("ticks : "+ticks);
    }

    this.setState({
      [name]: value,
      domainToday: domainToday,
      timeFormatter: timeFormatter,
      ticks: ticks,
      interval : interval,
      tickCount: tickCount,
      angle: angle,
      textAnchor: textAnchor
    });
  };


  render() {
    const { application, applications, productService, productServices, dateRange, chartData,ticks,interval,tickCount,domainToday,timeFormatter,angle,textAnchor} = this.state;
    const {classes} = this.props;

    return (

      <div>
        <div class= 'row' style={{ display: 'flex', justifyContent: 'left'}}>
          <MUValidatorForm
            onSubmit={this.handleSubmit}
          >
            <MUTypography variant='h4'>
              <h6> Applications </h6>
            </MUTypography>

            <MUNativeSelect
              required={true}
              value={application}
              onChange={(e) => this.handleChange(e, 'application')}
              name="application"
              className={classes.textField}
              options={
                [<option key={0} value="" label=""></option>,
                  applications.map((e, key) => {
                    return <option key={key} value={e.id}>{e.name}</option>;
                  })]
              }
            />
            <MUTypography variant='h4'>
              <h6> APIs </h6>
            </MUTypography>
            <MUNativeSelect
              required={true}
              value={productService}
              onChange={(e) => this.handleChange(e, 'productService')}
              name="productService"
              className={classes.textField}
              options={
                [<option key={0} value="" label=""></option>,
                  productServices.map((e, key) => {
                    return <option key={key} value={e.endpointAddress}>{e.name}</option>;
                  })]
              }
            />

            <MUTypography variant='h4'>
              <h6> Choose Date Range </h6>
            </MUTypography>
            <MUNativeSelect
              required={true}
              value={dateRange}
              onChange={(e) => this.setTickFormatter(e, 'dateRange')}
              name="dateRange"
              className={classes.textField}
              options={
                [<option key={0} value="" label=""></option>,
                  dateRanges.map((e, key) => {
                    return <option key={key} value={e.id}>{e.value}</option>;
                  })]
              }
            />
            <br/>
            <Button
              variant="outlined"
              color='primary'
              className={classNames('outlinedPrimary', classes.button)}
              type="submit"
              text="Generate Reports"
            />
          </MUValidatorForm>
        </div>

        <div class= 'row' style={{ display: 'flex', justifyContent: 'left'}}>
          <MUChart
            data = {chartData}
            dataKeyXAxis = 'timeInMs'
            domainXAxis={ domainToday}
            nameXAxis= 'Time'
            typeXAxis= 'number'
            dataKeyYAxis='calls'
            nameYAxis= 'Calls'
            tickFormatter = {timeFormatter}
            ticks = {ticks}
            interval = {interval}
            tickCount = {tickCount}
           angle = {angle}
           textAnchor = {textAnchor}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  products: state.products,
  selectProducts: getProductsSelect(state)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles))(Analytics);

