import React from 'react';
import PropTypes from 'prop-types';

import SwaggerUI from './Swagger';
import InfoContent from './Info';

import * as types from '../../../redux/constants';

const Content = (props) => {
  const { classes, products} = props;
  const {contentType, infoContent, swaggerContent, productList} = products;

  return (
    <main className={classes.content}>
      { contentType === types.DocsMainContentType.INFO ?
        <InfoContent classes={classes} infoContent={infoContent} productList={productList}/> :
        <SwaggerUI classes={classes} url={swaggerContent}/>}
    </main>
  );
};

Content.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired
};

export default Content;
