const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '75%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    marginTop: '5%'
  },
  sideBar: {
    marginBottom: '10%'
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: '#F9FAF9',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    padding: theme.spacing.unit * 3,
    marginBottom: '10%'
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

export default styles;
