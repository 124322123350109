import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import createExpirationTransform from 'redux-persist-transform-expire';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import rootReducer from '../redux/reducers';

/** ************   CustomizedExpireTransform   ***************/
const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt'
});

/** ****************  StoreConfiguration   ************/
export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const persistConfig = {
    transforms: [immutableTransform(), expireTransform],
    key: 'root',
    storage,
    whitelist: ['login']
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(sagaMiddleware)
  ));
  // const store = createStore(
  //   persistedReducer,
  //   applyMiddleware(sagaMiddleware),
  // );

  store.store = store;
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}

export function configurePersistStore(store) {
  return persistStore(store);
}
