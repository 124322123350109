const styles = theme => ({
  listItemText: {
    color: '#4c5068',
    fontSize: '14px',
    alignSelf: 'center',
    fontStyle: 'normal',
    fontFamily: 'Gilroy-Light',
    fontWeight: '500',
    lineHeight: '1.78',
    fontStretch: 'normal',
    letterSpacing: '-0.5px'

  },
  secondaryText: {
    textAlign: 'right',
    fontStyle: 'italic'
  }
});

export default styles;

