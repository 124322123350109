import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as apiDashBoardService from '../../../services/apidashboardservice';
import { MoreVert, Edit, Delete, FileCopy, Add } from '@material-ui/icons/';
import MUCard from '../../ui/MUCard';
import MUIconButton from '../../ui/MUIconButton';
import MUCircularProgress from '../../ui/MUCircularProgress';
import MUGrid from '../../ui/MUGrid';
import { history } from '../../../App';
import MUDialog from '../../../components/MUDialog/';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Fade from '@material-ui/core/Fade';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _ from 'lodash';
import { Typography } from '@material-ui/core';

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      appId: '',
      onServiceCall: true,
      readOnly: false,
      copied: false,
      isDialogOpen: false,
      idToDelete: '',
      anchorEl: null,
      selectedId: '',
      isApplicationsEmpty: 'false'
    };
  }
  componentWillMount() {
    this.getApplications();
  }

  handleEditClick = (e, id) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/myapplications',
      state: { appId: id, applications: this.state.applications}
    });
    this.props.onCardClick(id, this.state.applications);
    this.handleClose();
  }

  onDeleteClick = (e, id) => {
    e.preventDefault();
    this.setState({isDialogOpen: true, idToDelete: id});
    this.handleClose();
  };

  onSettingsClick = (e, id) => {
    this.setState({selectedId: id});
  }

  handleCloseDialog() {
    this.setState({ isDialogOpen: false });
  }

  handleApprove = (e) => {
    this.state.applications.forEach(value => {
      if (value.id === this.state.idToDelete) {
        apiDashBoardService.deleteApplication(this.state.idToDelete)
          .then((responseJson) => {
            console.log(responseJson);
            this.handleCloseDialog();
            this.props.actions.setNotification('Your application has been deleted', 'success');
            this.getApplications();
          }).catch((exception) => {
            // TODO: exception case must be handle
            const errorMessage = '' + exception;

            this.props.actions.setNotification(errorMessage, 'error');
            console.log(exception);
          });
      }
    });

  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAddClick = (e) => {
    this.props.onAddClick(e, 'newapplication');
  }

  getApplications = () => {
    apiDashBoardService.getApplications()
      .then((responseJson) => {
        console.log('applications => ' + JSON.stringify(responseJson.data || null));
        const isApplicationsEmpty = _.isEmpty(responseJson.data);

        this.setState({
          applications: responseJson.data || [],
          onServiceCall: false,
          isApplicationsEmpty: isApplicationsEmpty
        });
      })
      .catch((error) => {
        console.log('ERROR: ' + error);
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            this.props.actions.setNotification(error.message, 'error');
          } else {
            this.props.actions.setNotification('Applications couldnt get, please try again later', 'error');
          }
          // console.log('ERROR: ' + error);
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_applications: ' + JSON.stringify(r));
            this.props.actions.setNotification(r.error_description, 'error');
          });
      });
  }

  redirectToNewApplication= () => {
    history.push('/newapplication');
  }

  render() {
    const { classes } = this.props;
    const { applications, onServiceCall, selectedId, isApplicationsEmpty } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (

      <div>
        {this.props.open ?
          <div>
            {
              onServiceCall ?
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                  <MUCircularProgress className={classes.progress} size={50}></MUCircularProgress>
                </div> :
                <div>
                  <MUGrid
                    key={'rootGrid'}
                    container={true}
                    spacing={24}>
                    {isApplicationsEmpty ?
                      <MUGrid
                        item={true}
                        xs={4}>
                        <MUCard
                          basicComponent={true}
                          basicComponentContent = {
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '316px'}}>
                              <MUIconButton
                                variant="fab"
                                aria-label="Add"
                                color = 'secondary'
                                className={classes.buttonNewApp}
                              >
                                <Add onClick={(e) => this.handleAddClick(e)}/>
                              </MUIconButton>
                              <br/>
                              <Typography variant='h5' classes ={{h5: classes.h5}}> New Application </Typography>
                            </div>
                          }
                        >
                        </MUCard>
                      </MUGrid> :
                      null }
                    {applications.map((item, key) =>
                      <MUGrid
                        key={key}
                        item={true}
                        xs={6}>
                        <MUCard
                          key={key}
                          avatar={true}
                          icon= {item.icon}
                          id={item.id}
                          onCardClick={(e) => this.handleEditClick(e, item.id)}
                          name={item.name}
                          content = {item.description}
                          handleClick={this.handleEditClick}
                          header = {true}
                          classes ={{cardActionArea: classes.cardActionArea}}
                          headerActionImage = {
                            <div>
                              <MUIconButton
                                variant="fab"
                                aria-label="Add"
                                ariaOwns={open ? 'fade-menu' : undefined}
                                ariaHasPopup="true"
                                className={classes.button}
                                onClick={this.handleClick}
                              >
                                <MoreVert onClick = {(e) => this.onSettingsClick(e, item.id)} />
                              </MUIconButton>

                              <MUDialog open = {this.state.isDialogOpen} text = 'Are you sure to delete this application?'
                                onClose = {(e) => this.handleCloseDialog(e)} handleApprove = {(e) => this.handleApprove(e)}/>
                            </div>
                          }
                          title={true}
                        >
                        </MUCard>

                      </MUGrid>

                    )}
                  </MUGrid>

                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}

                  >
                    {applications.map((item, key) =>

                      item.id === selectedId ?
                        <div>
                          <CopyToClipboard text={item.oauthClientId}
                          >
                            <MenuItem onClick={this.handleClose} className={classes.menuItem}>
                              <ListItemIcon >
                                <FileCopy />
                              </ListItemIcon>
                              Copy ID
                            </MenuItem>
                          </CopyToClipboard>

                          <CopyToClipboard text={item.oauthClientSecret}>
                            <MenuItem onClick={this.handleClose} className={classes.menuItem}>
                              <ListItemIcon >
                                <FileCopy />
                              </ListItemIcon>
                              Copy Secret
                            </MenuItem>
                          </CopyToClipboard>
                          <MenuItem onClick={(e) => this.handleEditClick(e, item.id)} className={classes.menuItem}>
                            <ListItemIcon >
                              <Edit />
                            </ListItemIcon>
                         Edit
                          </MenuItem>
                          <MenuItem onClick={(e) => this.onDeleteClick(e, item.id)} className={classes.menuItem}>
                            <ListItemIcon >
                              <Delete />
                            </ListItemIcon>
                       Delete
                          </MenuItem>
                        </div> : null
                    )}
                  </Menu>
                </div>
            }
          </div> :
          null }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Applications);
