const styles = theme => ({
  title: {
    backgroundColor: '#F9F9F9',
    height: '100px'
  },
  h2: {
    margin: '1px 1px 60px',
    fontSize: '18px',
    paddingTop: '5px',
    paddingLeft: '2px'
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  card: {
    width: 270,
    height: 300
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  actions: {
    display: 'flex'
  },
  avatar: {
    backgroundColor: '#ED502E'
  },
  button: {
    backgroundColor: '#fff',
    color: theme.palette.common.black,
    align: 'center'
  },
  menuItem: {
    height: 3
  },
  cardActionArea: {
    display: 'contents'
  },
  h5: {
    margin: 'auto'
  },
  buttonNewApp: {
    backgroundColor: '#ED502E',
    color: theme.palette.common.white,
    align: 'center',
    borderColor: '#ED502E',
    border: 'solid 1px',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#F9F9F9',
      border: 'solid 1px',
      borderColor: '#ED502E',
      color: '#ED502E'
    }
  }

});

export default styles;
