import * as actionTypes from '../../constants/actionTypes';

export default function allProducts(state = '', action) {
  switch (action.type) {
    case actionTypes.ADD_PRODUCTS:
      return action.products;
    default:
      return state;
  }

}
