import React from 'react';
// import {MUTypography} from 'basic-components';
import MUTypography from '../../../../../ui/MUTypography';
import _ from 'lodash';

const ApiInfo = (props) =>
  <div>
    <MUTypography variant='title' paragraph={true} className = {props.classes.h5}>
      {
        (_.find(props.productList,
          (product) => (product.id === props.infoContent.content))
          .name)

      }
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className = {props.classes.paragraph}>
      {
        (_.find(props.productList,
          (product) => (product.id === props.infoContent.content))
          .description)

      }
    </MUTypography>
  </div>

  ;

export default ApiInfo;
