import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import MUCard from '../ui/MUCard';
import Button from '../ui/Button';
import { Link } from 'react-router-dom';
import * as Actions from '../../redux/actions';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { getProducts } from '../../redux/selectors';
import {docsInfoDynamicContentScreen} from '../../redux/constants/docsInfoContentTypes';
import { history } from '../../App';

class FeaturedAPIs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      products: props.productsFromStore
    };
  }

  handleClick = (e, id) => {
    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(id, docsInfoDynamicContentScreen, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true}
    });
  };

  render() {
    const { classes } = this.props;
    const productList = this.props.productsFromStore;

    return (
      <div>
        <div className = {classes.apiCards}>
          {productList.slice(0, 3).map((item) =>
            <MUCard
              key={item.id}
              imageSource={true}
              imageUrl = {item.imageUrl}
              id={item.id}
              name={item.name}
              content = {item.summary}
              media = {true}
              title ={true}
              children = {
                <Button
                  text = 'Find Out More'
                  classes={{ root: classes.btnCardAction, text: classes.txtFindMore, label: classes.label }}
                  className ={classes.btnCardAction}
                  onClick={(e) => this.handleClick(e, item.id)}
                />}
              classes= {classes}
            />
          )
          }

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  productsFromStore: getProducts(state)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ), withStyles(styles))(FeaturedAPIs);

