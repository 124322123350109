import Cookies from 'universal-cookie';

export function setCookie(name, value, options) {
  const cookies = new Cookies();

  options = options || {};
  options.path = '/';

  cookies.set(name, value, options);
  // TODO: domain, secure, httpOnly set edilecek
}

export function hasCookie(name) {
  const cookies = new Cookies();

  return cookies.get(name || 'PRT-XAUTH-ACTKN') !== undefined;
}

export function removeCookie(name) {
  const cookies = new Cookies();

  cookies.remove((name || 'PRT-XAUTH-ACTKN'), { path: '/' });
}

