const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 230,
    fontSize: '12px'
  },
  button: {
    marginTop: '15px'
  }
});

export default styles;
