import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import { NativeSelect } from '@material-ui/core/';

const MUNativeSelect = (props) => {
  return (
    <NativeSelect
      required={props.required}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      className={props.className}
    >
      {props.options}
    </NativeSelect>
  );
};

MUNativeSelect.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.array
};

export default withStyles(styles)(MUNativeSelect);
