import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import * as apiUserService from '../../services/confirm';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MUTextValidator from '../ui/MUTextValidator';
import _ from 'lodash';
import { setNotification } from '../../redux/actions';
import { store } from '../../../src';
import Button from '../ui/Button';
import classNames from 'classnames';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPasswordChange: false,
      password: '',
      confirmpassword: '',
      passwordIsMasked: true,
      confirmPasswordIsMasked: true,
      title: 'Reset Your Password',
      description: 'To change your password successfully, you need to enter your new password in the form below.'
    };
  }

  componentDidMount() {
    // custom validation rule
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('minLength', (value) => {
      if (value !== '' && value.length < 8) {
        return false;
      }
      return true;
    });
  }

  handleChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  handleSubmit = () => {
    console.log('submitted');
    this.changePassword();
  }

  changePassword = () => {
    apiUserService.changePasswordByKey(this.state.password, this.props.match.params.key)
      .then((responseJson) => {
        this.setState({ isPasswordChange: true,
          title: '',
          description: '' });
        console.log('Service Response: ' + responseJson);
      })
      .catch((exception) => {
        this.setState({
          password: '',
          confirmpassword: ''
        });
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      });
  }

  togglePasswordMask = (passwordType, e) => {
    e.preventDefault();

    if (passwordType === 'password') {
      this.setState(prevState => ({
        passwordIsMasked: !prevState.passwordIsMasked
      }));
    } else if (passwordType === 'confirmpassword') {
      this.setState(prevState => ({
        confirmPasswordIsMasked: !prevState.confirmPasswordIsMasked
      }));
    }
  };

  render() {

    const { classes } = this.props;
    const { password, confirmpassword, isPasswordChange, passwordIsMasked, confirmPasswordIsMasked, title, description} = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title= {title}
          description = {description}
          children = {
            isPasswordChange ?
              <div style = {{ marginTop: '-15%'}}>
                <div className="col_full" align="center">
                  <img src={require('../../images/success.png')} />
                  <h3 className={classes.message}>Your password has been updated. Click below to login.</h3>
                </div>

                <div className = {classes.btnSubmit}>
                  <Button
                    variant = 'outlined'
                    color = 'primary'
                    component={Link}
                    to="/login"
                    text="LOG IN"
                    className= {classNames('outlinedPrimary')}></Button>
                </div>
              </div> :
              <ValidatorForm
                onSubmit={this.handleSubmit}
                className="nobottommargin"
              >
                <div className = {classes.textField}>
                  < MUTextValidator
                    id="password"
                    onChange={(e) => this.handleChange('password', e)}
                    name="password"
                    placeholder = "PASSWORD"
                    value={password}
                    type={passwordIsMasked ? 'password' : 'text'}
                    validators={['required', 'minLength', 'matchRegexp:^(?=.*[0-9])', 'matchRegexp:^(?=.*[a-zA-Z])']}
                    errorMessages={['Required',
                      'At least 8 characters must be entered.',
                      'Must contain at least 1 digit.',
                      'Must contain at least 1 letter.']}
                    autoFocus={true}
                  />
                </div>

                <div className = {classes.textField}>
                  < MUTextValidator
                    id="confirmpassword"
                    onChange={(e) => this.handleChange('confirmpassword', e)}
                    name="confirmpassword"
                    placeholder='RE-ENTER NEW PASSWORD'
                    value={confirmpassword}
                    type={confirmPasswordIsMasked ? 'password' : 'text'}
                    validators={['required', 'isPasswordMatch']}
                    errorMessages={['Required', 'Password does not match']}
                  />
                </div>
                <div className = {classes.btnSubmit}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    text="Change"
                    className= {classNames('outlinedPrimary')}></Button>
                </div>

              </ValidatorForm>
          }
        />

      </div>
    );
  }
}

export default withStyles(styles)(ChangePassword);
