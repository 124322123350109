import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import styles from './style';
import './style.css';
import * as Actions from '../../redux/actions';
import { getProducts } from '../../redux/selectors';
import * as apiDashBoardService from '../../services/apidashboardservice';
import MUCard from '../ui/MUCard';
import MUCircularProgress from '../ui/MUCircularProgress';
import { history } from '../../App';
import { docsInfoDynamicContentScreen } from '../../redux/constants/docsInfoContentTypes';
import Button from '../ui/Button';
import BasicPageComponent from '../BasicPageComponent';
import Header from '../Header';
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.productsFromStore,
      onServiceCall: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    this.getProducts();
  }

  getProducts = () => {
    if (_.isEmpty(this.state.products)) {
      this.setState({ onServiceCall: true });

      apiDashBoardService.getProducts()
        .then((response) => {
          const productList = response.data.filter(product => product.type === null ||  product.type === "API");
          this.props.actions.addProducts(productList);
          this.setState({ products: productList, onServiceCall: false });
        })
        .catch((exception) => {
          this.props.actions.setNotification("Products couldn't get, please try again later", 'error');
          console.log('getproducts exception: ' + JSON.stringify(exception));
        });
    }
  }

  handleClick = (e, id) => {

    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(id, docsInfoDynamicContentScreen, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true }
    });
  };

  render() {
    const { products, onServiceCall } = this.state;
    const { classes } = this.props;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        {
          onServiceCall ?
            <BasicPageComponent
              children={<MUCircularProgress className={this.props.classes.progress} size={50} />} /> :
            <BasicPageComponent
              title="Discover API's"
              description='ALBARAKA APIs are waiting to be discovered in order to build products and services that will be shaped by your imagination.'
              children={<div className={classes.apiCards}>
                {products.map((item) =>
                  <MUCard
                    key={item.id}
                    imageSource={true}
                    imageUrl={item.imageUrl}
                    id={item.id}
                    name={item.name}
                    content={item.summary}
                    media={true}
                    title={true}
                    children={
                      <Button
                        text='Find Out More'
                        classes={{ root: classes.btnCardAction, text: classes.txtFindMore, label: classes.label }}
                        className={classes.btnCardAction}
                        onClick={(e) => this.handleClick(e, item.id)}
                      />}
                    classes={classes}
                  />
                )
                }

              </div>}
            />
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  productsFromStore: getProducts(state)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Products);

