import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import { MURecaptcha } from 'basic-components';
import Button from '../ui/Button';
import MUTextValidator from '../ui/MUTextValidator';
import { hasCookie } from '../../utils/cookieUtil';
import { history } from '../../App';
import LinearProgress from '@material-ui/core/LinearProgress';
import {RECAPTCHA_SITEKEY} from '../../config/environment.js';
import classNames from 'classnames';
import BasicPageComponent from '../BasicPageComponent';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MUTypography from '../ui/MUTypography';
import { Link } from 'react-router-dom';
import Header from '../Header';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      recaptchaResponse: '',
      loggedIn: false,
      passwordIsMasked: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if (hasCookie()) {
      this.setState({ loggedIn: true});
    }
  }

  redirectToReminderPage() {
    history.push('/reminder');
  }

  redirectToReActivationPage() {
    history.push('/reactivation');
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    window.grecaptcha.reset();
    event.preventDefault();
    this.props.actions.loginUser(this.state.username, this.state.password, this.state.recaptchaResponse);

  }

  verifyCallback = (response) => {
    this.setState({recaptchaResponse: response});
  };

  togglePasswordMask = () => {
    console.log('login togglepasword');
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));

  };

  render() {

    const { classes, login } = this.props;
    const {loggedIn, username, password, passwordIsMasked} = this.state;

    if (loggedIn === true) {
      return <Redirect to='/' />;
    }
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title='Login'
          description='Use your Albaraka API details to log in to your account.'
          children = {
            <ValidatorForm
              onSubmit={this.handleSubmit}
              className= {classes.form}
            >
              {login.progressState ?
                <div className={classes.root}>
                  <LinearProgress classes={{ colorPrimary: classes.colorPrimaryFirst, barColorPrimary: classes.barColorPrimaryFirst }} />
                  <br />
                  <LinearProgress
                    classes={{ colorPrimary: classes.colorPrimarySecond, barColorPrimary: classes.barColorPrimarySecond }}
                  />
                </div> :
                null }
              <div className = {classes.textField}>
                < MUTextValidator
                  id="username"
                  onChange={this.handleInputChange}
                  name="username"
                  value={username}
                  placeholder = 'YOUR MAIL'
                  validators={['required', 'isEmail']}
                  errorMessages={['Required', 'Please enter a valid email address.']}
                  autoFocus = {true}
                />
              </div>

              <div className = {classes.textField}>
                < MUTextValidator
                  id="password"
                  onChange={this.handleInputChange}
                  name="password"
                  value={password}
                  placeholder='YOUR PASSWORD'
                  type={passwordIsMasked ? 'password' : 'text'}
                  validators={['required', 'minStringLength:8', 'matchRegexp:^(?=.*[0-9])', 'matchRegexp:^(?=.*[a-zA-Z])']}
                  errorMessages={['Required',
                    'At least 8 characters must be entered.',
                    'Must contain at least 1 digit.',
                    'Must contain at least 1 letter.']}
                />
              </div>

              <div className = {classNames(classes.center)}>
                <MURecaptcha
                  sitekey = {RECAPTCHA_SITEKEY}
                  verifyCallback={this.verifyCallback}
                />
              </div>
              <div className={classNames(classes.center, classes.btnSubmit)}>
                < Button
                  variant="outlined"
                  color="primary"
                  className='outlinedPrimary'
                  type="submit"
                  text="LOGIN"
                  disabled= {login.progressState}
                />
              </div>
              <div className = {classes.textField}>
                <div className= {classes.typography}>
                  <MUTypography >
                    <Link to='/reminder' className={classes.link}>
                FORGOT PASSWORD?
                    </Link>
                  </MUTypography>
                </div>
              </div>
              <div className = {classes.textField}>
                <div className= {classes.typography}>
                  <MUTypography >
                    <Link to='/reactivation' className={classes.link}>
                RESEND ACTIVATION MAIL
                    </Link>
                  </MUTypography>
                </div>
              </div>
            </ValidatorForm> }
        />
      </div>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  login: state.login.toJS(),
  error: state.error
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
