const styles = theme => ({
  form: {
    paddingBottom: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px'

  },
  registerButton: {
    marginTop: '15px',
    margin: theme.spacing.unit,
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  root: {
    flexGrow: 1
  },
  colorPrimaryFirst: {
    backgroundColor: '#ED502E66'
  },
  barColorPrimaryFirst: {
    backgroundColor: '#ED502E'
  },
  colorPrimarySecond: {
    backgroundColor: '#D0142C66'
  },
  barColorPrimarySecond: {
    backgroundColor: '#D0142C'
  },
  btnForgotPassword: {
    paddingRight: '55px'
  },
  icon: {
    marginRight: '5px'
  },
  btnLogin: {
    height: '40px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  btnSubmit: {
    width: '100%',
    marginTop: '12px'
  },
  link: {
    width: '165px',
    height: '12px',
    fontfamily: 'Gilroy',
    fontSize: '12px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    textAlign: 'right',
    color: '#e61837'
  },
  typography: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default styles;
