import _ from 'lodash';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  if (isFunction(response.json)) {
	  return response.json();
  }

  return response;
}

function isFunction(functionToCheck) {
	 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText ? response.statusText : 'Failed[STATUS=' + response.status + '] : ' + response.url);

  error.response = response;
  throw error;
}

/**
 * Convert an object to request body
 * tha can be submiited as a form
 *
 * @param  {string} body       The URL we want to request
 *
 * @return {object}           The response data
 */
export function toFormBody(body) {
  const formBody = [];

  formBody.push('saltStart=salt');
  _.forOwn(body, (item, key) => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(item);

    formBody.push(encodedKey + '=' + encodedValue);
  });
  formBody.push('saltEnd=salt');
  return formBody.join('&');
}
