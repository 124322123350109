import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import * as apiUserService from '../../services/confirm';
import { MURecaptcha } from 'basic-components';
import Button from '../ui/Button';
import MUTextValidator from '../ui/MUTextValidator';
import _ from 'lodash';
import { setNotification } from '../../redux/actions';
import { store } from '../../../src';
import {RECAPTCHA_SITEKEY} from '../../config/environment.js';
import BasicPageComponent from '../BasicPageComponent';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Header from '../Header';
class Reminder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recaptchaResponse: '',
      emailaddress: '',
      beforeSend: true,
      captchaRendered: false,
      title: 'Password Reminder',
      description: 'You should type your e-mail to renew your password.'
    };
  }

  componentDidMount() {
    setTimeout(()=> {
      this.setState({captchaRendered: true});
    }, 500);
  }

  handleChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  handleSubmit = () => {
    console.log('submitted');
    window.grecaptcha.reset();
    this.sendForgotPasswordMail();
  }

  verifyCallback = (response) => {
    this.setState({recaptchaResponse: response});
  };

  sendForgotPasswordMail = () => {
    const { emailaddress, recaptchaResponse } = this.state;

    apiUserService.resetPassword(emailaddress, recaptchaResponse)
      .then((responseJson) => {
        this.setState({beforeSend: false,
          title: '',
          description: ''});
        console.log('Service Response: ' + responseJson);
      })
      .catch((error) => {
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            store.dispatch(setNotification(error.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      }).finally(()=>{
        this.setState({ recaptchaResponse: ''});
      });
  }

  render() {
    const { classes } = this.props;
    const { emailaddress, beforeSend, title, description} = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title= {title}
          description= {description}
          children = {
            beforeSend ?
              <ValidatorForm
                onSubmit={this.handleSubmit}
                className= {classes.form}
              >

                <div className = {classes.textField}>
                  < MUTextValidator
                    id="emailaddress"
                    onChange={(e) => this.handleChange('emailaddress', e)}
                    name="emailaddress"
                    value={emailaddress}
                    placeholder = 'YOUR EMAIL'
                    validators={['required', 'isEmail', 'maxStringLength:50']}
                    errorMessages={['Required', 'Please enter a valid email address.', 'The maximum allowed length is 50']}
                  />
                </div>

                <div className = {classes.textField}>
                  { this.state.captchaRendered ?
                    <MURecaptcha
                      verifyCallback={(response) => this.verifyCallback(response)}
                      sitekey = {RECAPTCHA_SITEKEY}
                    /> : null
                  }
                </div>

                <div className={ classes.btnSubmit}>
                  < Button
                    variant="outlined"
                    color="primary"
                    className='oulinedPrimary'
                    type="submit"
                    text="SEND"/>
                </div>

              </ValidatorForm> :
              <div className="col_full" style = {{marginTop: '-15%'}} align="center">
                <img src={require('../../images/success.png')} />
                <h3 style={{
                  textAlign: 'center',
                  marginTop: '30px',
                  fontFamily: 'Gilroy-Light',
                  fontWeight: 600}}>Mail has been sent to <span>{emailaddress}</span> to renew password</h3>
              </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Reminder);
