import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import productsRootReducer from './products';
import errorReducer from './errorReducer';
import notificationReducer from './notificationReducer';

const apiPortalRootReducer = combineReducers({
  login: loginReducer,
  products: productsRootReducer,
  error: errorReducer,
  notification: notificationReducer
});

export default apiPortalRootReducer;
