import React from 'react';
import Button from '../../ui/Button';
import * as Images from '../../../images';
import MUTypography from '../../ui/MUTypography';
import { setNotification } from '../../../redux/actions';
import { store } from '../../../../src';
import Avatar from '@material-ui/core/Avatar';
class ApplicationIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: false,
      icon: '',
      file: undefined,
      imageEncodedData: undefined

    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined && this.props.editapplication) {
      // state passed from history.push
      const { readOnly, applications, appId } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {
        if (value.id === appId) {
          this.setState({
            readOnly: readOnly,
            imageEncodedData: value.icon
          });
          this.props.onIconLoad(value.icon);
        }
      });
    }
  }
  fileClick = (e) => {
    e.target.value = null;
  }

  fileChange = (e) => {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size > 102400) {
      console.log('big size');
      store.dispatch(setNotification('Icon size must be less than 100KB', 'error'));
    } else {
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        console.log('image read complete');
        const iconBytes = new Uint8Array(reader.result);
        const imageEncoded = btoa(String.fromCharCode.apply(null, iconBytes));

        this.setState({
          icon: iconBytes,
          file: file,
          imageEncodedData: imageEncoded
        });
        this.props.fileChangedHandler(imageEncoded);
      };
    }
  }

  resetIcon = () => {
    this.setState({
      icon: '',
      file: undefined,
      imageEncodedData: undefined});
    this.props.onIconLoad('');
  }

  render() {
    const { classes } = this.props;
    const { readOnly } = this.state;

    return (
      <div id='appIcon' style = {{display: 'flex', margin: '40px 0px'}}>
        <div>
          {this.state.imageEncodedData ?
            <Avatar alt="appIcon" src={`data:image/png;base64,${this.state.imageEncodedData}`} className={classes.avatar} /> :
            <Avatar className = {classes.avatar} alt="appIcon" src={Images['defaultIcon']} />
          }
        </div>
        <div style={{display: 'flex', flexDirection: 'column', padding: '0px 30px'}}>
          <input
            accept="image/*"
            className={classes.inputStyle}
            id="contained-button-file"
            multiple
            type="file"
            onChange = {this.fileChange}
            onClick = {this.fileClick}
            disabled = {readOnly}
          />

          <label htmlFor="contained-button-file">
            <Button
              classes={{ root: classes.btnRefreshToken, text: classes.txtRefreshToken, label: classes.label }}
              text = "Choose an Icon"
              component = "span"
              disabled = {readOnly}
            />
          </label>
          <Button
            text = "Reset Icon"
            classes={{ root: classes.btnRefreshToken, text: classes.txtRefreshToken, label: classes.label }}
            onClick = {this.resetIcon}
            disabled = {readOnly}
            style={{
              border: 'solid 1px #000000',
              color: '#000000',
              backgroundColor: 'white',
              fontWeight: 700}}
          />
        </div>

        <div>
          <MUTypography variant="title" color="inherit"
            noWrap = {true}> The maximum allowed icon size is 100KB. </MUTypography>
        </div>

      </div>
    );
  }
}
export default (ApplicationIcon);
