const styles = theme => ({

  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'initial'
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  btnDetails: {
    border: '1px solid',
    borderRadius: '1.75rem',
    width: '190px',
    margin: 'auto',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#ED502E',
      borderColor: '#ED502E',
      color: 'white'
    }
  },
  apiCards: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    margin: '0% 10%'
  },
  card: {
    maxWidth: 400,
    textAlign: 'left',
    width: 384,
    height: '650px',
    marginBottom: '10px',
    boxShadow: 'inset -1px 0 0 0 rgba(0, 0, 0, 0.07)',
    backgroundColor: '#ffffff'
  },
  cardActionArea: {
    width: '100%',
    height: '648px'
  },
  cardActions: {
    justifyContent: 'center'
  },
  btnCardAction: {
    width: '216px',
    height: '50.4px',
    borderRadius: '28.8px',
    border: 'solid 0.9px #dcdcdc'
  },
  txtFindMore: {
    fontFamily: 'Gilroy-Light',
    fontSize: '14px',
    fontWeight: '200',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center'
  },
  cardContent: {
    padding: '75px'
  },
  link: {

    color: '#0f1120',
    fontSize: '22px',
    fontFamily: 'Gilroy-Light',
    textTransform: 'none',
    letterSpacing: '-0.6px',
    textAlign: 'center'
  },
  text: {
    fontFamily: 'Gilroy-Light',
    fontSize: '14.4px',
    lineHeight: 2,
    letterSpacing: '-0.4px',
    textAlign: 'center',
    color: '#4c5068',
    paddingTop: '10%'
  },
  label: {
    fontSize: '14px'
  }
});

export default styles;
