import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';

import MUTypography from '../ui/MUTypography';

class NotificationPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className = {classes.container}>

        <div className = {classes.intro} >
          <div >
            <img src={require('../../images/success.png')} />
          </div>
          <div className = {classes.typography}>
            <MUTypography
              variant = 'title'
              className = {classes.title}>
              {this.props.title}
            </MUTypography>
          </div>
          <div className = {classes.typography}>
            <MUTypography
              variant = 'body1'
              className={classes.description}>
              {this.props.description}
            </MUTypography>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(NotificationPage);

