import React, { Component } from 'react';
import View from '../View';
import './style.css';
import Header from '../Header';

class NotFound extends Component {
  render() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <View className='container' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1>
          404 <small>Page Not Found :(</small>
          </h1>
        </View>
      </div>
    );
  }
}

export default NotFound;
