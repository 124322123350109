import React from 'react';
import PropTypes from 'prop-types';
import {Switch} from '@material-ui/core/';

class MUSwitch extends React.Component {

  render() {
    const { props } = this;

    return (
      <Switch
        checked={props.checked}
        disabled = {props.disabled}
        onChange={props.onChange}
        classes={props.classes}
      />
    );
  }
}

MUSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

MUSwitch.defaultProps = {
  checked: false,
  disabled: false
};

export default MUSwitch;
