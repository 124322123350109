import React from 'react';

const View = (props) => (
  <div {...props}
    style={{
      paddingTop: props.paddingtop,
      paddingBottom: props.paddingbottom,
      minHeight: 400,
      ...props.style
    }}
  >
    { props.children }
  </div>
);

View.defaultProps = {
  paddingtop: 20,
  paddingbottom: 20
};

export default View;
