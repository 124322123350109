import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const AuthCodeReqTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"code"}>
              <StyledTableCell>code</StyledTableCell>
              <StyledTableCell>The code in order to take access token if user granted</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"state"}>
              <StyledTableCell>state</StyledTableCell>
              <StyledTableCell>The data if optionally passed in first request</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"error"}>
              <StyledTableCell>error</StyledTableCell>
              <StyledTableCell>Error code occured while authorization of the user</StyledTableCell>
            </StyledTableRow>  
          </TableBody>
        </Table>;

export default AuthCodeReqTable;
