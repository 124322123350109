const styles = theme => ({
  paragraph: {
    fontSize: '18px'
  },
  h6: {
    fontSize: '1.75rem',
    margin: '15px 0px'
  },
  h5: {
    fontSize: '2rem',
    fontWeight: 'bolder',
    margin: '15px 0px'
  },
  h4: {
    fontSize: '2.5rem',
    fontWeight: 'bolder'
  }

});

export default styles;
