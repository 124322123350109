import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../style.js';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ProductList from './List';
import {docsInfoDynamicContentScreen} from '../../../../../redux/constants/docsInfoContentTypes';
// import {MUList, MUListItem, MUListItemText, MUCollapse} from 'basic-components';
import MUList from '../../../../ui/MUList';
import MUListItem from '../../../../ui/MUListItem';
import MUListItemText from '../../../../ui/MuListItemText';
import MUCollapse from '../../../../ui/MUCollapse';
import _ from 'lodash';

class ProductGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.products.infoContent.content === props.group.id,
      selectedComponent: '',
      selectedService: ''
    };
  }

  handleClick = (e, id) => {
    this.props.switchDocsMainContentToInfo();
    this.props.setDocsApiInfo(this.props.group.id, docsInfoDynamicContentScreen, false);

    this.setState(prevState => ({
      open: !prevState.open,
      selectedComponent: id,
      selectedService: ''
    }));
  };

  onSelectListItem = (url) => {
    this.props.switchDocsMainContentToSwagger();
    this.props.setDocsSwaggerApi(url);

    this.setState({
      selectedService: url
    });
  };

  render() {
    const { classes, group } = this.props;
    const { open, selectedService} = this.state;

    return (
      <div className={classes.list}>
        <MUList
          component="nav"
        >
          <MUListItem
            button = {true}
            selected = {this.props.products.infoContent.content === group.id && !this.props.isStatic}
            onClick={(e) => this.handleClick(e, group.id)}
            classes ={{root: classes.listItem,
              selected: classes.selected}}>
            <MUListItemText
              inset = {false}
              classes={{primary: classes.listItemText}}
              primary={group.name} />
            {(open && this.props.products.infoContent.content === group.id) ? <ExpandLess /> : <ExpandMore />}
          </MUListItem>

          {_.isNil(group.services) ? null :
            <MUCollapse
              in={open && this.props.products.infoContent.content === group.id}
              timeout="auto"
              unmountOnExit = {true}
              classes = {{entered: classes.collapse}} >
              <ProductList {...this.props} onSelectListItem={(url) => this.onSelectListItem(url)} selectedService={selectedService} isProductSelected={this.props.products.infoContent.content === group.id}/>
            </MUCollapse>
          }
        </MUList>
      </div>
    );
  }
}

ProductGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductGroup);
