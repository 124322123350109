import * as types from '../constants/actionTypes';

export const switchDocsMainContentToInfo = () => ({ type: types.SWITCH_DOCS_MAIN_CONTENT_TO_INFO});
export const switchDocsMainContentToSwagger = () => ({ type: types.SWITCH_DOCS_MAIN_CONTENT_TO_SWAGGER});
export const setDocsSwaggerApi = url => ({ type: types.SET_DOCS_SWAGGER_API, url });
export const setDocsApiInfo = (info, component, isstatic) => ({
  type: types.SET_DOCS_API_INFO,
  info,
  component,
  isstatic
});

export const loginUser = (username, password, recaptchaResponse) => ({
  type: types.LOGIN_USER,
  username,
  password,
  recaptchaResponse
});

export const loginUserSuccess = token => ({
  type: types.LOGIN_USER_SUCCESS,
  token
});

export const loginUserError = token => ({
  type: types.LOGIN_USER_ERROR,
  token
});

export const logout = () => ({ type: types.LOGOUT });
export const logoutUser = () => ({ type: types.LOGOUT_USER });
export const tokenRefresh = () => ({ type: types.TOKEN_REFRESH });
export const tokenRefreshSuccess = token => ({ type: types.TOKEN_REFRESH_SUCCESS, token });
export const tokenRefreshError = error => ({ type: types.TOKEN_REFRESH_ERROR, error });
export const addProducts = products => ({ type: types.ADD_PRODUCTS, products});
export const addProductServices = services => ({ type: types.ADD_PRODUCT_SERVICES, services});
export const getProducts = () => ({ type: types.GET_PRODUCTS});
export const getProductServices = () => ({ type: types.GET_PRODUCT_SERVICES});
export const timeoutCreate = (timeoutId) => ({ type: types.TIMEOUT_CREATE, timeoutId});

export const setNotification = (message, variant, status) => ({
  type: types.SET_NOTIFICATION,
  message,
  variant,
  status
});

export const clearNotification = (variant) => ({
  type: types.CLEAR_NOTIFICATION,
  variant });
export const initialize = () => ({ type: types.INITIALIZE });
export const clearLogin = () => ({ type: types.CLEAR_LOGIN });
