const styles = theme => ({
  form: {
    paddingBottom: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formControl: {
    width: '480px',
    marginLeft: '8px',
    marginTop: 0,
    marginBottom: 0
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px'

  },
  select: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    padding: '4%',
    backgroundColor: 'white !important',
    borderRadius: '28px !important',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    width: '100%',
    height: '57px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  btnSubmit: {
    width: '100%',
    marginTop: '2%'
  },
  menu: {
    backgroundColor: 'white',
    borderRadius: '28px'
  }
});

export default styles;
