const styles = theme => ({
  btnViewAll: {
    width: '136px',
    height: '48px',
    borderRadius: '32px',
    border: 'solid 2px #e61837',
    backgroundColor: '#fff',
    marginTop: '30px'
  },
  txtViewAll: {
    fontFamily: 'Gilroy',
    color: '#e61837',
    textTransform: 'capitalize'
  },
  label: {
    fontSize: '14px'
  },
  margin: {
    margin: theme.spacing.unit
  },
  bootstrapRoot: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: '1px solid',
    backgroundColor: 'white',
    borderColor: '#ED502E',
    color: '#ED502E',
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(','),
    '&:hover': {
      backgroundColor: '#ED502E',
      borderColor: '#ED502E',
      border: '1px solid',
      color: 'white'
    }
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    color: 'white',
    backgroundColor: '#ED502E',
    borderColor: '#ED502E',
    width: 38,
    height: 38,
    '&:hover': {
      backgroundColor: '#F9F9F9',
      border: 'solid 1px',
      borderColor: '#ED502E',
      color: '#ED502E'
    }
  },
  navbar: {
    fontFamily: 'sans - serif',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#333'
  },
  logo: {
    marginTop: '-1%',
    marginLeft: '2%',
    marginRight: '65px !important'
  },
  header: {
    marginLeft: '56px',
    marginTop: '53px',
    height: '78px'
  },
  navigation: {
    border: 'none !important',
    backgroundColor: 'initial'
  },
  typography: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '3px',
    color: '#e61837',
    padding: '8px',
  },
  menu: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontWeight: '400',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '3px',
    color: '#ffffff',
    padding: '8px',
    color: '#4C5068'
  },
  header: {
    color: '#8B96A4',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '3px',
    padding: '10px 60px',
    textTransform: 'uppercase',
  },
  underline: {
    color: '#4C5068',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '3px',
    textTransform: 'uppercase',
    padding: '10px 60px',
    textDecoration: 'none',
    borderBottom: '4px solid #4C5068'
  },
  controlledExpansionPanels: {
    width: '50%',
    margin: 'auto',
    padding: 15
  },
  getStartBtn: {
    width: '182px',
    height: '44px',
    flexShrink: '0',
    borderRadius: '24px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    lineHeight: 'normal',
    
  },
  letsStartBtn: {
    width: '225px',
    height: '30px',
    borderRadius: '32px',
    border: '1px solid #F4F5F6',
    color: '#0F1120',
    backgroundColor: '#F4F5F6',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '22px !important',
    lineHeight: '28.8px',
    padding: '12px 32px',
    margin: 20,
  },
  getStartBtnTxt: {
    color: '#0F1120',
  },
  letsStartTxt: {
    color: '#0F1120',
    textAlign: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: '16.2px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28.8px',
    letterSxpacing: '-0.456px'
  },
  containerTitle: {
    width:'75px',
    marginTop: '50px',
    fontSize: '44px',
    fontWeight: 400,
    fontFamily: 'Gilroy',
    height: 100,
    margin: 'auto',
    letterSpacing: '-2.239px',

  },
  containerTypography: {
    marginTop: '-2%',
    width: '100%',
    height: 'auto',
    alignSelf: 'center',
    fontFamily: 'Gilroy-Light',
    fontSize: '16.2px',
    fontWeight: '400',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.8',
    letterSpacing: '-0.456px',
    textAlign: 'center',
    color: '#4c5068',
    marginBottom:50
  },

});

export default styles;

