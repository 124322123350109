import { createSelector } from 'reselect';

/**
 * Direct selector to the Login state domain
 */
const selectLoginDomain = (state) => state.login;

/**
 * Default selector used by Login
 */

const selectLogin = () => createSelector(
  selectLoginDomain,
  (substate) => {
    return substate.toJS();
  }
);

export default selectLogin;
