import React from 'react';
// import { MUButton, MUTypography} from 'basic-components';
import Button from '../../ui/Button';
import MUTypography from '../../ui/MUTypography';
import MUTextValidator from '../../ui/MUTextValidator';
import classNames from 'classnames';
import * as apiDashboardService from '../../../services/apidashboardservice';
import _ from 'lodash';
import { setNotification } from '../../../redux/actions';
import { store } from '../../../../src';

class TokenInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editPage: false,
      id: '',
      clientId: '',
      clientSecret: '',
      readOnly: false
    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined) {
      // state passed from history.push
      const { appId, applications, readOnly } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {
        console.log('product id : ' + value.id);
        if (value.id === appId) {
          this.setState({
            editPage: true,
            id: value.id,
            clientId: value.oauthClientId,
            clientSecret: value.oauthClientSecret,
            readOnly: readOnly
          });
          this.props.onTokenInfoLoad(value.oauthClientId, value.oauthClientSecret);
        }
      });
    }
  }

  renewTokens = () => {
    var { id, clientId, clientSecret } = this.state;

    apiDashboardService.renewTokens(id, clientId, clientSecret)
      .then((responseJson) => {
        this.setState({
          clientId: responseJson.data.clientId,
          clientSecret: responseJson.data.clientSecret
        });
        this.props.renewTokens(this.state.clientId, this.state.clientSecret, this.state.editPage);
      }
      ).catch((exception) => {
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      });
    console.log('clientID in tokeninfo: ' + this.state.clientId);
    console.log('clientSecret in token info : ' + this.state.clientSecret);

  }

  render() {

    const { classes } = this.props;
    const { clientId, clientSecret, readOnly} = this.state;

    return (

      <div id='appInfo' style={{display: 'flex', flexDirection: 'column'}}>
        <div>
          <MUTypography className = {classes.header} variant="headline" component="h2">
         Tokens
          </MUTypography>
        </div>
        <div>
          < MUTextValidator
            id="clientId"
            label= {'Client Id' }
            name="clientId"
            value={clientId}
            className = {classes.textValidator}
            disabled = {true}
          />
        </div>
        <div>
          < MUTextValidator
            id="clientSecret"
            label= {'Client Secret' }
            name="clientSecret"
            value={clientSecret}
            className = {classes.textValidator}
            disabled = {true}
          />
        </div>

        <div>
          <Button
            classes={{ root: classes.btnRefreshToken, text: classes.txtRefreshToken, label: classes.label }}
            text = 'Refresh Secret'
            color="secondary"
            onClick = {this.renewTokens}
            disabled = {readOnly}
          />
        </div>
      </div>
    );
  }
}
export default TokenInfo;
