import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import {InputLabel} from '@material-ui/core';

class MUInputLabel extends React.Component {

  render() {
    const { props } = this;
    const { classes } = this.props;

    return (
      <InputLabel
        required={props.required}
        className={props.className}
        classes ={{root: classes.root }}
        style={props.style}
        htmlFor = {props.htmlFor}
      >
        {props.text}
      </InputLabel>
    );
  }
}

MUInputLabel.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  htmlFor: PropTypes.string
};

export default withStyles(styles)(MUInputLabel);
