import React, { Component } from 'react';
import LiNavLink from '../LiNavLink';
import Copyrights from '../Copyrights';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as apiDashboardService from '../../services/apidashboardservice';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import { getProducts as getProductsSelect } from '../../redux/selectors';
import { docsInfoDynamicContentScreen } from '../../redux/constants/docsInfoContentTypes';
import { history } from '../../App';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      products: []
    };
  }

  componentWillMount() {
    const productList = this.props.selectProducts;

    if (!_.isEmpty(productList)) {
      this.setState({ products: productList });
    } else {
      this.getProducts();
    }
  }

  onDocumentationClick = (e, info) => {
    e.preventDefault();
    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(info, info, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true }
    });
    window.scrollTo(0, 0);

  }

  getProducts() {
    apiDashboardService.getProducts()
      .then((responseJson) => {
        if (responseJson.data) {
          this.setState({ products: responseJson.data });
          this.props.actions.addProducts(responseJson.data);
        }
      })
      .catch((exception) => {
        console.log('get products error');
        // TODO: Show Generic Error to user - System error occured, please try again
        console.log('NewApplication - refreshProducts => ' + JSON.stringify(exception));
      });
  }
  handleClick = (e, id) => {
    this.props.actions.switchDocsMainContentToInfo();
    if (id === "apiUsage") {
      this.props.actions.setDocsApiInfo("Info3", "Info3", false);

    }
    else if (id === "authorization") {
      this.props.actions.setDocsApiInfo("Info2", "Info2", false);

    }
    else {
      this.props.actions.setDocsApiInfo(id, docsInfoDynamicContentScreen, false);

    }

    if (id === "api") {
      this.props.actions.setDocsApiInfo('', '', true);
      localStorage.setItem("apiDocs", "1")
      localStorage.removeItem('baasDocs')
    }
    if (id === "baas") {
      this.props.actions.setDocsApiInfo('', '', true);
      localStorage.setItem("baasDocs", "2")
      localStorage.removeItem('apiDocs')

    }
    history.push({
      pathname: '/docs',
      state: { isLinked: true }
    });
    window.scrollTo(0, 0);

  };

  render() {
    const { products } = this.state;
    const { classes } = this.props;

    return (
      <footer id="footer" className={classNames('dark', classes.footer)}>
        <Copyrights />

        <div className="container">
          {/* Footer Widgets */}
          <div className="footer-widgets-wrap clearfix">
            <div className="col_full">
              <div className={classNames('widget clearfix')}>
                <div className={classNames('row', classes.menu)}>

                  <div className="col-lg-4 col-sm-12 col-md-6 bottommargin-sm widget_links">

                    <Link to='/'>
                      <img src={require('../../images/albaraka_api.jpg')} alt="Canvas Logo" />
                    </Link>
                  </div>

                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Products</h4>
                    <ul style={{ listStyleType: 'inherit' }}>
                      <LiNavLink exact={true} to="/docs" onClick={(e) => { this.handleClick(e, 'api') }}>API</LiNavLink>

                      <LiNavLink exact={true} to="/docs" onClick={(e) => { this.handleClick(e, 'baas') }}>BAAS</LiNavLink>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Documentation</h4>
                    <ul >
                      <LiNavLink exact={true} to='/gettingStarted' onClick={() => {  window.scrollTo(0, 0);}}>Getting Started</LiNavLink>
                      <LiNavLink exact={true} to='/docs' onClick={(e) => { this.handleClick(e, 'authorization') }}>Authorization</LiNavLink>
                      <LiNavLink exact={true} to='/docs' onClick={(e) => { this.handleClick(e, 'apiUsage') }}>API Usage</LiNavLink>
                    </ul>
                  </div>

                  <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <h4>Support</h4>
                    <ul>
                      <LiNavLink exact={true} to="/community" onClick={() => {
                        window.scrollTo(0, 0);
                      }}>Community</LiNavLink>
                      <LiNavLink exact={true} to="/faq"  onClick={() => {
                        window.scrollTo(0, 0);
                      }}>FAQ</LiNavLink>
                      <LiNavLink exact={true} to="/contact" onClick={() => {
                        window.scrollTo(0, 0);
                      }}>Contact Us</LiNavLink>
                      <LiNavLink exact to="/announcement" disabled>
                        Support Ticket
                      </LiNavLink>
                    </ul>
                  </div>

                  {/* <div className="col-lg-2 col-6 bottommargin-sm widget_links">
                    <div className={classes.link}>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/facebook.png')} ></img></a>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/twitter.png')} ></img></a>
                      <a href="https://www.albaraka.com.tr/" target="_blank" style ={{ marginBottom: '50%'}}><img src= {require('../../images/pinterest.png')} ></img></a>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div> { /* .footer-widgets-wrap end*/}
        </div>
        {/* Footer Widgets */}

      </footer >
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

const mapStateToProps = state => ({
  products: state.products,
  selectProducts: getProductsSelect(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Footer);
