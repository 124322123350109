import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const GetAuthCodeReqTable = () => 
    <Table aria-label="customized table" style={{ width: 710 }}>
          <TableHead sx={{ width: 710 }}>
            <TableRow>
              <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
              <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"client_id"}>
              <StyledTableCell>client_id</StyledTableCell>
              <StyledTableCell>Required. The client id provided to you when you create new application</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"response_type"}>
              <StyledTableCell>response_type</StyledTableCell>
              <StyledTableCell>Required. type “code” (see OAuth 2.0 spec)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"scope"}>
              <StyledTableCell>scope</StyledTableCell>
              <StyledTableCell>Required.  The token's authorization scope you'll be provided.<br></br>
              Make sure which scopes activated while application creation. Must be selected from activated.</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"state"}>
              <StyledTableCell>state</StyledTableCell>
              <StyledTableCell>Optional. This parameter will be passed to redirect uri</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"redirect_uri"}>
              <StyledTableCell>redirect_uri</StyledTableCell>
              <StyledTableCell>Required. The URI to redirect after the user grants/denies permission.<br></br>
                   Make sure redirect_uri must be entered to whitelist in application page</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default GetAuthCodeReqTable;
