const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex'
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-12%'
  },
  menu: {
    width: 200
  },
  registerButton: {
    marginLeft: theme.spacing.unit,
    marginTop: '20px',
    display: 'flex'
  },
  margin: {
    margin: theme.spacing.unit
  },
  bootstrapFormLabel: {
    marginTop: '-35px',
    marginLeft: '8px'
  },
  textFieldEmail: {
    marginLeft: '45px',
    marginRight: '-5px',
    clear: 'right'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  btnSubmit: {
    width: '100%',
    marginTop: '2%',
    marginBottom: '15%'
  },
  input: {
    height: '100px'
  },
  typography: {
    marginTop: 20,
    color: '#4C5068',
    textAlign: 'right',
    fontFamily: 'Gilroy-Light',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: ' -0.608px'
  },
  screenContainer: { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', padding: '30px 0',paddingBottom:0, fontFamily: 'Gilroy' },
  screenSubContainer: { padding: 10, width: '100%' },


});

export default styles;
