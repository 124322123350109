import React, { Component } from 'react';

import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import {FormControl} from '@material-ui/core';
import * as apiUserService from '../../services/confirm';
import * as constants from '../Register/registerConstants';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MUInputLabel from '../ui/MUInputLabel';
import MUTextValidator from '../ui/MUTextValidator';
import MUNativeSelect from '../ui/MUNativeSelect';
import View from '../View';
import Button from '../ui/Button';
import { RemoveRedEye, Mail, Lock, AccountCircle, Phone } from '@material-ui/icons';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { setNotification } from '../../redux/actions';
import { store } from '../../../src';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MUTypography from '../ui/MUTypography';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      country: '',
      firstname: '',
      lastname: '',
      emailaddress: '',
      phonenumber: '',
      password: '',
      confirmpassword: '',
      recaptchaResponse: '',
      passwordIsMasked: true,
      newPasswordIsMasked: true,
      confirmPasswordIsMasked: true,
      beforeRegister: true,
      onServiceCall: true,
      newpassword: ''
    };
    this.saveChanges = this.saveChanges.bind(this);
  }

  componentWillMount() {
    // custom validation rule
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.newpassword) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isPhoneValid', (value) => {
      if (value.length > 10) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('minLength', (value) => {
      if (value !== '' && value.length < 8) {
        return false;
      }
      return true;
    });
    this.getUser();
  }

  handleChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  getUser() {

    apiUserService.getUser()
      .then((responseJson) => {
        if (responseJson.data) {
          let user = responseJson.data;

          console.log('get user success');
          this.setState({
            firstname: user.firstName,
            lastname: user.lastName,
            emailaddress: user.eMailAddress,
            phonenumber: user.phoneNumber,
            country: user.countryCode
          });
        }
      })
      .catch((exception) => {
        console.log('get user error');
        let errorMessage = '' + exception;

        this.props.actions.setNotification(errorMessage, 'error');
      })
      .finally(()=>{
        this.setState({ onServiceCall: false });
      });
  }
  saveChanges() {
    const { firstname, lastname, phonenumber, country} = this.state;

    console.log('update password clicked');
    apiUserService.updateUser(firstname, lastname, phonenumber, country)
      .then((responseJson) => {
        if (responseJson.data) {
          console.log('save changes success');
          this.props.actions.setNotification('Personal info saved successfully', 'success');
        }
      })
      .catch((exception) => {
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      })
      .finally(()=>{
        this.setState({ onServiceCall: false });
      });

  }

  changePassword = () => {
    const { emailaddress, password, newpassword, confirmpassword} = this.state;

    apiUserService.changePassword(emailaddress, password, newpassword, confirmpassword)
      .then((responseJson) => {
        console.log('changePassword success');
        this.props.actions.setNotification('Password info updated successfully', 'success');
        this.setState({
          password: '',
          confirmpassword: '',
          newpassword: ''
        });
      })
      .catch((exception) => {
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      })
      .finally(()=>{
        this.setState({ onServiceCall: false });
      });
  }

  togglePasswordMask = (e, passwordType) => {
    e.preventDefault();

    if (passwordType === 'password') {
      this.setState(prevState => ({
        passwordIsMasked: !prevState.passwordIsMasked
      }));
    } else if (passwordType === 'newpassword') {
      this.setState(prevState => ({
        newPasswordIsMasked: !prevState.newPasswordIsMasked
      }));
    } else if (passwordType === 'confirmpassword') {
      this.setState(prevState => ({
        confirmPasswordIsMasked: !prevState.confirmPasswordIsMasked
      }));
    }
  };

  render() {
    const { classes } = this.props;
    const { firstname, lastname, password,
      passwordIsMasked, newPasswordIsMasked, confirmPasswordIsMasked, confirmpassword, country, newpassword, onServiceCall} = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title='Edit Profile'
          description='Via Edit Profile page, you can edit your information quickly or complete your missing information.'
          children = {
            <div style={{maxWidth: 500
            }}
            className='divcenter'>
              {onServiceCall ?
                <div className={classes.root}>
                  <LinearProgress classes={{ colorPrimary: classes.colorPrimaryFirst, barColorPrimary: classes.barColorPrimaryFirst }} />
                  <br />
                  <LinearProgress
                    classes={{ colorPrimary: classes.colorPrimarySecond, barColorPrimary: classes.barColorPrimarySecond }}
                  />
                </div> :
                null }
              <Grid container className={classes.root} spacing={16}>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.demo}
                    justify="center"
                    spacing={Number(24)}
                  >

                    <Grid style ={{marginLeft: '5%'}}>
                      <ValidatorForm
                        onSubmit={this.saveChanges}
                      >
                        <MUTypography paragraph={true} className={classes.title}>
                        Profil Info
                        </MUTypography>
                        < MUTextValidator
                          id="firstname"
                          placeholder = "NAME"
                          onChange={(e) => this.handleChange(e, 'firstname')}
                          name="firstname"
                          value={firstname}
                          className={classes.textField}
                          validators={['required', 'maxStringLength:30']}
                          errorMessages={['Required', 'The maximum allowed length is 30']}
                        />

                        < MUTextValidator
                          id="lastname"
                          placeholder="LAST NAME"
                          onChange={(e) => this.handleChange(e, 'lastname')}
                          name="lastname"
                          value={lastname}
                          className={classes.textField}
                          validators={['required', 'maxStringLength:30']}
                          errorMessages={['Required', 'The maximum allowed length is 30']}
                        />
                        <br/>

                        <div className = {classes.textField}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            className = {classes.formControl}
                            value={country}
                            onChange={(e) => this.handleChange(e, 'country')}
                            SelectProps={{
                              id: 'select',
                              className: classes.select,
                              inputProps: {
                                id: 'age-simple',
                                className: classes.selectbox,
                                placeholder: 'COUNTRY'
                              },
                              MenuProps: {
                                className: classes.menu
                              },
                              disableUnderline: true
                            }}
                            margin="normal"
                          >
                            <MenuItem value='-1'>
                          COUNTRY
                            </MenuItem>
                            {constants.countryData.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <br/>
                        <Button
                          variant="outlined"
                          color='primary'
                          type = "submit"
                          text="SAVE CHANGES"
                          style ={{fontFamily: 'Gilroy-Light', marginLeft: '2.1%'}}
                        />
                      </ValidatorForm>

                    </Grid>

                    <br/>
                    <Grid item>
                      <ValidatorForm
                        onSubmit={this.changePassword}
                      >
                        <MUTypography paragraph={true} className={classes.title}>
                        Password Info
                        </MUTypography>
                        < MUTextValidator
                          id="password"
                          placeholder="CURRENT PASSWORD"
                          onChange={(e) => this.handleChange(e, 'password')}
                          name="password"
                          value={password}
                          className={classes.textField}
                          type={passwordIsMasked ? 'password' : 'text'}
                          validators={['required', 'minLength', 'matchRegexp:^(?=.*[0-9])', 'matchRegexp:^(?=.*[a-zA-Z])', 'maxStringLength:30']}
                          errorMessages={['Required',
                            'At least 8 characters must be entered.',
                            'Must contain at least 1 digit.',
                            'Must contain at least 1 letter.',
                            'The maximum allowed length is 30']}
                        />
                        < MUTextValidator
                          id="newpassword"
                          placeholder="NEW PASSWORD"
                          onChange={(e) => this.handleChange(e, 'newpassword')}
                          name="newpassword"
                          value={newpassword}
                          className={classes.textField}
                          type={newPasswordIsMasked ? 'password' : 'text'}
                          validators={['required', 'minLength', 'matchRegexp:^(?=.*[0-9])', 'matchRegexp:^(?=.*[a-zA-Z])', 'maxStringLength:30']}
                          errorMessages={['Required',
                            'At least 8 characters must be entered.',
                            'Must contain at least 1 digit.',
                            'Must contain at least 1 letter.',
                            'The maximum allowed length is 30']}
                        />

                        <MUTextValidator
                          id="confirmpassword"
                          placeholder="RE-ENTER NEW PASSWORD"
                          onChange={(e) => this.handleChange(e, 'confirmpassword')}
                          name="confirmpassword"
                          value={confirmpassword}
                          className={classes.textField}
                          type={confirmPasswordIsMasked ? 'password' : 'text'}
                          validators={['required', 'isPasswordMatch']}
                          errorMessages={['Required', 'Password does not match']}
                        />
                        <Button
                          variant="outlined"
                          color='primary'
                          className='outlinedPrimary'
                          text="Update Password"
                          type = "submit"
                          style ={{fontFamily: 'Gilroy-Light', marginLeft: '2.1%'}}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

          }
        />

      </div>
    );
  }

}
const mapStateToProps = state => ({
  login: state.login.toJS()
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Profile);

