const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '1% 0%'
  },
  menu: {
    width: 200
  },
  btnChange: {
    marginLeft: '8px',
    heigth: '30px',
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#ED502E',
    color: '#ED502E',
    '&:hover': {
      backgroundColor: '#ED502E',
      borderColor: '#ED502E',
      border: '2px solid',
      color: 'white'
    }
  },
  btnSubmit: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2%',
    marginBottom: '15%'
  },
  message: {
    textAlign: 'center',
    marginTop: '30px',
    fontFamily: 'Gilroy-Light',
    fontWeight: 600
  }
});

export default styles;
