import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@material-ui/core';

class MUIconButton extends React.Component {

  render() {
    const { props } = this;

    return (
      <IconButton
        className={props.className}
        color={props.color}
        aria-label={props.ariaLabel}
        onClick={props.onClick}
        aria-owns={props.ariaOwns}
        aria-haspopup={props.ariaHasPopup}
      >
        {props.children}
      </IconButton>
    );
  }
}

MUIconButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  ariaOwns: PropTypes.string,
  ariaHasPopup: PropTypes.string
};

export default MUIconButton;
