import React, { Component } from 'react';
import ScreenContainer from '../ui/ScreenContainer';
import './style.css';
import ControlledExpansionPanels from '../ui/ControlledExpansionPanels';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
import Button from '../ui/Button';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import LiNavLink from '../LiNavLink';
import MUTypography from '../ui/MUTypography';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import { history } from '../../App';

const generalQuestions = [
  {
    id: 'p1',
    title: 'What is the Albaraka API?',
    content: 'The Albaraka API Exchange is a digital platform that accelerates developer engagement and adoption of Albaraka APIs. ' +
      'Albaraka API is designed for developers to discover and consume Albaraka APIs with their innovative applications.'
  }
];

const openBankingQuestions = [
  {
    id: 'p2',
    title: 'What is Open Banking?',
    content: 'Open Banking is a definiton of ecosystem that APIs will allow banks,' +
      'fintechs and other third party providers to increase collaboration and ' +
      'Cooperation to be able to design easy-to-use experiences and innovative products for Customers.'
  }
];

const loginRegisterQuestions = [
  {
    id: 'p3',
    title: 'How do I register?',
    content: 'Click on the Register link at the top right of the page and follow the instructions. After filling the registration form and submitting,' +
      'an activation link will be sent to your e-mail inbox. After clicking the link you will be directed to confirmation page.'
  },
  {
    id: 'p4',
    title: 'How Do I login?',
    content: 'Click on the Log in link at the top right of the page. After typing your e-mail and password, you will be directed to “My Applications” page.'
  },

  {
    id: 'p5',
    title: 'What are the Password Requirements?',
    content: 'Your password should consist of at least 8 characters and at least one letter and one number.'
  },

  {
    id: 'p6',
    title: 'I forgot my password – how do I reset it?',
    content: 'On the Log in screen click the ‘Forgot your password?’ button and type your e-mail. A link will be sent to your inbox to renew your password.'
  }
];

const applicationQuestions = [
  {
    id: 'p7',
    title: 'What is an Application ?',
    content: 'An application is for a user to manage APIs credentials. ' +
      'It is identified by a unique app_id and an app_name given by the user and an app_secret generated by the system.'
  },
  {
    id: 'p8',
    title: 'How to create an application?',
    content: 'On the menü click “My Applications” page and follow the instructions'
  },
  {
    id: 'p9',
    title: 'How many applications can I create?',
    content: 'There is no limit for the number of applications you create.'
  },
  {
    id: 'p10',
    title: 'Can I use the same name for two different apps?',
    content: 'No, You can’t. Using the same name for different applications will make harder the categorization and management process.'
  },
  {
    id: 'p11',
    title: 'Can I create apps in more than one country?',
    content: 'Yes, you can create apps in any available country.'
  }
];

const supportQuestions = [
  {
    id: 'p12',
    title: 'How I send questions and requests related to APIs?',
    content: 'After browsing API Documentation if you couldn’t an answer' +
      ' please click “Contact Us” and follow the instruction to send your questions and requests.'
  },
  {
    id: 'p13',
    title: 'How to Go Live?',
    content: 'After you complete the development and tests of your applications, send your Production request via Contact Us page.'
  }
];

class Faq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      limitedApplicationQuestions: [],
      limitedLoginRegisterQuestions: [],
      generalQuestions: [],
      supportQuestions: [],
      isGeneralClicked: false,
      isBankingClicked: true,
      isSupportClicked: false,
      isLoginClicked: false,
      isApplicationClicked: false
    };
  }
  handleGeneralClick = () => {
    this.setState({
      isGeneralClicked: true,
      isBankingClicked: false,
      isSupportClicked: false,
      isLoginClicked: false,
      isApplicationClicked: false
    });
  };
  handleBankingClick = () => {
    this.setState({
      isGeneralClicked: false,
      isBankingClicked: true,
      isSupportClicked: false,
      isLoginClicked: false,
      isApplicationClicked: false,
    });
  };
  handleSupportClick = () => {
    this.setState({
      isGeneralClicked: false,
      isBankingClicked: false,
      isSupportClicked: true,
      isLoginClicked: false,
      isApplicationClicked: false,
    });
  };
  handleLoginClick = () => {
    this.setState({
      isGeneralClicked: false,
      isBankingClicked: false,
      isSupportClicked: false,
      isLoginClicked: true,
      isApplicationClicked: false,
    });
  };
  handleApplicationClick = () => {
    this.setState({
      isGeneralClicked: false,
      isBankingClicked: false,
      isSupportClicked: false,
      isLoginClicked: false,
      isApplicationClicked: true,
    });
  };
  handleButtonSlackClick = () => {
    const url = 'https://join.slack.com/t/albarakaapi/shared_invite/zt-24pex8dlv-htHYqVb4SdZkM9~DI1fPWQ';
    window.open(url, '_blank');
  };
  handleButtonMediumClick = () => {
    const url = 'https://medium.com/albaraka-open-banking';
    window.open(url, '_blank');
  };
  componentWillMount() {
    // TODO: get faqs from service
    window.scrollTo(0, 0);
    this.setState({
      limitedApplicationQuestions: applicationQuestions.slice(0, 3),
      limitedLoginRegisterQuestions: loginRegisterQuestions.slice(0, 3),
      generalQuestions: generalQuestions.slice(0, 3),
      supportQuestions: supportQuestions.slice(0, 3)

    });
  }

  onClick = (e, name, value) => {
    e.preventDefault();
    this.setState({ [name]: [...value] });
  };

  render() {
    const { classes } = this.props;
    const { limitedApplicationQuestions, limitedLoginRegisterQuestions, generalQuestions, supportQuestions } = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <div>
          <MUTypography className={classes.containerTitle}>
            FAQ
          </MUTypography>
          <MUTypography className={classes.containerTypography}>
            You can find frequently asked questions and their answers in the FAQ section, which includes previously asked and likely questions.
          </MUTypography>
          <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center', textAlign: 'center' }}>
            <ul style={{ listStyleType: 'none', display: 'flex', margin: 'auto', justifyContent: 'center', textAlign: 'center', color: '#8B96A4' }}>
              <Button
                variant='text'
                text="GENERAL"
                className={this.state.isGeneralClicked ? classes.underline : classes.header}
                onClick={this.handleGeneralClick}
              />
              <Button
                variant='text'
                text="OPEN BANKING"
                className={this.state.isBankingClicked ? classes.underline : classes.header}
                onClick={this.handleBankingClick} />
              <Button
                variant='text'
                text="SUPPORT"
                className={this.state.isSupportClicked ? classes.underline : classes.header}
                onClick={this.handleSupportClick}
              />
              <Button
                variant='text'
                text="LOGIN AND REGISTER"
                className={this.state.isLoginClicked ? classes.underline : classes.header}
                onClick={this.handleLoginClick}
              />
              <Button
                variant='text'
                text="APPLIICATIONS"
                className={this.state.isApplicationClicked ? classes.underline : classes.header}
                onClick={this.handleApplicationClick}
              />
            </ul>
          </div>
          <div hidden={!this.state.isGeneralClicked} className={classes.controlledExpansionPanels}>
            <ControlledExpansionPanels questions={generalQuestions} />
          </div>
          <div hidden={!this.state.isBankingClicked} className={classes.controlledExpansionPanels}>
            <ControlledExpansionPanels questions={limitedLoginRegisterQuestions} />
          </div>
          <div hidden={!this.state.isSupportClicked} className={classes.controlledExpansionPanels}>
            <ControlledExpansionPanels questions={supportQuestions} />
          </div>
          <div hidden={!this.state.isLoginClicked} className={classes.controlledExpansionPanels}>
            <ControlledExpansionPanels questions={limitedLoginRegisterQuestions} />
            <Button
              classes={{ root: classes.btnViewAll, text: classes.txtViewAll, label: classes.label }}
              text='View All'
              onClick={(e) => this.onClick(e, 'limitedLoginRegisterQuestions', loginRegisterQuestions)}
            />

          </div>
          <div hidden={!this.state.isApplicationClicked} className={classes.controlledExpansionPanels}>
            <ControlledExpansionPanels questions={limitedApplicationQuestions} />
            <Button
              classes={{ root: classes.btnViewAll, text: classes.txtViewAll, label: classes.label }}
              text='View All'
              onClick={(e) => this.onClick(e, 'limitedApplicationQuestions', applicationQuestions)}
            />

          </div>
          <div style={{ margin: 'auto', textAlign: 'center' }}>
            <Button
              variant='text'
              classes={{ root: classes.getStartBtn }}
              text="STILL NEED HELP?"
              onClick={() => { history.push("/support") }}

            >
            </Button>
          </div>
          <div style={{ textAlign: 'center', margin: 'auto' }}>
            <div style={{ display: 'flex', maxWidth: '75%', paddingBottom: 56, margin: 'auto', justifyContent: 'center' }}>
              <div >
                <img style={{ position: 'absolute', zIndex: 1, marginTop: 25, marginLeft: 25 }} src={require('../../images/slackBlack.png')} alt="community" />
                <Button
                  variant='text'
                  classes={{ root: classes.letsStartBtn, label: classes.getStartBtnTxt }}
                  text="JOIN OUR COMMUNITY"
                  onClick={this.handleButtonSlackClick}
                />
              </div>
              <div>
                <img style={{ position: 'absolute', zIndex: 1, marginTop: 25, marginLeft: 25 }} src={require('../../images/mediumBlack.png')} alt="community" />

                <Button
                  variant='text'
                  classes={{ root: classes.letsStartBtn, label: classes.getStartBtnTxt }}
                  text="Our Medium Blog"
                  onClick={this.handleButtonMediumClick}
                />
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default withStyles(styles)(Faq);
