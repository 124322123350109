import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import registerServiceWorker from './registerServiceWorker';

import rootSaga from './sagas';
import * as storeConfigs from './store/configureStore';

const storeWithConfig = storeConfigs.configureStore();

storeWithConfig.runSaga(rootSaga);

export const store = storeWithConfig.store;
export const persistor = storeConfigs.configurePersistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
