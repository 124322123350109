import { call } from '../utils/serviceUtils';
import {
  APIUSERSERVICE_CONFIRM,
  APIUSERSERVICE_CHANGE_PASSWORD_BY_KEY,
  APIUSERSERVICE_REGISTER,
  APIUSERSERVICE_RESET_PASSWORD,
  APIUSERSERVICE_SAVE_CONTACT,
  APIUSERSERVICE_GET_USER,
  APIUSERSERVICE_UPDATE_USER,
  APIUSERSERVICE_CHANGE_PASSWORD,
  APIUSERSERVICE_REACTIVATION

} from '../config/paths';

export function doConfirm(key) {
  return call.postBeforeLogin(APIUSERSERVICE_CONFIRM,
    {
      'key': key
    });
}
export function changePasswordByKey(password, key) {
  return call.postBeforeLogin(APIUSERSERVICE_CHANGE_PASSWORD_BY_KEY,
    {
      'password': password,
      'key': key
    });
}

export function resetPassword(eMailAddress, recaptchaResponse) {
  return call.postBeforeLogin(APIUSERSERVICE_RESET_PASSWORD,
    {
      'eMailAddress': eMailAddress,
      'recaptchaResponse': recaptchaResponse
    });
}
export function register(name, lastName, eMailAddress, phoneNumber, password, country, recaptchaResponse, timeout) {
  return call.postBeforeLogin(APIUSERSERVICE_REGISTER,
    {
      'name': name,
      'lastName': lastName,
      'eMailAddress': eMailAddress,
      'phoneNumber': phoneNumber,
      'password': password,
      'country': country,
      'recaptchaResponse': recaptchaResponse
    }, '', timeout);
}

export function saveContact(name, eMailAddress, subject, message, recaptchaResponse) {
  return call.postBeforeLogin(APIUSERSERVICE_SAVE_CONTACT, {
    'name': name,
    'eMailAddress': eMailAddress,
    'subject': subject,
    'message': message,
    'recaptchaResponse': recaptchaResponse
  });
}
export function getUser() {
  return call.post(APIUSERSERVICE_GET_USER, {
  });
}
export function updateUser(firstName, lastName, phoneNumber, country) {
  return call.post(APIUSERSERVICE_UPDATE_USER, {
    'firstName': firstName,
    'lastName': lastName,
    'phoneNumber': phoneNumber,
    'country': country
  });
}
export function changePassword(eMailAddress, password, newpassword, confirmpassword) {
  return call.post(APIUSERSERVICE_CHANGE_PASSWORD, {
    'eMailAddress': eMailAddress,
    'password': password,
    'newPassword': newpassword,
    'confirmPassword': confirmpassword
  });
}
export function reactivate(eMailAddress, recaptchaResponse) {
  return call.postBeforeLogin(APIUSERSERVICE_REACTIVATION, {
    'eMailAddress': eMailAddress,
    'recaptchaResponse': recaptchaResponse
  });

}
