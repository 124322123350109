import _ from 'lodash';
import { createSelector } from 'reselect';

/** ******** HELPER ******* */
function groupBy(myArray, key) {
  return _.mapValues(_.groupBy(myArray, key),
    list => list.map(myArray => _.omit(myArray, key)));
}

export const getProducts = (state) => {
  return (state.products && !_.isEmpty(state.products.productList)) ? state.products.productList : [];
};

export const getProductServices = (state) => (groupBy(state.products.services, 'productId') || []);

export const combineProductsWithServices = createSelector(
  getProducts,
  getProductServices,
  (products, services) => (_.each(products, product => {product.services = services[product.id];})
  )
);
