import { connect } from 'react-redux';
import * as Actions from '../../../redux/actions';
import Sidebar from '../Sidebar';
import {combineProductsWithServices} from '../../../redux/selectors';

const {
  switchDocsMainContentToInfo,
  switchDocsMainContentToSwagger,
  setDocsSwaggerApi,
  setDocsApiInfo
} = Actions;

const mapDispatchToProps = {
  switchDocsMainContentToInfo,
  switchDocsMainContentToSwagger,
  setDocsSwaggerApi,
  setDocsApiInfo
};

export default connect(
  state => ({combinedProductAndServices: combineProductsWithServices(state),
    isStatic: state.products.infoContent.isstatic
  }),
  mapDispatchToProps
)(Sidebar);

