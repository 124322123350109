import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ScopeTable = () => 
    <Table sx={{ width: 350 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{fontSize:16}}>Applications</StyledTableCell>
              <StyledTableCell style={{fontSize:16}}>Scopes</StyledTableCell>
              <StyledTableCell style={{fontSize:16}}>Grant Types</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"Accounts"} size="small">
              <StyledTableCell size="medium">Accounts</StyledTableCell>
              <StyledTableCell size="medium">accounts</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Payments"}>
              <StyledTableCell>Payments</StyledTableCell>
              <StyledTableCell>payments</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Money Transfer"}>
              <StyledTableCell>Money Transfer</StyledTableCell>
              <StyledTableCell>moneytransfers</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Loans/Financing"}>
              <StyledTableCell>Loans/Financing</StyledTableCell>
              <StyledTableCell>loans</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Credit Card"}>
              <StyledTableCell>Credit Card</StyledTableCell>
              <StyledTableCell>creditcards</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Information Services"}>
              <StyledTableCell>Information Services</StyledTableCell>
              <StyledTableCell>public</StyledTableCell>
              <StyledTableCell size="medium">client_credentials, refresh_token</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Investment Products"}>
              <StyledTableCell>Investment Products</StyledTableCell>
              <StyledTableCell>investments</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Analytics"}>
              <StyledTableCell>Analytics</StyledTableCell>
              <StyledTableCell>analytics</StyledTableCell>
              <StyledTableCell size="medium">client_credentials , refresh_token</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Cancels"} size="small">
            <StyledTableCell>Cancels</StyledTableCell>
              <StyledTableCell>cancels</StyledTableCell>
              <StyledTableCell size="medium">authorization_code, refresh_token, password</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Takbis"}>
              <StyledTableCell>Takbis</StyledTableCell>
              <StyledTableCell>takbis</StyledTableCell>
              <StyledTableCell size="medium">client_credentials , refresh_token</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Accounts"}>
              <StyledTableCell>Baas Accounts</StyledTableCell>
              <StyledTableCell>baas_accounts</StyledTableCell>
              <StyledTableCell size="medium">authorization_id</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Money Transfers"}>
              <StyledTableCell>Baas Money Transfers</StyledTableCell>
              <StyledTableCell>baas_moneytransfers</StyledTableCell>
              <StyledTableCell size="medium">authorization_id</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Investments"}>
              <StyledTableCell>Baas Investments</StyledTableCell>
              <StyledTableCell>baas_investments</StyledTableCell>
              <StyledTableCell size="medium">authorization_id</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Onboarding"}>
              <StyledTableCell>Baas Onboarding</StyledTableCell>
              <StyledTableCell>baas_onboarding</StyledTableCell>
              <StyledTableCell size="medium">client_credentials</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Customers"}>
              <StyledTableCell>Baas Customers</StyledTableCell>
              <StyledTableCell>baas_customers</StyledTableCell>
              <StyledTableCell size="medium">client_credentials</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Baas Cancels"}>
              <StyledTableCell>Baas Cancels</StyledTableCell>
              <StyledTableCell>baas_cancels</StyledTableCell>
              <StyledTableCell size="medium">authorization_id</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;
export default ScopeTable;
