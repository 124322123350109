import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

const styles = {
  underline: {
    textDecoration: 'none',
    borderBottom: '4px solid #e61837'
  },
  disabled: {
    pointerEvents: 'none',
    color: 'gray',
    cursor: 'not-allowed',
  }
};

class LiNavLink extends Component {
  render() {
    const {
      to,
      exact,
      strict,
      activeClassName,
      className,
      activeStyle,
      style,
      isActive: getIsActive,
      onClick,
      disabled,
      ...rest
    } = this.props;

    return (
      <Route
        path={typeof to === 'object' ? to.pathname : to}
        exact={exact}
        strict={strict}
        children={({ location, match }) => {
          const isActive = !!(getIsActive ? getIsActive(match, location) : match);

          const liClassNames = [
            isActive && activeClassName,
            className,
          ].filter(Boolean).join(' ');

          const liStyle = isActive
            ? { ...style, ...activeStyle, ...(disabled ? {} : styles.underline) }
            : style;

          return (
            <li
              className={liClassNames}
              style={liStyle}
              onClick={() => {
                if (!disabled && onClick) {
                  onClick();
                }
              }}
            >
              <Link
                to={to}
                {...rest}
                style={disabled ? styles.disabled : styles.underline}
              >
                {rest.children}
              </Link>
            </li>
          );
        }}
      />
    );
  }
}

export default LiNavLink;
