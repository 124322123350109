import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const PasswordFlowReqTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"grant_type"}>
              <StyledTableCell>grant_type</StyledTableCell>
              <StyledTableCell>password (see also: OAuth 2.0 spec)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"scope"}>
              <StyledTableCell>scope (Optional)</StyledTableCell>
              <StyledTableCell>Authorized scope of Access Token</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"username"}>
              <StyledTableCell>username</StyledTableCell>
              <StyledTableCell>1 (“Test Individual Customer Numbers: 1, 2, 3, 4, 5, 6, 7” or Test Corporate Customer Number: 12)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"password"}>
              <StyledTableCell>password</StyledTableCell>
              <StyledTableCell>123456</StyledTableCell>
            </StyledTableRow>  
            <StyledTableRow key={"corporateUsername"}>
              <StyledTableCell>corporateUsername</StyledTableCell>
              <StyledTableCell>testUser1 (If you want to get token for corporate user, you have to pass "corporateUsername". It does not need for individiual customer.</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Authorization"}>
              <StyledTableCell>Authorization</StyledTableCell>
              <StyledTableCell>Required. in format of basic encoded Basic "client_id:client_secret". (see Basic Authentication https://tools.ietf.org/html/rfc7617)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Content-Type"}>
              <StyledTableCell>Content-Type</StyledTableCell>
              <StyledTableCell>application/x-www-form-urlencoded</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default PasswordFlowReqTable;
