import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const TakeAccessTokenTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"grant_type"}>
              <StyledTableCell>grant_type</StyledTableCell>
              <StyledTableCell>type “authorization_code”. (see OAuth 2.0 spec)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"code"}>
              <StyledTableCell>code</StyledTableCell>
              <StyledTableCell>The Authorization Code provided in second step</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"redirect_uri"}>
              <StyledTableCell>redirect_uri</StyledTableCell>
              <StyledTableCell>The redirect_uri passed in first step</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"state"}>
              <StyledTableCell>state</StyledTableCell>
              <StyledTableCell>Optional. This parameter will be passed to redirect uri</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Authorization"}>
              <StyledTableCell>Authorization (in Header)</StyledTableCell>
              <StyledTableCell>Basic "base64 encoded string" (clientid:clientsecret)</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default TakeAccessTokenTable;
