const styles = theme => ({
  label: {
    fontSize: '12px',
    fontFamily: 'Gilroy-Light',
    fontWeight: 'bolder',
    letterSpacing: '1px'
  }
});

export default styles;

