import React from 'react';
import PropTypes from 'prop-types';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

class MUTypography extends React.Component {

  render() {
    const { props } = this;

    return (
      <Typography
        component={props.component}
        variant = {props.variant}
        className={props.className}
        gutterBottom = {props.gutterBottom}
        noWrap = {props.noWrap}
        paragraph = {props.paragraph}
        classes = {props.classes}
      >
        {props.children}
      </Typography>
    );
  }
}

MUTypography.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  component: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  paragraph: PropTypes.bool

};

MUTypography.defaultProps = {
  gutterBottom: false,
  noWrap: false,
  paragraph: false
};

export default withStyles(styles)(MUTypography);
