import React from 'react';
import { ListItemSecondaryAction} from '@material-ui/core';
import MUSwitch from '../../ui/MUSwitch';
import MUTypography from '../../ui/MUTypography';
import MUList from '../../ui/MUList';
import MUListItem from '../../ui/MUListItem';
import MUListItemText from '../../ui/MuListItemText';

class APIsInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editPage: false,
      checked: [],
      readOnly: false
    };

    this.listItemClick = this.listItemClick.bind(this);
  }

  handleToggle = (e, value) => {
    e.preventDefault();
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checked: newChecked
    });

    this.props.handleToggle(newChecked);
  };

  componentWillMount() {
    if (this.props.location.state !== undefined && this.props.editapplication) {
      // state passed from history.push
      const { appId, applications, readOnly } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {
        console.log('product id : ' + value.id);
        if (value.id === appId) {
          this.setState({
            editPage: true,
            checked: value.productList,
            readOnly: readOnly
          });
          this.props.onAPIsInfoLoad(value.productList);
        }
      });
    }
  }

  listItemClick = (e, productId) => {
    this.handleToggle(e, productId);
  }

  render() {
    const { classes, products } = this.props;
    const { editPage, checked, readOnly} = this.state;

    return (
      <div id = 'apis'style = {{ display: 'flex', flexDirection: 'column', marginTop: '5%'}}>
        <div >
          <MUTypography className = {classes.header} variant="headline" component="h2">
            {editPage ? 'APIs Used' : 'Available APIs' }
          </MUTypography>
          <MUTypography variant="headline" component="p" className={classes.p}>
            Below are the APIs to power your app. Activate with a single click to access the API product.
          </MUTypography>
        </div>
        <br/>
        <div className="row">
          <MUList>
            {products ? products.productList.map(value => (
              <MUListItem
                key={value.id}
                dense = {true}
                button = {true}
                className={classes.listItem}
                onClick={(event) => this.listItemClick(event, value.id)}
              >
                <br/>
                <MUListItemText
                  primary={
                    <MUTypography className={classes.p} component="p">
                      {value.name}
                    </MUTypography>
                  }
                  secondary={value.summary}
                  className={classes.p}
                />
                <ListItemSecondaryAction>
                  <MUSwitch
                    onChange={(e) => this.handleToggle(e, value.id)}
                    checked={checked.indexOf(value.id) !== -1}
                    disabled = {readOnly}
                    classes = {{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      bar: classes.colorBar
                    }}
                  />
                </ListItemSecondaryAction>
              </MUListItem>
            )) : null}
          </MUList>
        </div>
      </div>
    );
  }
}
export default APIsInfo;
