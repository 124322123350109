import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ApiUsageResponseTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"data"}>
              <StyledTableCell>data</StyledTableCell>
              <StyledTableCell>The response of called api. See Api Products section for api specific response</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"header"}>
              <StyledTableCell>header</StyledTableCell>
              <StyledTableCell>Contains generic info about api call</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"message"}>
              <StyledTableCell>message</StyledTableCell>
              <StyledTableCell>Error message. On successful case "null"</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"status"}>
              <StyledTableCell>status</StyledTableCell>
              <StyledTableCell>Status of api call. “SUCCESS” or “ERROR”</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"statusCode"}>
              <StyledTableCell>statusCode</StyledTableCell>
              <StyledTableCell>Http status code</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default ApiUsageResponseTable;
