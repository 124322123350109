import React, { Component } from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import * as apiUserService from '../../services/confirm';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MURecaptcha } from 'basic-components';
import MUTextValidator from '../ui/MUTextValidator';
import Button from '../ui/Button';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import {RECAPTCHA_SITEKEY} from '../../config/environment.js';
import _ from 'lodash';
import { setNotification } from '../../redux/actions';
import { store } from '../../../src';
import BasicPageComponent from '../BasicPageComponent';
import NotificationPage from '../NotificationPage';
import Header from '../Header';
class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      captchaRendered: false,
      recaptchaResponse: '',
      beforeRegister: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    setTimeout(()=> {
      this.setState({captchaRendered: true});
    }, 500);
  }

  handleChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  handleSubmit = () => {
    window.grecaptcha.reset();
    this.resendActivationMail();
  }

  verifyCallback(response) {
    this.setState({recaptchaResponse: response});
  };

  resendActivationMail = () => {
    const { username, recaptchaResponse } = this.state;

    apiUserService.reactivate(username, recaptchaResponse)
      .then((responseJson) => {
        this.setState({ beforeRegister: false });
        console.log(responseJson);
      })
      .catch((exception) => {
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      })
      .finally(()=>{
        this.setState({ onServiceCall: false,
          recaptchaResponse: '' });
      });
  }
  render() {
    const { classes } = this.props;
    const { username, beforeRegister } = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        {beforeRegister ?
          <BasicPageComponent
            title='Activation E-Mail'
            description='To retrieve the activation e-mail, you should type e-mail information you used during the registration.'
            children = {

              <ValidatorForm
                onSubmit={this.handleSubmit}
                className= {classes.form}
              >
                <div className = {classes.textField}>
                  < MUTextValidator
                    id="username"
                    onChange={(e) => this.handleChange(e, 'username')}
                    name="username"
                    value={username}
                    placeholder = 'YOUR EMAIL'
                    validators={['required', 'isEmail', 'maxStringLength:50']}
                    errorMessages={['Required', 'Please enter a valid email address.', 'The maximum allowed length is 50']}
                    autoFocus = {true}
                  />
                </div>

                <div className={classes.textField}>
                  { this.state.captchaRendered ?
                    <MURecaptcha
                      verifyCallback={(response) => this.verifyCallback(response)}
                      sitekey = {RECAPTCHA_SITEKEY}
                    /> : null
                  }
                </div>

                <div className={ classes.btnSubmit}>
                  < Button
                    variant="outlined"
                    color='primary'
                    className='outlinedPrimary'
                    type="submit"
                    text="SEND"
                    disabled= {false}
                  />
                </div>

              </ValidatorForm>
            }
          /> :
          <NotificationPage
            title='your activation request has been received.'
            description={<h3 style={{textAlign: 'center', marginTop: '30px'}}>
            Click on the link sent at <span>{username}</span> to activate your account.</h3>}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Register);
