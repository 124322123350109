import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const RefreshTokenReqTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"grant_type"}>
              <StyledTableCell>grant_type</StyledTableCell>
              <StyledTableCell>Required. Always type "refresh_token". (see OAuth 2.0 spec)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"refresh_token"}>
              <StyledTableCell>refresh_token</StyledTableCell>
              <StyledTableCell>Required. The refresh token when you got together with access token</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Authorization"}>
              <StyledTableCell>Authorization</StyledTableCell>
              <StyledTableCell>Required. in format of "Basic auth". (see Basic Authentication https://tools.ietf.org/html/rfc7617)</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Content-Type"}>
              <StyledTableCell>Content-Type</StyledTableCell>
              <StyledTableCell>application/x-www-form-urlencoded</StyledTableCell>
            </StyledTableRow>
            </TableBody>
        </Table>;

export default RefreshTokenReqTable;
