import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.css';
import MUTypography from '../ui/MUTypography';
import { motion } from 'framer-motion';
import Button from '../ui/Button';
import { history } from '../../App';
import LiNavLink from '../LiNavLink';

class GettingStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreProduct: false,
            isHovered: false,
            isHoveredRegister: false,
            isHoveredCreateApp: false,
            isHoveredDevelopApp: false,
            isHoveredGoLive: false,
            windowWidth: window.innerWidth
        };
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };
    handleRegisterMouseEnter = () => {
        this.setState({ isHoveredRegister: true });
    };

    handleRegisterMouseLeave = () => {
        this.setState({ isHoveredRegister: false });
    };
    handleCreateAppMouseEnter = () => {
        this.setState({ isHoveredCreateApp: true });
    };

    handleCreateAppMouseLeave = () => {
        this.setState({ isHoveredCreateApp: false });
    };
    handleDevelopAppMouseEnter = () => {
        this.setState({ isHoveredDevelopApp: true });
    };

    handleDevelopAppMouseLeave = () => {
        this.setState({ isHoveredDevelopApp: false });
    };
    handleGoLiveMouseEnter = () => {
        this.setState({ isHoveredGoLive: true });
    };

    handleGoLiveMouseLeave = () => {
        this.setState({ isHoveredGoLive: false });
    };
    handleButtonMediumClick = () => {
        const url = 'https://medium.com/albaraka-open-banking';
        window.open(url, '_blank');
    };
    handleButtonSlackClick = () => {
        const url = 'https://join.slack.com/t/albarakaapi/shared_invite/zt-24pex8dlv-htHYqVb4SdZkM9~DI1fPWQ';
        window.open(url, '_blank');
    };
    handleButtonClick = () => {
        this.props.history.push('/gettingStarted');
    };
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    render() {
        const { classes } = this.props;
        const { isHovered, isHoveredRegister, isHoveredCreateApp, isHoveredDevelopApp, isHoveredGoLive } = this.state;
        const widthSize = this.state.windowWidth < 1130;
        return (
            <div >
                <div id='featureTitle' style={{ marginTop: 120, textAlign: 'center' }} >
                    <MUTypography className={classes.gettingStartedTitle}>Getting Started</MUTypography>
                </div>
                <div id='feature' style={{ marginTop: 56, textAlign: 'center' }} >
                    <div className={classes.screenContainer}>

                        <div style={{ padding: 20, maxWidth: '15%', maxWidth: 300, backgroundColor: 'white' }}>
                            <div style={{ backgroundColor: 'white', padding: 20 }}>
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        width: 'fit-content',
                                    }}
                                    initial={{ y: 0, opacity: 1 }}
                                    whileHover={{ y: -50, }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div style={{ paddingBottom: 16 }}>
                                        <div
                                            style={{
                                                backgroundColor: isHovered ? '#0076FF' : '#F4F5F6',
                                                display: 'flex',
                                                width: '48px',
                                                height: '48px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '40px',
                                                background: '#F4F5F6',
                                            }}
                                            onMouseEnter={this.handleMouseEnter}
                                            onMouseLeave={this.handleMouseLeave}
                                        >
                                            <span style={{
                                                color: isHovered ? 'white' : '#4C5068',
                                                textAlign: 'center',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                fontFamily: 'Gilroy',
                                                fontSize: 24,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: 'normal',
                                                margin: 'auto',
                                            }}>1</span>
                                        </div>
                                        <br></br>
                                        <span style={{
                                            color: isHovered ? '#0076FF' : '#4C5068',
                                            fontFamily: 'Gilroy',
                                            fontSize: '32px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            letterSpacing: '-0.608px',
                                            display: 'block',
                                            textAlign: 'left',
                                        }} onMouseEnter={this.handleMouseEnter}
                                            onMouseLeave={this.handleMouseLeave}>
                                            Discover
                                            <br />
                                            Our APIs
                                        </span>
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                backgroundColor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                            onMouseEnter={this.handleMouseEnter}
                                            onMouseLeave={this.handleMouseLeave}
                                        >
                                            <ul>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Visit our <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/productCard'}>Discover APIs</span><br></br>
                                                        page to find the APIs that<br></br> are suitable for you.</MUTypography>
                                                </li>
                                            </ul>
                                        </div>


                                    </div>

                                </motion.div>
                            </div>
                        </div>

                        <div style={{ padding: 20, maxWidth: '15%', maxWidth: 300, backgroundColor: 'white' }}>
                            <div style={{ padding: 20, backgroundColor: 'white' }}>
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        width: 'fit-content',
                                    }}
                                    initial={{ y: 0, opacity: 1 }}
                                    whileHover={{ y: -50, }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div >
                                        <div
                                            style={{
                                                backgroundColor: isHoveredRegister ? '#0076FF' : '#F4F5F6',
                                                display: 'flex',
                                                width: '48px',
                                                height: '48px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '40px',
                                                background: '#F4F5F6',
                                            }}
                                            onMouseEnter={this.handleRegisterMouseEnter}
                                            onMouseLeave={this.handleRegisterMouseLeave}
                                        >
                                            <span style={{
                                                color: isHoveredRegister ? 'white' : '#4C5068',
                                                textAlign: 'center',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                fontFamily: 'Gilroy',
                                                fontSize: 24,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: 'normal',
                                                margin: 'auto',
                                            }}>2</span>
                                        </div>
                                        <br></br>

                                        <span style={{
                                            color: isHoveredRegister ? '#0076FF' : '#4C5068',
                                            fontFamily: 'Gilroy',
                                            fontSize: '32px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            letterSpacing: '-0.608px',
                                            display: 'block',
                                            textAlign: 'left',
                                        }} onMouseEnter={this.handleRegisterMouseEnter}
                                            onMouseLeave={this.handleRegisterMouseLeave}>
                                            Register
                                            <br />
                                            On Site
                                        </span>
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                backgroundColor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                            onMouseEnter={this.handleRegisterMouseEnter}
                                            onMouseLeave={this.handleRegisterMouseLeave}
                                        >
                                            
                                            <ul>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Create a developer<br></br>account from <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/register'}>Register </span>on<br></br>
                                                        the Homepage to use<br></br>APIs.</MUTypography>
                                                </li>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Activate your account<br></br>with the activation link<br></br>sent to your email.
                                                    </MUTypography>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </motion.div>
                            </div>
                        </div>
                        <div style={{ padding: 20, maxWidth: '15%', maxWidth: 300, backgroundColor: 'white' }}>
                            <div style={{ padding: 20, backgroundColor: 'white' }}>
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        width: 'fit-content',
                                    }}
                                    initial={{ y: 0, opacity: 1 }}
                                    whileHover={{ y: -50, }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div >
                                        <div
                                            style={{
                                                backgroundColor: isHoveredCreateApp ? '#0076FF' : '#F4F5F6',
                                                display: 'flex',
                                                width: '48px',
                                                height: '48px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '40px',
                                                background: '#F4F5F6',
                                            }}
                                            onMouseEnter={this.handleCreateAppMouseEnter}
                                            onMouseLeave={this.handleCreateAppMouseEnter}
                                        >
                                            <span style={{
                                                color: isHoveredCreateApp ? 'white' : '#4C5068',
                                                textAlign: 'center',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                fontFamily: 'Gilroy',
                                                fontSize: 24,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: 'normal',
                                                margin: 'auto',
                                            }}>3</span>
                                        </div>
                                        <br></br>

                                        <span style={{
                                            color: isHoveredCreateApp ? '#0076FF' : '#4C5068',
                                            fontFamily: 'Gilroy',
                                            fontSize: '32px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            letterSpacing: '-0.608px',
                                            display: 'block',
                                            textAlign: 'left',
                                        }} onMouseEnter={this.handleCreateAppMouseEnter}
                                            onMouseLeave={this.handleCreateAppMouseLeave}>
                                            Create Apps
                                            <br />
                                            And Use APIs
                                        </span>
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                backgroundColor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                            onMouseEnter={this.handleCreateAppMouseEnter}
                                            onMouseLeave={this.handleCreateAppMouseLeave}
                                        >
                                            <ul>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Click on the New<br></br> Application button on the<br></br>Dashboard page.</MUTypography>
                                                </li>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Fill out the required fields.
                                                    </MUTypography>
                                                </li>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Activate apis required for<br></br> your application and click<br></br> to add button
                                                    </MUTypography>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </motion.div>
                            </div>
                        </div>
                        <div style={{ padding: 20, maxWidth: '15%', maxWidth: 300, backgroundColor: 'white' }}>
                            <div style={{ padding: 20, backgroundColor: 'white' }}>
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        width: 'fit-content',
                                    }}
                                    initial={{ y: 0, opacity: 1 }}
                                    whileHover={{ y: -50, }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div >
                                        <div
                                            style={{
                                                backgroundColor: isHoveredDevelopApp ? '#0076FF' : '#F4F5F6',
                                                display: 'flex',
                                                width: '48px',
                                                height: '48px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '40px',
                                                background: '#F4F5F6',
                                            }}
                                            onMouseEnter={this.handleDevelopAppMouseEnter}
                                            onMouseLeave={this.handleDevelopAppMouseLeave}
                                        >
                                            <span style={{
                                                color: isHoveredDevelopApp ? 'white' : '#4C5068',
                                                textAlign: 'center',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                fontFamily: 'Gilroy',
                                                fontSize: 24,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: 'normal',
                                                margin: 'auto',
                                            }}>4</span>
                                        </div>
                                        <br></br>

                                        <span style={{
                                            color: isHoveredDevelopApp ? '#0076FF' : '#4C5068',
                                            fontFamily: 'Gilroy',
                                            fontSize: '32px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            letterSpacing: '-0.608px',
                                            display: 'block',
                                            textAlign: 'left',
                                        }} onMouseEnter={this.handleDevelopAppMouseEnter}
                                            onMouseLeave={this.handleDevelopAppMouseLeave}>
                                            Develop
                                            <br />
                                            Your App
                                        </span>
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                backgroundColor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                            onMouseEnter={this.handleDevelopAppMouseEnter}
                                            onMouseLeave={this.handleDevelopAppMouseLeave}
                                        >
                                            <ul>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        Develop your application<br>
                                                        </br> using sandbox<br>
                                                        </br> environment with<br>
                                                        </br> simulated data safely test<br></br> API functionality.</MUTypography>                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </motion.div>
                            </div>
                        </div>
                        <div style={{ padding: 20, maxWidth: '15%', maxWidth: 300, backgroundColor: 'white' }}>
                            <div style={{ padding: 20, backgroundColor: 'white' }}>
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        cursor: 'pointer',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        width: 'fit-content',
                                    }}
                                    initial={{ y: 0, opacity: 1 }}
                                    whileHover={{ y: -50, }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div >
                                        <div
                                            style={{
                                                backgroundColor: isHoveredGoLive ? '#0076FF' : '#F4F5F6',
                                                display: 'flex',
                                                width: '48px',
                                                height: '48px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '40px',
                                                background: '#F4F5F6',
                                            }}
                                            onMouseEnter={this.handleGoLiveMouseEnter}
                                            onMouseLeave={this.handleGoLiveMouseLeave}
                                        >
                                            <span style={{
                                                color: isHoveredGoLive ? 'white' : '#4C5068',
                                                textAlign: 'center',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                fontFamily: 'Gilroy',
                                                fontSize: 24,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: 'normal',
                                                margin: 'auto',
                                            }}>5</span>
                                        </div>
                                        <br></br>

                                        <span style={{
                                            color: isHoveredGoLive ? '#0076FF' : '#4C5068',
                                            fontFamily: 'Gilroy',
                                            fontSize: '32px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            letterSpacing: '-0.608px',
                                            display: 'block',
                                            textAlign: 'left',
                                        }} onMouseEnter={this.handleGoLiveMouseEnter}
                                            onMouseLeave={this.handleGoLiveMouseLeave}>
                                            Then
                                            <br />
                                            Go Live
                                        </span>
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                backgroundColor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                            onMouseEnter={this.handleGoLiveMouseEnter}
                                            onMouseLeave={this.handleGoLiveMouseLeave}
                                        >
                                            <ul>
                                                <li>
                                                    <MUTypography className={classes.gettingStartedTypography}>
                                                        When you are confident<br></br>
                                                        that your application is<br></br>
                                                        working as desired, you<br></br>
                                                        can request access by <br></br>
                                                        contacting us from the <br></br>
                                                        contact us section on the <br></br>
                                                        support page.
                                                    </MUTypography>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id='gettingStartBtn' style={{ marginTop: 120, textAlign: 'center' }} >
                    <Button
                        variant='text'
                        classes={{ root: classes.getStartBtn, label: classes.getStartBtnTxt }}
                        text="LET'S GET START"
                        onClick={() => { window.location.href = '/gettingStarted' }}
                    >
                    </Button>
                </div>
                <div style={{ marginTop: 72, position: 'relative' }}>
                    <img id="maviResim" src={require('../../images/letsbuild.png')} width="100%" alt="Resim" />
                    <div
                        style={{
                            position: 'absolute',
                            top: widthSize ? '30%' : '50%',
                            left: '50%',
                            transform: 'translate(-50%, -200%)',
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: 'Gilroy',
                            fontSize: widthSize ? 20 : 44,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                            letterSpacing: '-1.239px',
                        }}
                    >
                        Let’s build together!
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFF',
                        textAlign: 'center',
                    }}>
                        <p style={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: 'Gilroy-Light',
                            fontSize: widthSize ? '10.2px' : '16.2px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: widthSize ? '15.8px' : '28.8px',
                            letterSpacing: '-0.456px'
                        }}>
                            We are deeply committed to providing unwavering support to our users trough the Albaraka API platform, offering a continuous stream of innovative updates, invaluable usage recommendations, and profound insights derived from the dynamic realm of open banking.

                        </p>

                    </div>
                    <div>
                        <div id='joinOurCommunity' style={{
                            position: 'absolute',
                            top: widthSize ? '70%' : '60%',
                            left: '30%',
                            color: '#FFF',
                        }} >
                            <Button
                                variant='text'

                                style={{
                                    width: '225px',
                                    height: '30px',
                                    borderRadius: '32px',
                                    border: '1px solid white',
                                    color: 'white',
                                    backgroundColor: '#0076FF',
                                    textAlign: 'center',
                                    fontFamily: 'Gilroy',
                                    fontSize: widthSize ? '11px !important' : '22px !important',
                                    lineHeight: widthSize ? '15.8px' : '28.8px',
                                    padding: '12px 32px',
                                    color: 'white'

                                }}
                                text="JOIN OUR COMMUNITY"
                                onClick={this.handleButtonSlackClick}

                            />
                            <div>
                                <img src={require('../../images/join.png')} alt="Icon" style={{
                                    position: 'absolute',
                                    top: '20%',
                                    left: '5%',
                                }} />
                            </div>
                        </div>
                        <div id='joinOurCommunity' style={{
                            position: 'absolute',
                            top: widthSize ? '90%' : '60%',
                            left: widthSize ? '30%' : '50%',
                            color: '#FFF',
                        }} >

                            <Button
                                variant='text'
                                classes={{ root: classes.letsStartBtn, label: classes.getStartBtnTxt }}
                                text="OUR MEDIUM BLOG"
                                onClick={this.handleButtonMediumClick}
                            />
                            <div>
                                <img src={require('../../images/medium.png')} alt="Icon" style={{
                                    position: 'absolute',
                                    top: '20%',
                                    left: '5%',
                                }} />
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        );
    }
}

GettingStarted.propTypes = {
    login: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(GettingStarted);
