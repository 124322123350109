import { fork, all } from 'redux-saga/effects';
import {productsRootSaga} from './products';
// TODO: nadir login saga dışarı alınmalı
import loginSaga from '../components/Login/saga';

export default function * root() {
  yield all([
    fork(productsRootSaga),
    fork(loginSaga)
  ]);
}
