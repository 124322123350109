const styles = theme => ({
  container: {
    backgroundColor: '#FFFFF',
    height: '699px',
    position: 'relative',
    zIndex: 1
  },
  imgBack: {
    position: 'absolute',
    zIndex: 2,
    left: '30%',
    top: '0em',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%'
  },
  imgFront: {
    position: 'absolute',
    zIndex: 3,
    width: '30%',
    left: '15%',
    top: '15%',
    height: '37em'
  },
  txtBackImage: {
    position: 'absolute',
    zIndex: 4,
    left: '25%',
    top: '18%',
    width: '20%',
    height: '60%'

  },
  title: {
    fontFamily: 'Gilroy',
    fontSize: '44px',
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.2px',
    color: '#ffffff'
  },
  description: {
    marginTop: '15%',
    fontFamily: 'Gilroy-Light',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: '-0.4px',
    color: '#ffffff'
  },
  button: {
    width: '180px',
    height: '42px',
    borderRadius: '24px',
    border: 'solid 2px #ffffff'
  },
  txtFindMore: {
    marginTop: '15%',
    fontFamily: 'Gilroy-Light',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff'
  },
  label: {
    fontSize: '14px'
  }

});

export default styles;

