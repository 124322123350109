import React from 'react';
import PropTypes from 'prop-types';
import {Collapse} from '@material-ui/core/';

class MUCollapse extends React.Component {

  render() {
    const { props } = this;

    return (
      <Collapse
        in= {props.in}
        timeout = {props.timeout}
        unmountOnExit = {props.unmountOnExit}
        classes = {props.classes}
      >
        {props.children}
      </Collapse>
    );
  }
}

MUCollapse.propTypes = {

  in: PropTypes.bool,
  timeout: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  unmountOnExit: PropTypes.bool
};

MUCollapse.defaultProps = {
  in: false,
  unmountOnExit: false
};

export default MUCollapse;
