import * as actionTypes from '../constants/actionTypes';
import {
  fromJS
} from 'immutable';
import moment from 'moment';

const initialState = fromJS({
  username: undefined,
  password: undefined,
  progressState: false,
  persistExpiresAt: moment().add(1, 'minutes').toDate()
});

export default function loginReducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return state.merge({
        username: action.username,
        password: action.password,
        recaptchaResponse: action.recaptchaResponse,
        progressState: true
      });
    case actionTypes.LOGIN_USER_SUCCESS:
      return state.merge({
        token: action.token,
        password: undefined,
        progressState: false
      });
    case actionTypes.LOGIN_USER_ERROR:
      return state.merge({
        username: undefined,
        password: undefined,
        token: undefined,
        progressState: false
      });
    case actionTypes.LOGOUT_USER:
      return state.merge({
        username: undefined,
        password: undefined,
        token: undefined,
        tokenError: undefined,
        timeoutId: undefined
      });
    case actionTypes.TOKEN_REFRESH_SUCCESS:
      return state.merge({
        token: action.token
      });
    case actionTypes.TOKEN_REFRESH_ERROR:
      return state.merge({
        username: undefined,
        token: undefined,
        tokenError: action.error
      });
    case actionTypes.TIMEOUT_CREATE:
      return state.merge({
        timeoutId: action.timeoutId
      });
    case actionTypes.CLEAR_LOGIN:
      return initialState;
    default:
      return state;
  }

}

