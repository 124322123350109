import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const AuthorizationIdTokenResponseTable = () => 
        <Table aria-label="customized table" style={{ width: 710 }}>
        <TableHead sx={{ width: 710 }}>
          <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
            <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
          </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"access_token"}>
              <StyledTableCell>access_token</StyledTableCell>
              <StyledTableCell>An authorized token to call Baas APIs</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"token_type"}>
              <StyledTableCell>token_type</StyledTableCell>
              <StyledTableCell>always “bearer”</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"expires_in"}>
              <StyledTableCell>expires_in</StyledTableCell>
              <StyledTableCell>Expiration time of access_token (seconds)</StyledTableCell>
            </StyledTableRow>  
            <StyledTableRow key={"scope"}>
              <StyledTableCell>scope</StyledTableCell>
              <StyledTableCell>Authorized scope of Access Token</StyledTableCell>
            </StyledTableRow>  
          </TableBody>
        </Table>;

export default AuthorizationIdTokenResponseTable;
