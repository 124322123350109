const styles = theme => ({
  menuList: {
    backgroundColor: 'rgba(255, 255, 255, 0.62)',
    width: '100%',
    height: '172px'
  },
  content: {

    width: '75%'
  },
  selected: {
    backgroundColor: '#50e3c2 !important',
    '& $primary, & $icon': {
      color: theme.palette.common.white,
      fontWeight: '900',
      fontFamily: 'Gilroy-Light',
      fontSize: '12px'
    }
  },
  primary: {
    color: theme.palette.common.black,
    fontWeight: '900',
    fontFamily: 'Gilroy-Light',
    fontSize: '12px'
  },
  icon: {}

});

export default styles;
