import * as constants from '../../constants';
import * as actionTypes from '../../constants/actionTypes';

const { DocsMainContentType } = constants;

export default function contentType(state = DocsMainContentType.INFO, action) {
  switch (action.type) {
    case actionTypes.SWITCH_DOCS_MAIN_CONTENT_TO_INFO:
      return DocsMainContentType.INFO;
    case actionTypes.SWITCH_DOCS_MAIN_CONTENT_TO_SWAGGER:
      return DocsMainContentType.SWAGGER;
    default:
      return state;
  }

}

