import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import {Info1, Info2, Info3} from './Main/Static';
import ApiInfo from './Main/Dynamic/ApiInfo';

// const RendererContainer = connect(null, null);

// const ReduxWrapperComponent = (props) => <div>{RendererContainer(props.component)}</div>;

// HOC declaration
const ReduxWrapperComponent = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  }

  return HOC;
};

const Info = props => {
  return (
    <div>
      {{
        Info1: <Info1 {...props} />,
        Info2: <Info2 {...props} />,
        Info3: <Info3 {...props} />,
        ApiInfo: <ApiInfo {...props}/>
      }[props.infoContent.component]}
    </div>
  );
};

// (
//   <Info1 {...props}/>
// );

const WrappedComponent = ReduxWrapperComponent(Info);

export default withStyles(styles)(WrappedComponent);

