const styles = theme => ({
    gettingStartedTypography: {
        color: '#0F1120',
        textAlign: 'center',
        fontFamily: 'Gilroy',
        fontSize: 44,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '-2.239px'
    },
    screenContainer: {
        margin: 'auto',
        display: 'flex',
        padding: ' 30px 0',
        fontFamily: 'Gilroy',
        alignContent: 'space-around',
        paddingBottom: 0,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start'
    },
    getStartBtn: {
        width: '182px',
        height: '44px',
        flexShrink: '0',
        borderRadius: '24px',
        border: '2px solid white',
        color: 'white',
        backgroundColor: '#0076FF',
        textAlign: 'center',
        fontFamily: 'Gilroy',
        fontSize: '14px !important',
        lineHeight: 'normal',
    },
    letsStartBtn: {
        width: '225px',
        height: '30px',
        borderRadius: '32px',
        border: '1px solid white',
        color: 'white',
        backgroundColor: '#0076FF',
        textAlign: 'center',
        fontFamily: 'Gilroy',
        fontSize: '22px !important',
        lineHeight: '28.8px',
        padding: '12px 32px',
    },
    getStartBtnTxt: {
        color: 'white',
    },
    developerBlogTitle: {
        color: '#FFF',
        textAlign: 'center',
        paddingTop: '150px',
        fontFamily: 'Gilroy',
        fontSize: '44px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28.8px',
        letterSpacing: '-0.456px',
    },
    developerBlogText: {
        color: '#FFF',
        textAlign: 'center',
        padding: '40px',
        fontFamily: 'Gilroy-Light',
        fontSize: '19px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28.8px',
        letterSpacing: '-0.456px',
    },
    showMoreButton: {
        width: '240px',
        height: '56px',
        borderRadius: '32px',
        backgroundColor: 'white',
        textAlign: 'center',
        fontFamily: 'Gilroy',
        fontSize: '14px !important',
        lineHeight: 'normal',
    },
    showMoreBtnTxt: {
        color: '#0076FF',
        textAlign: 'center',
        fontFamily: 'Gilroy',
    },
    centeredText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#FFF',
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    imageContainer: {
        position: 'relative',
        width: '70%',
        marginLeft: '15%',
    },
    footerTypography: {
        color: '#4C5068',
        paddingTop: 112,
        textAlign: 'center',
        fontFamily: 'Gilroy',
        fontSize: '44px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '49.8px',
        letterSpacing: '-0.456px',
    },
    footerTypographyText: {
        color: '#8B96A4',
        padding: 40,
        textAlign: 'center',
        fontFamily: 'Gilroy-Light',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28.8px',
        letterSpacing: '-0.456px',
    },
    typography: {
        color: '#0076FF',
        textAlign: 'center',
        paddingBottom:10,
        fontFamily: 'Gilroy',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '-0.608',
    },
    typographyText: {
        color: '#4C5068',
        textAlign: 'center',
        fontFamily: 'Gilroy-Light',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '-0.608',
    }
});

export default styles;
