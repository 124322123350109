import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const TakeAccessTokenFailResponse = () => 
    <Table aria-label="customized table" style={{ width: 50 }}>
          <TableHead sx={{ width: 50 }}>
            <TableRow>
              <StyledTableCell style={{fontSize:16, width: 25}}>Parameter</StyledTableCell>
              <StyledTableCell style={{fontSize:16, width: 25}}>Explanation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"status"}>
              <StyledTableCell>status</StyledTableCell>
              <StyledTableCell>Http status code</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"error"}>
              <StyledTableCell>error</StyledTableCell>
              <StyledTableCell>Error code</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"message"}>
              <StyledTableCell>message</StyledTableCell>
              <StyledTableCell>Error description</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default TakeAccessTokenFailResponse;
