import * as actionTypes from '../../constants/actionTypes';
import * as infoContentType from '../../constants/docsInfoContentTypes';

export default function infoContent(
  state = {
    content: infoContentType.docsInfoDefaultContent.info,
    component: infoContentType.docsInfoDefaultContent.component,
    isstatic: true},
  action) {
  switch (action.type) {
    case actionTypes.SET_DOCS_API_INFO:
      return {
        content: action.info,
        component: action.component,
        isstatic: action.isstatic
      };
    default:
      return state;
  }

}

