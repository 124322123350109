import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import { InputAdornment } from '@material-ui/core/';
import _ from 'lodash';
class MUTextValidator extends React.Component {

  render() {
    const {classes} = this.props;

    return (
      <TextValidator
        id={this.props.id}
        label={this.props.label}
        onChange={this.props.onChange}
        name={this.props.name}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.root
          }
        }}
        InputProps={{
          classes: {
            label: classes.label,
            input: classes.input
          },
          startAdornment: (
            <InputAdornment position="start">
              {this.props.icon}
            </InputAdornment>
          ),
          endAdornment: (_.isNil(this.props.endAdornmentIcon) ? null :
            <InputAdornment classes = {this.props.inputAdornmentClasses} position="end">
              {this.props.endAdornmentIcon}
            </InputAdornment>
          ),
          disabled: (this.props.disabled),
          disableUnderline: true
        }
        }
        className={this.props.className}
        value={this.props.value}
        validators={this.props.validators}
        errorMessages={this.props.errorMessages}
        multiline={this.props.multiline}
        type={this.props.type}
        placeholder={this.props.placeholder}
        autoFocus = {this.props.autoFocus}
        rows = {this.props.rows}
        margin = {this.props.margin}
        variant = {this.props.variant}
        select = {this.props.select}
      />
    );
  }
}

MUTextValidator.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
  endAdornmentIcon: PropTypes.element,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  errorMessages: PropTypes.array.isRequired,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default withStyles(styles)(MUTextValidator);
