import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../style.js';
import { withStyles } from '@material-ui/core/styles';
import {docsInfoStaticContentScreens} from '../../../../../redux/constants/docsInfoContentTypes';
// import { MUList, MUListItem, MUListItemText} from 'basic-components';
import MUList from '../../../../ui/MUList';
import MUListItem from '../../../../ui/MUListItem';
import MUListItemText from '../../../../ui/MuListItemText';

class InfoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent: ''
    };
  }

  handleClick = (e, info) => {
    this.props.switchDocsMainContentToInfo();
    this.props.setDocsApiInfo(info, info, true);
    this.setState({
      selectedComponent: info
    });
  };
  componentWillMount() {
    let isLinked = false;

    if (this.props.location.state !== undefined) {
      isLinked = this.props.location.state;
    }

    if (!isLinked) {
      this.props.switchDocsMainContentToInfo();
      this.props.setDocsApiInfo('Info1', 'Info1', true);
      this.setState({
        selectedComponent: 'Info1'
      });
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <div >
        <MUList
          className={classes.list}
          component="nav">
          {docsInfoStaticContentScreens.map(screen =>
            <MUListItem
              key={screen.component}
              button={true}
              selected = {this.state.selectedComponent === screen.component && this.props.isStatic}
              onClick={(e) => this.handleClick(e, screen.component)}
              classes ={{root: classes.listItem,
                selected: classes.selected}}>
              <MUListItemText
                inset = {false}
                classes={{primary: classes.listItemText}}
                primary={screen.display} />
            </MUListItem>
          )}
        </MUList>
      </div>
    );
  }
}

InfoSection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InfoSection);
