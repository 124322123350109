import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../style.js';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ProductGroup from '../ProductGroup';
import { docsInfoDynamicContentScreen } from '../../../../../redux/constants/docsInfoContentTypes';
// import {MUList, MUListItem, MUListItemText, MUCollapse} from 'basic-components';
import MUList from '../../../../ui/MUList';
import MUListItem from '../../../../ui/MUListItem';
import MUListItemText from '../../../../ui/MuListItemText';
import MUCollapse from '../../../../ui/MUCollapse';
import _ from 'lodash';
var localStorageApiValue = localStorage.getItem('apiDocs');
var localStorageBaasValue = localStorage.getItem('baasDocs');

class ProductTab extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: (localStorage.getItem('apiDocs') === this.props.id || localStorage.getItem('baasDocs') === this.props.id || this.props.products.infoContent.content === this.props.id || this.props.list.some(x => x.id === this.props.products.infoContent.content))
    };
  }

  handleClick = (e, id) => {
    // localStorage'dan değeri al

    this.props.switchDocsMainContentToInfo();
    this.props.setDocsApiInfo(id, null, false);

    this.setState(prevState => ({
      open: prevState.open === true && (localStorage.getItem('apiDocs') === id || localStorage.getItem('baasDocs') === id ||
        this.props.products.infoContent.content === id || this.props.list.some(x => x.id === this.props.products.infoContent.content)) ? false : true
    }));
    console.log("clicked id", id);
    console.log("localStorageApiValue", localStorage.getItem('apiDocs'));
    console.log("localStorageBaasValue", localStorage.getItem('baasDocs'));

  };

  render() {
    const { classes, list, name, id } = this.props;
    const { open } = this.state;
    console.log("localStorageApiValue", localStorage.getItem('apiDocs'));
    console.log("localStorageBaasValue", localStorage.getItem('baasDocs'));

    return (
      <div>
        <MUList
          className={classes.list}
          component="nav"
        >
          <MUListItem
            button={true}
            selected={(localStorage.getItem('apiDocs') === id ||localStorage.getItem('baasDocs') === id ||this.props.products.infoContent.content === id || list.some(x => x.id === this.props.products.infoContent.content))
            }
            onClick={(e) => this.handleClick(e, id)}
            classes={{
              root: classes.listItem,
              selected: classes.selected
            }}>
            <MUListItemText
              inset={false}
              classes={{ primary: classes.listItemText }}
              primary={name} />
            {(open && (localStorage.getItem('apiDocs') === id ||localStorage.getItem('baasDocs') === id ||this.props.products.infoContent.content === id || list.some(x => x.id === this.props.products.infoContent.content))
            ) ? <ExpandLess /> : <ExpandMore />}
          </MUListItem>

          {_.isNil(list) ? null :
            <MUCollapse
              in={open && (localStorage.getItem('apiDocs') === id ||localStorage.getItem('baasDocs') === id ||this.props.products.infoContent.content === id || list.some(x => x.id === this.props.products.infoContent.content))
              }
              timeout="auto"
              unmountOnExit={true}
              classes={{ entered: classes.collapse }} >
              {list.map(group => (
                <div key={group.id}>
                  <ProductGroup {...this.props} group={group} />
                </div>)
              )}
            </MUCollapse>
          }
        </MUList>
      </div>
    );
  }
}

ProductTab.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductTab);
