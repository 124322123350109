import React from 'react';
import MUTypography from '../../../../../ui/MUTypography';
import CodeBlock from '@tenon-io/tenon-codeblock';
import './styles.css';
import sample  from './Texts.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {ApiUsageResponseTable, ApiUsageRequestTable, OTPMessageTable, MobileConfirmationTable, ApiUsageRequestMobileConfTable} from './index';

const Info3 = (props) =>
  <div>
    <MUTypography variant='h4' className={props.classes.h4}>
    API Usage
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      "{<a href='https://apitest.albarakaturk.com.tr/api' style = {{fontWeight: 'bold'}}>https://apitest.albarakaturk.com.tr/api</a>}" is the base url for api calls.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      Sample Request Endpoint;  "{<a href='https://apitest.albarakaturk.com.tr/api/accounts/v1/list' style = {{fontWeight: 'bold'}}>https://apitest.albarakaturk.com.tr/api/accounts/v1/list</a>}"<br></br>
    </MUTypography>
    <br/>
    <MUTypography variant='h5' paragraph={true} className={props.classes.h5}>
    OTP Free Calls
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      
    Each product has a specific endpoint and it should be added to base url while making a request.<br></br>
    You’ll get response on Otp free apis.
    <br></br>
    </MUTypography>

    <MUTypography variant='h5' paragraph={true} className={props.classes.h5}>
    OTP Required Calls
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    The customer can use sms or mobile confirmation login method while logging in via api banking.<br></br>
    Whichever login method was used when logged in, the same method must be used in the api call.
    </MUTypography>
    <br></br>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    For example: If you log in with sms, sms will be used in the API call,<br></br>
    and if you log in with mobile confirmation, mobile confirmation will be used in the API call.
    </MUTypography>
    <MUTypography variant='h6' className={props.classes.h6}>
        Sms Flow
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Some of Albaraka APIs require otp(one time password) to complete call.<br></br>
    If your login type is "Sms" and you call an api, you will get "transaction_id" and "authenticationType" in the response.
    </MUTypography>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Sample response:
    </MUTypography>
    <div style={{ width: 710 }}>
    <CodeBlock
              language="html"
              codeString={sample['transactionIdResp']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    At the same time, user will be provided an otp message via sms.
    </MUTypography>
    <br></br>
    <OTPMessageTable/>
    <MUTypography variant='h6' className={props.classes.h6}>
        Mobile Confirmation Flow
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Mobile Confirmation is a method that enables us to log in to our internet branch and confirm transactions by confirming the notification (push notification)
    without entering any password on Albaraka Mobile instead of generating an SMS password or one-time password.<br></br>
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Some of Albaraka APIs require otp(one time password) to complete call.<br></br>
    If your login type is "Mobile Confirmation" and you call an api, you will get "transaction_id" , "authenticationType" , "activationId" and "requestId" in the response.
    </MUTypography>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Sample response:
    </MUTypography>
    <div style={{ width: 710 }}>
    <CodeBlock
              language="html"
              codeString={sample['mobileConfirmationResp']} />
    </div>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    At the same time, user will be provided an push notification via recorded phone.
    </MUTypography>
    <br></br>
    <MobileConfirmationTable/>
    <br></br>
    <br></br>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    To complete the API call process;<br></br>
    Depending on your login type, "sms" or "mobile confirmation" steps should be applied, and the required fields should be sent to the Execute Service below.<br></br>
    This endpoint is fixed for all otp required Albaraka APIs.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    <img src= {require('./postIcon.png')} width="70" alt="gh-pages branch setting"></img>
      <b>&nbsp;&nbsp;&nbsp;https://apitest.albarakaturk.com.tr/api/execute/</b>
    </MUTypography>
    <br></br>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    If your flow is "Sms", your body & headers parameters should be like below:
    </MUTypography>
    <br></br>
    <ApiUsageRequestTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Sample request:
    </MUTypography>
    <br></br>
    <div style={{ width: 710 }}>
    <CodeBlock
              language="html"
              codeString={sample['transactionIdReq']} />
    </div>
    <br></br>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    If your flow is "Mobile Confirmation", your body & headers parameters should be like below:
    </MUTypography>
    <br></br>
    <ApiUsageRequestMobileConfTable/>
    <MUTypography variant='h6' paragraph={true} className={props.classes.h6}>
    Sample request:
    </MUTypography>
    <br></br>
    <div style={{ width: 710 }}>
    <CodeBlock
              language="html"
              codeString={sample['mobileConfirmationReq']} />
    </div>
    <br/>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    On a successful execute call, execution of api will be completed and api specific response will be returned.<br></br>
    (also see responses : Response Object, How it works/Api )
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
    Response Object
    </MUTypography>
    <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
    Albaraka APIs has a standard JSON response object as shown below.
    </MUTypography>
    <br/>
    <div style={{ width: 710 }}>
      <CodeBlock
            language="html"
            codeString={'\{ "data"\: \{\},"header"\: \{"message"\: ,"status"\: "statusCode"\: ,"detailCode"\: ,"warnings": [],\} \}'} />
      <br/>
    </div>
      <MUTypography variant='display1' paragraph={true} className={props.classes.paragraph}>
      <ApiUsageResponseTable/>
      <br/>
    </MUTypography>
    <MUTypography variant='h5' className={props.classes.h5}>
    Signing Requests
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    In order to call private apis you must sign your request with RSA Encryption.<br></br>
    RSA Key size should be 1024 bit.<br></br>
    Public key should be saved on application creation stage.<br></br>
    Fields must be sign with private key as shown below and signature should be placed in header with ‘sign’ key.
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    Please define your keys without the "Begin" and "End" terms.
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    POST : token data and body
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    GET : token data and query string
    </MUTypography>
    <MUTypography variant='h6' className={props.classes.h6}>
    Sample Signing Method
    </MUTypography>
    <div className="demo">
    <Tabs forceRenderTabPanel defaultIndex={1}>
      <TabList>
        <Tab style={{fontSize:20}}>Java</Tab>
        <Tab style={{fontSize:20}}>C#</Tab>
      </TabList>
      <TabPanel>
        <CodeBlock
              language="html"
              codeString={sample['java']} />
      </TabPanel>
      <TabPanel>
        <CodeBlock
              language="html"
              codeString={sample['csharp']} />
      </TabPanel>
  </Tabs>          
    </div>
    <br/>
    <MUTypography variant='h5' className={props.classes.h5}>
      Signing Response
    </MUTypography>
    <br/>
    <MUTypography variant='display1' className={props.classes.paragraph}>
      In api calls, you can sign the responses.<br></br>
      If you want to sign service responses, you must pass "isResponseSigned : true" in the request header when making service requests.<br></br>
      You can get the signed response information from the "signedResponse" field in the response header.
      And if you want to get the decrypted version of the signed data, you can parse the signature with the public key below.<br></br>
      The response is signed with SHA256withRSA and the public key is in RSA PKCS8 format.<br></br>
    </MUTypography>
    <br></br>
    <div style={{ width: 710 }}>
    <CodeBlock
      language="html"
      codeString={sample['signResponsePublicKey']}/>
    </div>
    <br/>
    <MUTypography variant='h5' className={props.classes.h5}>
    IP Filter
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    IP Filter is applied to all api calls. Filtering operation checks if the request is made from IP which is specified while creating application. The request made from unknown ip is responded with "Invalid IP" error message.
    </MUTypography>
    <br/>

    <MUTypography variant='h5' className={props.classes.h5}>
    Rate Limiter
    </MUTypography>
    <MUTypography variant='display1' className={props.classes.paragraph}>
    In api calls there are limitations to 5 request per seconds and 100 request per day for a client. In case of over use 'Too many requests' error message is thrown.
    </MUTypography>
    <br/>
  </div>;

export default Info3;

