import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.js';
import Button from '../ui/Button';
import MUTypography from '../ui/MUTypography';
import BasicPageComponent from '../BasicPageComponent';
import FeaturedAPIs from '../FeaturedAPIs';
import Header from '../Header';
import ScreenContainer from '../ui/ScreenContainer';
import { motion } from 'framer-motion';
import { history } from '../../App';
import { docsInfoDynamicContentScreen } from '../../redux/constants/docsInfoContentTypes';
import { getProducts } from '../../redux/selectors';

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreProduct: false,
      products: props.productsFromStore
    };
  }
  renderHeader = () => {
    const { classes, showHeader } = this.props;
    if (showHeader) {
      return <Header />;
    } else {
      return null;
    }
  };
  handleClick = (e, id) => {
    this.props.actions.switchDocsMainContentToInfo();
    this.props.actions.setDocsApiInfo(id, docsInfoDynamicContentScreen, false);
    history.push({
      pathname: '/docs',
      state: { isLinked: true }
    });
  };
  render() {
    const { classes } = this.props;
    const { showMoreProduct } = this.state;
    const handleShowMoreClick = () => {
      const targetElement = document.getElementById('showMore');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        this.setState({ showMoreProduct: true })
      }
    };
    const handleShowLessClick = () => {
      const targetElement = document.getElementById('cards');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        this.setState({ showMoreProduct: false })
      }

    };
    const excludedIds = ['9000', '13000', '14000', '15000', '17000', '18000', '19000', '21000', '22000', '23000', '29000'];
    const productList = this.props.productsFromStore;
    const filteredProductList = productList.filter(item => !excludedIds.includes(item.id));

    return (
      <div>
        {this.renderHeader()}

        <div id='feature' className="force-full-screen full-screen dark"
        >
          <BasicPageComponent
            title='Featured API’s'
            description={
              <>
                Discover the wide range of Albaraka APIs ranging from accounts to payments,
                <br />
                from investment products to credit cards!
              </>
            }
            children={FeaturedAPIs}
          />
        </div>
        <div id="cards" style={{ backgroundColor: "#F4F5F6" }}>
          <ScreenContainer >
            <div className={classes.screenContainer} >
              {filteredProductList.slice(0, 6).map((item) =>
                <div className={classes.screenSubContainer} key={item.id}>
                  <div className={classes.screenContainerContext} 
                      onClick={(e) => this.handleClick(e, item.id)}
                      >
                  {item.imageUrl && (
                    <img className={classes.screenImage} src={require(`../../images/${item.imageUrl}.png`)} alt={item.imageUrl} />)}
                    <MUTypography className={classes.containerTypography}>{item.name}</MUTypography>
                    <motion.div
                      style={{ display: 'flex', alignItems: 'center' }}
                      initial={{ x: 0, opacity: 1 }}
                      whileHover={{ x: 100 }}
                      transition={{ duration: 1 }}
                    >
                      <MUTypography className={classes.findoutTypography}>FIND OUT MORE</MUTypography>
                      <img src={require('../../images/arrow.svg')} alt="Arrow" />
                    </motion.div>
                  </div>
                </div>)}

            </div>
            <div className={classes.screenMoreContainer} hidden={!this.state.showMoreProduct} id="showMore">
              {filteredProductList.slice(6, 16).map((item) => (

                <div className={classes.screenSubContainer} key={item.id}>
                  <div className={classes.screenContainerContext}
                   onClick={(e) => this.handleClick(e, item.id)}
                  >
                  {item.imageUrl && (
                    <img className={classes.screenImage} src={require(`../../images/${item.imageUrl}.png`)} alt={item.imageUrl} />)}
                    <MUTypography className={classes.containerTypography}>{item.name}</MUTypography>
                    <motion.div
                      style={{ display: 'flex', alignItems: 'center' }}
                      initial={{ x: 0, opacity: 1 }}
                      whileHover={{ x: 100 }}
                      transition={{ duration: 1 }}
                     
                    >
                      <MUTypography className={classes.findoutTypography}>FIND OUT MORE</MUTypography>
                      <img src={require('../../images/arrow.svg')} alt="Arrow" />
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>


            <div
              hidden={this.state.showMoreProduct}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <Button
                variant='text'
                classes={{ root: classes.showMoreButton, label: classes.showMoreBtnTxt }}
                text="SHOW MORE"
                onClick={handleShowMoreClick}
              />
            </div>
            <div
              hidden={!this.state.showMoreProduct}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <Button
                variant='text'
                classes={{ root: classes.showMoreButton, label: classes.showMoreBtnTxt }}
                text="SHOW LESS"
                onClick={handleShowLessClick}
              />
            </div>
          </ScreenContainer>

        </div>
      </div>

    );
  }
}
ProductCard.defaultProps = {
  showHeader: true,
};
ProductCard.propTypes = {
  login: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  login: state.login,
  productsFromStore: getProducts(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductCard);
