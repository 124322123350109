const styles = theme => ({
  gettingStartedTypography: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: 60,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-2.239px'
  },
  screenContainer: { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', padding: '30px 0', paddingBottom: 0, fontFamily: 'Gilroy' },
  getStartBtn: {
    width: '182px',
    height: '44px',
    flexShrink: '0',
    borderRadius: '24px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    lineHeight: 'normal',
  },
  letsStartBtn: {
    width: '225px',
    height: '30px',
    borderRadius: '32px',
    border: '1px solid white',
    color: 'white',
    backgroundColor: '#0076FF',
    textAlign: 'center',
    fontFamily: 'Gilroy',
    fontSize: '22px !important',
    lineHeight: '28.8px',
    padding: '12px 32px',
  },
  getStartBtnTxt: {
    color: 'white',
  },
  typography: {
    color: '#8B96A4',
    fontFamily: 'Gilroy-Light',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: ' 28.8px', /* 120% */
    letterSpacing: ' -0.456px',
    marginLeft: 72,
    marginTop: 40
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'column wrap',
    justifyContent: 'center'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px', // Mobil uyumlu içerik padding'i
    justifyContent: 'center',
    alignItems: 'center',
    margin:'auto',
  },

});

export default styles;
