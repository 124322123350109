import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import * as apiDashBoardService from '../../services/apidashboardservice';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { history } from '../../App';

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    margin: 16,
    justifyContent: 'center',
    alignItems: 'middle'
  }
};

class AnnouncementBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      announcements: props.announcements,
      vertical: 'bottom',
      horizontal: 'right',
      open: true
    };
  }

  redirectToAnnouncement = (key) => {
    this.props.closeSnackbar();
    setTimeout(()=> {
      history.push('/announcement');
    }, 300);

  };

  componentWillMount() {
    var {vertical, horizontal} = this.state;

    apiDashBoardService.getAnnouncements()
      .then((response) => {
        this.setState({announcements: response.data});
        var temp = [...this.state.announcements].reverse();

        temp.slice(0, 3).map((item) => {
          console.log('item: ', item);
          this.props.enqueueSnackbar(
            (<h6>{item.title}</h6>),
            {
              variant: 'default',
              anchorOrigin: { vertical, horizontal },
              onClose: this.handleClose(),
              action: key =>(
                <Fragment>
                  <Button color="secondary" size="small" onClick={() => this.redirectToAnnouncement(key)}>
                    <span style={{'fontSize': '14px', 'color': 'white'}}>{item.shortMessage}</span>
                  </Button>

                  <IconButton size="small" aria-label="close" color="inherit" onClick={() => { this.props.closeSnackbar(key); }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Fragment>

              )
            });
        });

      })
      .catch((exception) => {

        console.log('getproducts exception: ' + JSON.stringify(exception));
      });

  };
 handleClose = () => {
   this.setState({ ...this.state, open: false });
 };
 render() {
   return (<div></div>);
 }
}

AnnouncementBar.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(
  withSnackbar(AnnouncementBar),
);
