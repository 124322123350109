import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ApiUsageRequestTable = () => 
    <Table aria-label="customized table" style={{ width: 710 }}>
          <TableHead sx={{ width: 710 }}>
            <TableRow>
            <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
              <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"transaction_id"}>
              <StyledTableCell>transaction_id</StyledTableCell>
              <StyledTableCell>The id provided you in api call</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"authenticationType"}>
              <StyledTableCell>authenticationType</StyledTableCell>
              <StyledTableCell>1 for Sms.</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"otp_info"}>
              <StyledTableCell>otp_info</StyledTableCell>
              <StyledTableCell>One time password send to user via sms ( Any 6-digits number usable for tests )</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"Authorization"}>
              <StyledTableCell>Authorization (in Header)</StyledTableCell>
              <StyledTableCell>Bearer "Access_token"</StyledTableCell>
              </StyledTableRow>
          </TableBody>
        </Table>;

export default ApiUsageRequestTable;
