import React from 'react';
import PropTypes from 'prop-types';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import {Label} from '@material-ui/core';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList} from 'recharts';
import moment from 'moment';

class Chart extends React.Component {

  render() {
    const { props } = this;

    return (

      <LineChart width={props.width} height={props.height} data={props.data}
        margin={{top: 50, right: 50, left: 0, bottom: 25}}>
        <XAxis
          dataKey = {props.dataKeyXAxis}
          domain = {props.domainXAxis}
          name = {props.nameXAxis}
          tickFormatter = {props.tickFormatter}
          ticks ={props.ticks}
          type = {props.typeXAxis}
          scale = 'time'
          interval = {props.interval}
          tickCount = {props.tickCount}
          tickSize = {10}
          angle = {props.angle}
          textAnchor={props.textAnchor}
        />
        <YAxis dataKey = {props.dataKeyYAxis} name = {props.nameYAxis} />
        <CartesianGrid strokeDasharray={props.strokeDasharray}/>
        <Tooltip />
        <Legend />
        <Line dataKey={props.dataKeyYAxis} stroke={props.stroke} activeDot={{r: 6}}/>
        {/* <Line type="monotone" dataKey="tr" stroke="#82ca9d" /> */}
      </LineChart>

    );
  }
}

Chart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  dataKeyXAxis: PropTypes.string,
  domainXAxis: PropTypes.array,
  nameXAxis: PropTypes.string,
  typeXAxis: PropTypes.string,
  strokeDasharray: PropTypes.string,
  dataKeyYAxis: PropTypes.string,
  nameYAxis: PropTypes.string,
  stroke: PropTypes.string,
  tickCount: PropTypes.number,
  interval: PropTypes.number | PropTypes.string
};

Chart.defaultProps = {
  width: 800,
  height: 300,
  strokeDasharray: '3 3',
  stroke: '#8E1D11'
};
export default withStyles(styles)(Chart);
