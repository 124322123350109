import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';

class MUCircularProgress extends React.Component {

  render() {
    const { props } = this;

    return (
      <CircularProgress
        className={props.className}
        size={props.size}
      />
    );
  }
}

MUCircularProgress.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number
};

export default withStyles(styles)(MUCircularProgress);
