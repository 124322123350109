const styles = theme => ({
  btnChange: {
    marginLeft: '8px',
    heigth: '40px',
    backgroundColor: 'white',
    borderColor: '#ED502E',
    color: '#ED502E',
    '&:hover': {
      backgroundColor: '#ED502E',
      borderColor: '#ED502E',
      border: '2px solid',
      color: 'white'
    }
  },
  message: {
    textAlign: 'center',
    marginTop: '30px',
    fontFamily: 'Gilroy-Light',
    fontWeight: 600
  }
});

export default styles;
