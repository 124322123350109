const styles = theme => ({
  media: {
    maxWidth: '100%',
    maxHeight: '100%',
    minHeight: '200px',
    backgroundSize: 'initial',
    paddingTop: 'inherit'
  },
  card: {
    maxWidth: 360,
    boxShadow: 'none',
    backgroundColor: '#FFF',
    textAlign: 'left',
    width: 444,
    height: '316px',
    marginBottom: '10px'
  },
  actions: {
    display: 'flex'
  },
  avatar: {
    width: 120,
    height: 120
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '0 0 auto',
    paddingTop: '50px'
  },
  action: {
    alignSelf: 'flex-start',
    marginTop: '0px'
  },
  cover: {
    width: 151,
    height: 151,
    margin: '20px'
  },
  header: {
    display: 'flex'
  },
  iconButton: {
    margin: '1px 1px 10px'
  },
  h2: {
    fontSize: '16px',
    fontFamily: 'Gilroy',
    fontWeight: 700,
    paddingTop: '5px',
    paddingLeft: '2px',
    textAlign: 'center'
  },
  p: {
    display: 'flex',
    padding: '80px'
  },
  text:
  {
    fontSize: '14px',
    fontFamily: 'Gilroy-Light'
  },
  cardActionArea:
  {
    width: '100%',
    height: '350px'
  },
  gutterBottom: {
    textAlign: 'center'
  },
  link: {
    color: 'black',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#ED502E'
    }
  }

});

export default styles;

