import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../style.js';
import { withStyles } from '@material-ui/core/styles';
// import { MUList, MUListItem, MUListItemText } from 'basic-components';
import MUList from '../../../../../ui/MUList';
import MUListItem from '../../../../../ui/MUListItem';
import MUListItemText from '../../../../../ui/MuListItemText';

const ProductList = props => {
  const { classes, group, onSelectListItem } = props;
  const {services} = group;

  return (
    <MUList component="div" disablePadding={true}>
      {services.map(service =>
        <MUListItem
          key={service.serviceId}
          button = {true}
          className={classes.nested}
          selected = {props.selectedService === service.swaggerUrl && props.isProductSelected}
          onClick={() => onSelectListItem(service.swaggerUrl)}>
          <MUListItemText
            inset = {false}
            classes={{primary: classes.collapseItemText}}
            primary={service.name} />
        </MUListItem>
      )
      }
    </MUList>
  );
};

ProductList.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  onSelectListItem: PropTypes.func.isRequired
};

export default withStyles(styles)(ProductList);
