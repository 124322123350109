import React from 'react';
import { connect } from 'react-redux';
import CustomizedSnackbars from '../Notification';
import { clearNotification } from '../../redux/actions';

class NotificationBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(clearNotification(this.props.notification.variant));
  }
  render() {
    return (
      <CustomizedSnackbars
        open={this.props.notification.status}
        variant = {this.props.notification.variant}
        message = {<span id="message-id">{this.props.notification.message}</span>}
        handleClose = {this.handleClose}
      />
    );
  }
}

const mapStateToProps = state => ({
  notification: state.notification
});

export default connect(mapStateToProps, null)(NotificationBar);

