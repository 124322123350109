import * as actionTypes from '../constants/actionTypes';

export default function errorReducer(state = {isError: false, errorMessage: ''}, action) {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return {
        isError: action.isError,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }

}
