const styles = theme => ({
  title: {
    backgroundColor: '#F9F9F9',
    height: '100px',
    textAlign: 'center',
    textPadding: 'center'
  },
  textValidator: {
    width: '50%',
    marginBottom: '3%'
  },
  textField: {
    width: '100%'
  },
  muTextValidator: {
    width: '100%'
  },
  positionEnd: {
    marginLeft: '-12%'
  },
  inputStyle: {
    display: 'none !important'
  },
  p: {
    fontSize: '14px',
    fontWeight: 600
  },
  div: {
    margin: '0px 0px 20px'
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  buttonProgress: {
    color: '#008000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  listItem: {
    boxShadow: '0 1px 2px 0 #647385',
    backgroundColor: '#fff',
    margin: '2%',
    height: '87px'
  },
  listItemText: {
    fontSize: '12px',
    fontWeight: 900,
    width: '80%'
  },
  secondaryAction: {
    marginRight: '-57px'
  },
  header: {
    width: '167px',
    height: '26px',
    fontFamily: 'Gilroy-Light',
    fontSize: '20px',
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#0f1120',
    marginBottom: '2%'
  },
  appType: {
    fontSize: '26px',
    fontWeight: 900
  },
  btnRefreshToken: {
    width: '153px',
    height: '50px',
    borderRadius: '28.8px',
    backgroundColor: '#0f1120',
    marginLeft: '1%'
  },
  txtRefreshToken: {
    fontFamily: 'Gilroy-Light',
    color: '#fff',
    textTransform: 'uppercase'
  },
  label: {
    fontSize: '14px'
  },
  colorSwitchBase: {
    color: '#fff',
    '&$colorChecked': {
      color: '#50e3c2',
      '& + $colorBar': {
        backgroundColor: '#50e3c2'
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  avatar: {
    width: '140px',
    height: '140px'
  }
});

export default styles;
