import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core/';

class MUGrid extends React.Component {

  render() {
    const { props } = this;

    return (
      <Grid
        container = {props.container}
        item = {props.item}
        spacing = {props.spacing}
        xs = {props.xs}
      >
        {props.children}
      </Grid>
    );
  }
}

MUGrid.propTypes = {
  container: PropTypes.bool,
  item: PropTypes.bool,
  spacing: PropTypes.number,
  xs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

MUGrid.defaultProps = {
  container: false,
  item: false
};

export default MUGrid;
