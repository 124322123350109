import React from 'react';
import { IconButton, Grid, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { AddCircle, Delete} from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MUTextValidator from '../../ui/MUTextValidator';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import MUTypography from '../../ui/MUTypography';
class IpList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ip: '',
      ipList: [],
      isValid: true,
      readOnly: false
    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined && this.props.editapplication) {
      // state passed from history.push
      const { appId, applications, readOnly } = this.props.location.state;

      Array.prototype.forEach.call(applications, value => {
        console.log('product id : ' + value.id);
        if (value.id === appId) {
          this.setState({
            ipList: value.ipList,
            readOnly: readOnly
          });
          this.props.onIpListLoad(value.ipList);
        }

      });
    }
  }

  addIpToList = (e, ip) => {
    if (ip.match('^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$')) {
      console.log('match');
      this.setState({
        ip: ''
      });
      if (ip !== '') {
        this.setState({
          ipList: [...this.state.ipList, ip]
        });

        this.props.addIpToList(ip);
      }

    }
  };

  deleteIpFromList = (e, ip) => {
    if (ip !== '') {
      let temp = [...this.state.ipList]; // make a separate copy of the array
      let index = temp.indexOf(ip);

      temp.splice(index, 1);
      this.setState({ipList: temp});

      this.props.deleteIpFromList(ip);
    }
  }

    handleChange = (event, name) => {

      const fieldValue = event.target.value;

      this.setState({ [name]: fieldValue });
      this.props.handleChange(name, fieldValue);
    };

    onError() {
      this.setState({isValid: false});
    }

    render() {

      const { classes } = this.props;
      const { ip, ipList, readOnly } = this.state;

      return (
        <div>
          <div id='form'>
            <ValidatorForm
              ref="ipForm"
            >
              <div id='textValidator' style = {{width: '50%'}}>
                < MUTextValidator
                  id="ip"
                  className ={classes.muTextValidator}
                  onChange={(event) => this.handleChange(event, 'ip')}
                  value={ip}
                  name="ip"
                  placeholder="Enter an IP..."
                  inputAdornmentClasses = {{positionEnd: classes.positionEnd}}
                  endAdornmentIcon = { <IconButton
                    aria-label="Toggle password visibility"
                    onClick={(e) => this.addIpToList(e, ip)}
                    disabled={readOnly}
                  >
                    <AddCircle />
                  </IconButton>}
                  validators={['matchRegexp:^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$']}
                  errorMessages={['Invalid IP Address']}
                  disabled={readOnly}
                />
              </div>
            </ValidatorForm>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={4} md={3} >
              <List >
                {ipList.map((value) =>
                  <li className={classes.listItemText} >
                    <ListItem key={value} classes={{secondaryAction: classes.secondaryAction}}>
                      <ListItemText
                        primary = {
                          <MUTypography
                            variant="subheading"
                            className={classes.listItemText}
                            component="span"
                          >
                            {value}
                          </MUTypography>
                        }
                      />
                      <ListItemSecondaryAction className={classes.secondaryAction}>
                        <IconButton aria-label="Delete"
                          onClick={(e) => this.deleteIpFromList(e, value)}
                          disabled={readOnly}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </li>
                )}
              </List>
            </Grid>
          </Grid>
        </div>
      );
    }
}

export default withStyles(styles)(IpList);
