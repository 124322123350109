const styles = theme => ({
  margin: {
    margin: theme.spacing.unit
  },
  bootstrapRoot: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: '1px solid',
    backgroundColor: 'white',
    borderColor: '#ED502E',
    color: '#ED502E',
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(','),
    '&:hover': {
      backgroundColor: '#ED502E',
      borderColor: '#ED502E',
      border: '1px solid',
      color: 'white'
    }
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    color: 'white',
    backgroundColor: '#ED502E',
    borderColor: '#ED502E',
    width: 38,
    height: 38,
    '&:hover': {
      backgroundColor: '#F9F9F9',
      border: 'solid 1px',
      borderColor: '#ED502E',
      color: '#ED502E'
    }
  },
  navbar: {
    fontFamily: 'sans - serif',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#333'
  },
  logo: {
    marginTop: '-1%',
    marginLeft: '2%',
    marginRight: '65px !important'
  },
  header: {
    marginLeft: '56px',
    marginTop: '53px',
    height: '78px'
  },
  navigation: {
    border: 'none !important',
    backgroundColor: 'initial'
  },
  typography: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '3px',
    color: '#e61837',
    padding: '8px',
  },
  menu: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '2px',
    color: '#ffffff',
    padding: '8px'
  },
  activeLink:{
    textDecoration:'underline'
  }
});

export default styles;
