import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import BasicPageComponent from '../BasicPageComponent';
import Header from '../Header';
import * as apiDashBoardService from '../../services/apidashboardservice';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import FolderIcon from '@material-ui/icons/Announcement';

class Announcement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'Announcements',
      announcements: []
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.getAnnouncements();
  }

  getAnnouncements = () => {

    apiDashBoardService.getAnnouncements()
      .then((response) => {
        this.setState({
          announcements: response.data.reverse()
        });
      })
      .catch((exception) => {

        console.log('getproducts exception: ' + JSON.stringify(exception));
      });

  }

  generate(element) {
    return [0, 1, 2].map(value =>
      React.cloneElement(element, {
        key: value
      }),
    );
  }

  getLocalDate = (item) => {
    var date = new Date(item.updateDate);

    date = date.toLocaleDateString();
    console.log('date: ', date);
    return date.toString();
  }

  render() {
    const {title} = this.state;
    const {classes} = this.props;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title= {title}
          children ={ <div sytle = {{flexGrow: 1, maxWidth: 752}}>

            <Grid container spacing={2} style = {{justifyContent: 'center',
              marginBottom: '30px'}}>
              <Grid item xs={12} md={6}>
                <div style={{backgroundColor: '#FFFFFF'}}>
                  <List >
                    {this.state.announcements.map((item) =>

                      <ListItem>
                        <div>
                          <ListItemIcon>
                            <FolderIcon />
                          </ListItemIcon>
                        </div>
                        <div>
                          <ListItemText classes={{
                            primary: classes.listItemText
                          }}
                          primary={item.title}
                          />
                          <ListItemText classes={{
                            primary: classes.listItemText,
                            secondary: classes.secondaryText
                          }}
                          primary={item.longMessage}
                          secondary= {this.getLocalDate(item)}
                          />
                        </div>

                      </ListItem>

                    )}
                  </List>
                </div>
              </Grid>
            </Grid>
          </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Announcement);

