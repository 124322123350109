import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const OTPMessageTable = () => 
    <Table aria-label="customized table" style={{ width: 710 }}>
          <TableHead sx={{ width: 710 }}>
            <TableRow>
              <StyledTableCell style={{fontSize:16, width: 60}}>Parameter</StyledTableCell>
              <StyledTableCell style={{fontSize:16, width: 650}}>Explanation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
            <StyledTableRow key={"status"}>
              <StyledTableCell>status</StyledTableCell>
              <StyledTableCell>Status of process</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"transactionId"}>
              <StyledTableCell>transactionId</StyledTableCell>
              <StyledTableCell>The ID will be used to complete process</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={"authenticationType"}>
              <StyledTableCell>authenticationType</StyledTableCell>
              <StyledTableCell>authenticationType value will be 1 for sms flow. </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>;

export default OTPMessageTable;
