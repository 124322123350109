import * as actionTypes from '../../constants/actionTypes';

export default function swaggerContent(state = '', action) {
  switch (action.type) {
    case actionTypes.SET_DOCS_SWAGGER_API:
      return action.url;
    default:
      return state;
  }

}

