import React, { Component } from 'react';
import { connect } from 'react-redux';
import LiNavLink from '../LiNavLink';
import { Link, withRouter } from 'react-router-dom';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../../App';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import MUTypography from '../ui/MUTypography';

class Header extends Component {

  onLogoutClick() {
    this.props.actions.logout();
  }

  onAvatarClick() {
    history.push('/profile');
  }

  render() {

    const { classes } = this.props;

    return (
      <nav className="navbar navbar-expand-md navbar-dark fixed-top p-md-5 m-sm-0">
        <Link to='/'>
          <img src={this.props.srcImage} alt="Canvas Logo" />
        </Link>
        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" style={{ fontSize: '20px' }}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarCollapse" aria-expanded="false" style={{ display: 'flex !important' }}>
          <ul id="headermenu" className="d-sm-flex">
            <ul id="sitemenu" className="navbar-nav mr-auto">
              <LiNavLink activeClassName='active' exact={true} to="/gettingStarted">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  GETTING STARTED
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/productCard">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  DISCOVER APIS
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/docs" >
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  DOCUMENTATIONS
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/community">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  COMMUNITY
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/support">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  SUPPORT
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/faq">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  FAQ                </MUTypography>
              </LiNavLink>
              {this.props.login.token ?
                <LiNavLink activeClassName='active' exact={true} to="/myapplications">
                  <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                    MY APPLICATIONS
                  </MUTypography>
                </LiNavLink> :
                null}

            </ul>

            <ul className="navbar-nav float-md-right float-sm-left">

              <li class="nav-item float-right">

                {this.props.login.token && this.props.login.username !== undefined ?
                  <div onClick={this.onAvatarClick}>

                    <Avatar className={classNames('outlinedPrimary', classes.orangeAvatar)}>
                      {this.props.login.username.charAt(0).toUpperCase()}</Avatar>
                  </div> :
                  <LiNavLink to="/login">
                    <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                      SIGN IN
                    </MUTypography>
                  </LiNavLink>
                }
              </li>
              <li class="nav-item float-right">
                {this.props.login.token ?
                  <LiNavLink to="/" onClick={this.onLogoutClick.bind(this)}>
                    <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                      LOGOUT
                    </MUTypography>
                  </LiNavLink> :
                  <LiNavLink to="/register">
                    <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                      SIGN UP
                    </MUTypography>
                  </LiNavLink>
                }
              </li>

            </ul>
          </ul>
        </div>
      </nav>

    );
  }
}

Header.defaultProps = {
  srcImage: require('../../images/albaraka_api.jpg')
};

const mapStateToProps = state => ({
  login: state.login.toJS()
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
